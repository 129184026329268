import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const AlertModal = ({ showAlert, handleClose, alertText, titleText }) => {
	return (
		<Modal
			tabIndex="-1"
			role="dialog"
			aria-labelledby="alertLabel"
			aria-hidden="true"
			show={showAlert}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>{titleText}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{alertText}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AlertModal;
