import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { ERROR, ACTIONS } from "../state/Config.js";

const Support = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const onDataChange = event => {
		formDataRef.current = {
			...formDataRef.current,
			[event.target.name]: event.target.value
		};
		// DEBUG
		console.log("Form = ", formDataRef.current);
	};

	const onSubmitSupport = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"/categories/support",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit demographic data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>
							Information about support you have received to
							access the NEON Intervention{" "}
						</h1>
					</div>

					<Form onSubmit={onSubmitSupport} id="support">
						<Form.Group className="mb-3">
							<Form.Label>
								Has anyone helped you to access the NEON
								Intervention in the last year, even if they have
								only helped you once?
							</Form.Label>
							<Form.Select
								onChange={onDataChange}
								id="helped"
								name="helped"
								required
							>
								<option>Select an answer from the list</option>
								<option value="1">No-one</option>
								<option value="2">
									Close associate (e.g. partner, family,
									friends, informal carer)
								</option>
								<option value="3">Social care worker</option>
								<option value="4">Mental health worker</option>
							</Form.Select>
						</Form.Group>
						<Form.Group id="timesAnswer" className="d-none mb-3">
							<Form.Label>
								How many times has someone helped you to use the
								NEON Intervention?
							</Form.Label>
							<Form.Select
								onChange={onDataChange}
								id="times"
								name="times"
							>
								<option>Select an answer from the list</option>
								<option value="1">1</option>
								<option value="2">2-10</option>
								<option value="3">11 or more</option>
							</Form.Select>
						</Form.Group>
						<input
							type="hidden"
							name="formRequestID"
							value={props.formRequestID}
						/>
						<button type="submit" className="btn btn-primary">
							Next
						</button>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Support;
