import React from "react";
import { Routes, Route } from "react-router-dom";
import Confidence from "../questions/Confidence.js";
import Meaning from "../questions/Meaning.js";
import EQ5D from "../questions/EQ5D.js";
import Abridged from "../questions/Abridged.js";
import NarrativeUsage from "../questions/NarrativeUsage.js";
import Support from "../questions/Support.js";
import Demographic from "../questions/Demographic.js";
import MansaPreQ from "../questions/MansaPreQ.js";
import Mansa from "../questions/Mansa.js";
import Core from "../questions/Core.js";
import Herth from "../questions/Herth.js";
import ThanksIntervention from "../questions/ThanksIntervention.js";
import ThanksControl from "../questions/ThanksControl.js";
import CollectionInfo from "../questions/CollectionInfo.js";

const Questions = () => {
	return (
		<Routes>
			<Route path="/confidence" element={<Confidence />} />
			<Route path="/meaning" element={<Meaning />} />
			<Route path="/EQ5D" element={<EQ5D />} />
			<Route path="/abridged" element={<Abridged />} />
			<Route path="/narratives" element={<NarrativeUsage />} />
			<Route path="/support" element={<Support />} />
			<Route path="/demographic" element={<Demographic />} />
			<Route path="/mansaPreQ" element={<MansaPreQ />} />
			<Route path="/mansa" element={<Mansa />} />
			<Route path="/core" element={<Core />} />
			<Route path="/hope" element={<Herth />} />
			<Route path="/collectionInfo" element={<CollectionInfo />} />
			<Route
				path="/thanksinterventionGroup"
				element={<ThanksIntervention />}
			/>
			<Route path="/thankscontrolGroup" element={<ThanksControl />} />
		</Routes>
	);
};

export default Questions;
