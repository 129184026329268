import React from "react";

const LogoHeader = () => (
	<div className="container-fluid mt-4">
		<div className="row bg-white">
			<div className="container pt-2 pb-2">
				<a
					className="sr-only sr-only-focusable text-primary"
					href="#main"
				>
					Skip to main content
				</a>
				<div className="row">
					<div className="col-9 col-xl-10 d-none d-lg-block pt-1">
						<img
							src="/images/NEON_logo_strapline_portrait.png"
							alt="NEON logo: Narrative Experiences ONline"
							width="540"
							height="46"
						/>
					</div>

					<div className="col-7 col-md-9 d-lg-none">
						<img
							src="/images/NEON_logo_strapline_mobile.png"
							alt="NEON logo: Narrative Experiences ONline"
							width="200"
							height="54"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default LogoHeader;
