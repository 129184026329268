import React, { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LogoHeader from "../partials/LogoHeader.js";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import Form from "react-bootstrap/Form";
import postData from "../data/PostData.js";
import { ERROR, ACTIONS } from "../state/Config.js";
import useParticipantStore from "../state/participantStore.js";

const Safety = props => {
	// Get url parameters
	const location = useLocation();
	const control = new URLSearchParams(location.search).get("control");
	const navigate = useNavigate();

	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const formDataRef = useRef({});

	const onChangeData = event => {
		formDataRef.current = {
			...formDataRef.current,
			[event.target.name]: event.target.value
		};

		// DEBUG
		console.log("Form = ", formDataRef.current);
	};

	const onSubmitSafety = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"/categories/safetyReport",
				formDataRef.current
			);
			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						setParticipantData(data);
						navigate(`/safe/${data.page}`);
						break;

					default:
						break;
				}
			}
		} catch (error) {
			console.log("Could not submit safety report");
		}
	};

	return (
		<div className="content intervention">
			{/* PS added min-height-full class, h1 and h2 */}
			{control ? <LogoHeader /> : <MenuHeader />}
			<div className="container mt-5 min-height-full">
				<div>
					<h1>An incident has occurred</h1>
				</div>

				<div>
					<p>
						This form can be used to report problems that you have
						experienced through taking part in the NEON Trial. We
						use this information to help us monitor the safety of
						NEON and of individual participants. Please tell us as
						much as you can, as this will help us in responding
						effectively. Any information you tell us will be logged,
						and reported to the NEON Chief Investigator and others.
					</p>
				</div>
				<hr className="mt-4 mb-4" />
				<Form
					onSubmit={onSubmitSafety}
					id="safetyReport"
					className="mb-3"
				>
					<Form.Group className="mb-3">
						<label>
							<h2>What has happened?</h2>
						</label>
						<Form.Select
							onChange={onChangeData}
							id="safety"
							name="eventType"
							required
						>
							<option>Select an answer from the list</option>
							<option value="2">
								I experienced something that threatened my life
							</option>
							<option value="3">
								I was admitted to hospital
							</option>
							<option value="4">
								I had to stay in hospital longer than planned or
								expected
							</option>
							<option value="5">
								I have been disabled or incapacitated
							</option>
							<option value="6">
								I have experienced something else I want to tell
								you about
							</option>
						</Form.Select>
					</Form.Group>
					<Form.Group id="somethingElse" className="d-none mt-3">
						<label>Please describe what you experienced</label>
						<Form.Control
							onChange={onChangeData}
							id="somethingElseText"
							type="text"
							name="eventOther"
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<label>
							<h2>
								In your opinion was this caused by your
								participation in a NEON trial?
							</h2>
						</label>
						<Form.Select
							onChange={onChangeData}
							id="caused"
							name="causedByTrial"
							required
						>
							<option>Select an answer from the list</option>
							<option value="1">Yes</option>
							<option value="2">Unsure</option>
							<option value="3">No</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-2">
						<label>
							When did this occur? Please give us your best guess
							if you cannot remember accurately.
						</label>
						<input
							onChange={onChangeData}
							id="occurred"
							type="date"
							name="occurred"
						/>
					</Form.Group>
					<Form.Group>
						<label>Where did this occur?</label>
						<textarea
							onChange={onChangeData}
							id="whereOccurred"
							className="form-control"
							name="whereOccurred"
							rows="5"
							maxLength="300"
						/>
					</Form.Group>
					<Form.Group id="trialCaused" className="form-group d-none">
						<label>
							Please tell us how you think the trial might have
							caused this.
						</label>
						<textarea
							onChange={onChangeData}
							id="howOccurred"
							className="form-control"
							name="howCaused"
							rows="5"
							maxLength="300"
						/>
					</Form.Group>
					<Form.Group>
						<label>
							How is it best for us to contact you if we need more
							information?
						</label>
						<textarea
							onChange={onChangeData}
							id="contact"
							className="form-control"
							name="contactYou"
							rows="5"
							maxLength="300"
						/>
					</Form.Group>
					<button type="submit" className="btn btn-primary mt-3">
						<i
							className="fas fa-sign-in-alt ml-1 mr-3"
							aria-hidden="true"
						/>
						Submit
					</button>
				</Form>

				{Boolean(props.showResponse) && (
					<React.Fragment>
						{" "}
						<p className="text-danger">
							Thank you for taking the time to complete this form.
							The information that you have provided has been
							immediately passed to the NEON Chief Investigator
							and study coordinator, and will be processed
							rapidly.
						</p>
					</React.Fragment>
				)}
			</div>
			{!control ? <FooterStories /> : null}
		</div>
	);
};

export default Safety;
