const ERROR = {
	NO_ERROR: 0,
	USER_ALREADY_EXISTS: 1,
	USER_NOT_LOGGED_IN: 16,
	GENERAL_ERROR: 17
};

const ACTIONS = {
	NO_ACTION: 0,
	NAVIGATE: 1
};

const LABELS = {
	Hopeful: [
		"Less hopeful than before",
		"No change",
		"A bit more hopeful",
		"Much more hopeful"
	],
	HOPE_MAX: 3,
	Similar: ["Not at all", "A bit", "Quite a lot", "Very much"],
	SIMILAR_MAX: 3,
	Life: ["Not at all", "A bit", "Quite a lot", "Very much"],
	LIFE_MAX: 3,
	Learning: ["Nothing", "A bit", "Quite a lot", "A huge amount"],
	LEARNING_MAX: 3,
	Emotional: ["Not at all", "A bit", "Quite a lot", "A huge amount"],
	EMOTION_MAX: 3,
	Zoom: ["0", "1", "2", "3"],
	ZOOM_MAX: 3,
	Happy: ["1", "2", "3", "4", "5", "6"],
	HAPPY_MAX: 5,
	Seven: ["1", "2", "3", "4", "5", "6", "7"],
	SEVEN_MAX: 6,
	Distress: [
		"Not at all",
		"Only occasionally",
		"Sometimes",
		"Often",
		"Most or all the time"
	],
	DISTRESS_MAX: 4,
	Hope: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
	HOPE_MAX: 3
};

export { ERROR, ACTIONS, LABELS };
