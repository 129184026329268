import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ContactModal from "../modals/ContactModal";
import VimeoModal from "../modals/VimeoModal";
import { Link } from "react-router-dom";

const HowTo = () => {
	// Video Modal
	const [showVideo, setShowVideo] = useState(false);
	const [currentUrl, setCurrentUrl] = useState("");

	// Contact Modal
	const [showContact, setShowContact] = useState(false);

	const handleCloseVideo = () => setShowVideo(false);
	const handleShowVideo = props => {
		setCurrentUrl(props.target.getAttribute("data-src"));
		setShowVideo(true);
	};
	const handleCloseContact = () => setShowContact(false);
	const handleShowContact = () => setShowContact(true);

	return (
		<React.Fragment>
			<div className="content mt-3">
				<div className="container mt-3">
					<a
						className="sr-only sr-only-focusable text-primary"
						href="#main"
					>
						Skip to main content
					</a>
					<div className="row d-none d-xl-flex">
						<div className="col-2">
							<a href="/">
								<img
									src="/images/NEON_logo_splash_left.png"
									alt="NEON Trial logo"
								/>
							</a>
						</div>
						<div className="col-5">
							<a href="/">
								<img
									src="/images/NEON_logo_splash_right.png"
									alt="Narrative Experiences ONline"
								/>
							</a>
						</div>
						<div className="col-5 text-end">
							<a
								href="https://researchintorecovery.com/neon"
								target="_blank"
								className="btn btn-info ps-2"
							>
								<img
									src="/images/neon_icon.png"
									alt="NEON icon"
									className="me-1"
									width="16"
									height="16"
								/>{" "}
								About NEON
							</a>
							<Button
								className="btn-dark ms-3"
								onClick={handleShowContact}
							>
								<i className="fas fa-comment fa-fw me-1" />{" "}
								Contact
							</Button>
							<Link
								className="btn btn-primary ps-2 ms-3"
								to="/login"
							>
								<i className="fas fa-sign-in-alt" /> Log in
							</Link>
						</div>
					</div>
					<div className="row d-xl-none">
						<div className="col-7">
							<img
								src="/images/NEON_logo_strapline_mobile.png"
								alt="NEON logo: Narrative Experiences ONline"
								width="200"
								height="54"
							/>
						</div>
						<div className="col-5 text-end">
							<Link className="btn btn-primary ps-3" to="/login">
								<i className="fas fa-sign-in-alt" /> Log in
							</Link>
						</div>
					</div>
					<div className="row">
						<div className="col-12 mt-3">
							<div
								className="trialprocedures-stripe-top"
								id="main"
							/>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-0 col-xl-2 d-none d-xl-block">
							<div
								style={{
									height: "100%",
									background:
										"url('/assets/images/kite_desktop_bottom.jpg') repeat-y"
								}}
							>
								<img
									src="/images/kite_desktop_200_faded.jpg"
									width="140"
									alt="Photo of a kite"
								/>
							</div>
						</div>
						<div className="col-12 d-xl-none">
							<img
								src="/images/kite_mobile.jpg"
								width="100%"
								alt="Photo of a kite"
							/>
						</div>

						<div className="col-12 col-xl-10 mt-4 mb-4">
							<p className="text-end">
								<a
									href="/"
									className="btn btn-primary ps-3 mb-3"
								>
									<i className="fas fa-arrow-left pe-3" />
									Back to homepage
								</a>
							</p>
							<h1>How to use NEON</h1>
							<p className="mb-5 blueLinks">
								We've recorded some videos of how to use NEON.
								Please email{" "}
								<a href="mailto:neon@nottingham.ac.uk?subject=NEON Videos">
									neon@nottingham.ac.uk
								</a>
								if you'd like a specific video adding.
							</p>

							<div className="row">
								<div className="col-12 col-lg-6 pe-3">
									<div className="card video-card-grey mb-4 p-2">
										<div className="card-body">
											{/* New button for modal */}
											<Button
												onClick={handleShowVideo}
												className="ps-3 mb-3 stretched-link video-btn"
												data-src="https://player.vimeo.com/video/537215999"
											>
												<i className="fas fa-question pe-3" />
												Logging in
											</Button>
											<p className="card-text">
												A walk through of the steps for
												finding & logging in to NEON.
											</p>
										</div>
									</div>
								</div>

								<div className="col-12 col-lg-6 ps-3">
									<div className="card video-card-grey mb-4 p-2">
										<div className="card-body">
											{/* New button for modal */}
											<Button
												onClick={handleShowVideo}
												className="ps-3 mb-3 stretched-link video-btn"
												data-src="https://player.vimeo.com/video/541591827"
											>
												<i className="fas fa-question pe-3" />
												How to find stories
											</Button>
											<p className="card-text">
												There are four ways you can find
												stories in NEON - this video
												explains each option.
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12 col-lg-6 pe-3">
									<div className="card video-card-grey mb-4 p-2">
										<div className="card-body">
											{/* New button for modal */}
											<Button
												onClick={handleShowVideo}
												className="ps-3 mb-3 stretched-link video-btn"
												data-src="https://player.vimeo.com/video/537216535"
											>
												<i className="fas fa-question pe-3" />
												Other features
											</Button>
											<p className="card-text">
												A tour of some of the other
												features of NEON.
											</p>
										</div>
									</div>
								</div>

								<div className="col-12 col-lg-6 ps-3" />
							</div>
						</div>

						{/* Vimeo Video Modal */}
						<VimeoModal
							showVideo={showVideo}
							url={currentUrl}
							handleClose={handleCloseVideo}
						/>

						{/* Add contact modal */}
						<ContactModal
							showContact={showContact}
							handleClose={handleCloseContact}
						/>
						<input
							type="hidden"
							id="unsupportedBrowser"
							value="false"
						/>
					</div>{" "}
					{/* main col */}
				</div>{" "}
				{/* row */}
				<div className="container">
					<div className="row">
						<div className="col-12 mb-5">
							<div className="trialprocedures-stripe-bottom" />
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-xs-0 col-xl-9 mb-5 d-none d-xl-flex">
							<a
								href="https://www.nottingham.ac.uk/"
								target="_blank"
							>
								<img
									src="/images/logo_uon.png"
									className="me-4"
									height="65"
									alt="University of Nottingham logo"
								/>
							</a>
							<a
								href="https://www.institutemh.org.uk/"
								target="_blank"
							>
								<img
									src="/images/logo_imh2.png"
									className="ms-2 me-4 pe-1"
									height="65"
									alt="Institute of Mental Health Nottingham logo"
								/>
							</a>
							<a href="https://www.nihr.ac.uk/" target="_blank">
								<img
									src="/images/logo_nihr.png"
									height="65"
									className="ms-2"
									alt="Funded by NIHR (The National Institute for Health Research)"
								/>
							</a>
						</div>
						<div className="col-xs-12 col-xl-3 mb-5 text-end">
							<a
								href="https://www.nottinghamshirehealthcare.nhs.uk/"
								target="_blank"
							>
								<img
									src="/images/NHS_Logo.png"
									height="65"
									alt="NHS logo: Nottinghamshire Healthcare NHS Foundation Trust"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default HowTo;
