import axios from "axios";
import { useEffect, useState, useRef } from "react";

const useFetch = url => {
	// DEBUG
	console.log("Use fetch called with url = ", url);

	const [data, setData] = useState(null);
	const controller = useRef(new AbortController());

	useEffect(() => {
		const fetchData = async url => {
			try {
				const information = await axios.get(url, {
					signal: controller.current.signal
				});
				setData(information.data);
			} catch (error) {
				console.log("Request cancelled", error);
			}
		};

		fetchData(url);

		return () => {
			controller.current.abort();
		};
	}, [url]);

	return data;
};

export default useFetch;
