import React from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import useParticipantStore from "../state/participantStore.js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import getData from "../data/getData.js";
import { useNavigate } from "react-router-dom";
import { ERROR, ACTIONS } from "../state/Config.js";

const CollectionInfo = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	const navigate = useNavigate();

	const onGetDemographics = async event => {
		event.preventDefault();

		try {
			const response = await getData("/categories/demographic", {});
			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						setParticipantData(data);
						navigate(`/questions/${data.page}`);
						break;

					default:
						break;
				}
			}
		} catch (error) {
			console.log("Could not get demographics data", error);
		}
	};

	return (
		<div className="content mt-3">
			<MainHeader />
			<div className="container">
				<div>
					<p className="blueLinks">
						Great, you've now joined the trial!
					</p>
					<p>
						We now need to collect some information about you to
						help us evaluate the NEON Intervention. Completing this
						online survey will take about half an hour. Some
						questions might feel a little sensitive, so you might
						want to choose to complete the survey in a private
						place. Your responses will not be used to match you to
						stories, and cannot be used to identify you.
						{participantData.showPayment &&
							" Once you have completed the survey, you can claim a £20 voucher as thanks for your time and effort.\n"}{" "}
					</p>
					{participantData.showPayment && (
						<React.Fragment>
							{" "}
							<p>
								We will contact you in one week, three months
								and one year to ask you to complete the online
								survey again, and you can claim a further £20
								each time you complete the online survey.
							</p>
						</React.Fragment>
					)}{" "}
				</div>
				<div>
					<Form onSubmit={onGetDemographics}>
						<Button type="submit">Next</Button>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</div>
	);
};

export default CollectionInfo;
