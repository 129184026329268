import React, { useState } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import postData from "../data/PostData.js";
import { ERROR, ACTIONS } from "../state/Config.js";
import useParticipantStore from "../state/participantStore.js";

const Eligible = () => {
	const participantData = useParticipantStore(state => state.participantData);
	// DEBUG
	console.log("Part data = ", participantData);

	const navigate = useNavigate();
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	const onSubmitInfo = async event => {
		event.preventDefault();

		try {
			const formData = {
				trial: participantData.trial,
				partID: participantData.partID
			};

			const response = await postData("users/information", formData);

			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						setParticipantData(data);
						navigate(`/${data.page}`);
						break;

					default:
						break;
				}
			}
		} catch (error) {}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					<div>
						{participantData.expired ? (
							<React.Fragment>
								{" "}
								<p className="blueLinks">
									Thank you for completing our questions.
									{participantData.trialText ? (
										<React.Fragment>
											{" "}
											{participantData.trialText}
										</React.Fragment>
									) : (
										<React.Fragment>
											{" "}
											You would be eligible for the{" "}
											{participantData.trial} trial only,
											but this trial is now closed to
											recruitment.
										</React.Fragment>
									)}{" "}
									If you would like to learn about the results
									of our trials when they are available,
									please check our
									<a
										href="https://www.researchintorecovery.com/research/neon/outputs/"
										target="_blank"
									>
										{" "}
										study website
									</a>{" "}
									in 2022. Please do not change your responses
									to the questions.
								</p>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<p className="blueLinks">
									Thank you for taking the time to answer our
									questions. You are eligible to take part in
									the {participantData.trial} Trial. You can
									now access some further information about
									this trial, and decide whether to take part
									or not.
								</p>
							</React.Fragment>
						)}{" "}
					</div>

					{!participantData.expired && (
						<React.Fragment>
							{" "}
							<Form onSubmit={onSubmitInfo}>
								<button
									type="submit"
									className="btn btn-primary"
								>
									Next
								</button>
							</Form>
						</React.Fragment>
					)}
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Eligible;
