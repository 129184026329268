import React from "react";
import { Routes, Route } from "react-router-dom";
import Safety from "../safety/Safety";
import SafetySubmitted from "../safety/SafetySubmitted.js";

const Safe = () => {
	return (
		<Routes>
			<Route path="/safety" element={<Safety />} />
			<Route path="/safetySubmitted" element={<SafetySubmitted />} />
		</Routes>
	);
};

export default Safe;
