import React from "react";
import MenuHeader from "../partials/MenuHeader";
import FooterStories from "../partials/FooterStories";
import useParticipantStore from "../state/participantStore";
import { Link } from "react-router-dom";

const CategoryStories = () => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);

	return (
		<div className="content intervention">
			<MenuHeader />
			<div className="container mt-5 min-height-full">
				<ul className="nav nav-tabs mt-4">
					<li className="nav-item">
						<div className="nav-link active">
							<i
								className="fas fa-list-ol me-2"
								aria-hidden="true"
							/>
							<strong>
								Stories matching your chosen categories
							</strong>
						</div>
					</li>
				</ul>

				<div className="text-center bg-white pt-4 pb-4 mb-5">
					{participantData.storyDetails.map(story => (
						<React.Fragment key={story.id}>
							<div className="pt-2 pb-2">
								<Link
									to={`/stories/checkStory?story=${story.id}&categories=${participantData.selectedCategories}`}
									className="btn btn-info story-button"
								>
									{story.name}
								</Link>
							</div>
						</React.Fragment>
					))}
				</div>
			</div>
			<FooterStories />
		</div>
	);
};

export default CategoryStories;
