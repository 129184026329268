import React, { useState } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import useParticipantStore from "../state/participantStore.js";
import { Link } from "react-router-dom";

const Information = props => {
	const participantData = useParticipantStore(state => state.participantData);
	const [showRef, setShowRef] = useState(false);
	const [showGrant, setShowGrant] = useState(false);

	const toggleRef = () => {
		setShowRef(!showRef);
	};

	const toggleGrant = () => {
		setShowGrant(!showGrant);
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container mt-3">
					<div>
						<h1>NEON Study Information</h1>
					</div>

					<div className="container-fluid mt-4 mb-3">
						<div className="row mb-5">
							<div className="col-12">
								<div className="blueLinks info-points">
									<ul>
										<li>
											{/* PS changed to H2 */}
											<h2>
												Information for people
												interested in the{" "}
												{participantData.trial} Trial
											</h2>
											<p>
												Participant Information Sheet
												version 4.0. 13th October 2020
											</p>
											<br />
											<p>
												We would like to invite you to
												take part in our research study.
												This participant information
												sheet will help you understand
												why the research is being done
												and what it would involve for
												you, if you wish to take part.
												You do not have to take part in
												this study, joining is entirely
												up to you.
											</p>
											<Button
												id="projectID"
												variant="secondary"
												onClick={toggleRef}
											>
												<i className="fas fa-caret-down" />{" "}
												More information
											</Button>
											<Collapse in={showRef} id="project">
												<div>
													<div className="card card-body">
														The reference number for
														this research study is
														249015
													</div>
												</div>
											</Collapse>
										</li>
										<li>
											<h3>
												What is the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												The {participantData.trial}{" "}
												Trial is a healthcare research
												study. It aims to understand
												whether reading, watching or
												listening to the stories of
												people who have recovered from
												mental health problems can help{" "}
												{participantData.healthText}.
												The {participantData.trial}{" "}
												Trial is taking place as part of
												the NEON research study, which
												runs until September 2022.
											</p>
										</li>
										<li>
											<h3>What are recovery stories?</h3>
											<p>
												A recovery story is any story
												that that describes one or more
												periods of adversity that relate
												to mental health problems, and
												also one or more periods of
												success, strength or survival
												that relate to these.
											</p>
										</li>
										<li>
											<h3>
												How would I access recovery
												stories?
											</h3>
											<p>
												Through the NEON Intervention, a
												website which gives access to
												hundreds of recovery stories. It
												can be used on a smartphone or a
												computer. The NEON Intervention
												tries to match you to stories
												which might help you, using
												information about yourself that
												you provide. It also allows you
												to browse stories based on a
												range of categories, or to
												return to stories that you have
												previously accessed. Stories are
												presented in a mixture of text,
												video, audio and images. You can
												choose to hide particular
												formats of story if you wish,
												and any type of content that
												causes you distress.
											</p>
										</li>
										<li>
											<h3>
												What will happen next if I
												decide to take part in the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												You will be asked to fill out an
												online consent form, through
												which you must provide a working
												email address. You don't have to
												provide your name. You will be
												asked to provide a password for
												an online account. You will be
												asked for some basic information
												about yourself using an online
												survey. You will be asked to
												fill out the online survey again
												after 1 week, 12 weeks and 52
												weeks. This is an online trial;
												you will not be expected to meet
												with researchers or to visit
												treatment centres.
											</p>
											<p>
												To test whether accessing
												recovery stories through the
												NEON Intervention can
												significantly improve user's
												quality of life, we need to
												compare people who receive
												immediate access with similar
												people who do not receive
												immediate access. So, using a
												list of random numbers, you will
												be selected to either receive
												immediate access to the NEON
												Intervention, or to receive
												access after one year.
											</p>
											<p>
												In the first few months of the
												trial, and after taking part for
												one year, you might be asked to
												take part in an interview by
												telephone or secure video
												conference to help us understand
												what it feels like to take part.
												You don't have to take part in
												an interview if you don't want
												to, and you can continue
												participating in the trial even
												if you refuse. If you are
												interviewed, then we will record
												the sound of your interview and
												store the recording. We may also
												transcribe it. If we do, we will
												remove any information that
												identifies you from the
												transcripts.
											</p>
										</li>
										<li>
											<h3>
												If I get access to the NEON
												Intervention, how much do I have
												to use it?
											</h3>
											<p>
												You can use it as little or as
												much as you want until the end
												of the trial. It's up to you.
												The end of the trial is
												currently planned for 30th April
												2022.
											</p>
										</li>
										<li>
											<h3>
												If I don't get immediate access
												to the NEON Intervention, do I
												still have to continue with the{" "}
												{participantData.trial} Trial?{" "}
											</h3>
											<p>
												No, as you can withdraw at any
												point, but your continued
												involvement would be appreciated
												and your responses to the online
												questionnaires are important for
												the Trial.
											</p>
										</li>
										<li>
											<h3>
												Do I have to take part in the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												No, and if you decide not to
												participate this will not affect
												any medical or social care you
												are receiving.
											</p>
										</li>
										<li>
											<h3>
												What will I receive for taking
												part?
											</h3>
											{participantData.onNeonTrial && (
												<React.Fragment>
													{" "}
													<p>
														You can claim a £20
														online voucher for
														completing each round of
														our our online survey.
														If you choose to claim a
														voucher, then your email
														address will be passed
														to a study administrator
														who will send it to you.
														We aim to send vouchers
														within a week of claim;
														but payment can be
														delayed or cancelled if
														we detect unusual
														patterns (such as many
														vouchers being claimed
														from the same computer).
													</p>
												</React.Fragment>
											)}{" "}
											<p>
												If you are asked to take part in
												an interview, then you will be
												offered £20, which you can be
												paid through an online voucher
												or by submitting a claims form.
												We aim to send vouchers within a
												week, and payment by claims form
												can take up to 6 weeks.
											</p>
										</li>
										<li>
											<h3>
												What are the possible benefits
												of taking part?
											</h3>
											<p>
												Our research has identified a
												range of ways in which recovery
												stories can benefit people. They
												can help you feel more hopeful,
												connected, or more appreciative
												of your own life. You can learn
												about strategies for recovery.
												They might help you decide that
												you need to look for help. They
												can expose you to people and
												ideas that you might not find in
												your everyday life. NEON is a
												research study, and we cannot
												guarantee that it will benefit
												you. If you are receiving
												treatment, you should consult
												your healthcare professional
												before making any changes to
												your treatment.
											</p>
										</li>
										<li>
											<h3>
												What are the possible harms
												caused by taking part?
											</h3>
											<p>
												Our research has shown some ways
												in which recovery stories can
												harm people. Learning about the
												difficulties of others might
												make you feel more pessimistic
												or emotionally burdened. Some
												people can feel disconnected or
												inadequate if they read a story
												and feel that they can't match
												what the person telling it has
												achieved. Some people experience
												the release of uncomfortable
												emotions after receiving
												stories. There is some evidence
												that reading stories describing
												harmful behaviours can create a
												risk of copying those
												behaviours, especially for
												people with similar experiences.
											</p>
											<p>
												To help us monitor any harmful
												effects of the study, we have
												provided an online form which
												you can use to tell us about
												incidents of a serious nature.
												We understand these situations
												can be distressing and
												information on help and support
												organisations or services will
												be available to you if you need
												it, through a page in the
												intervention.
											</p>
										</li>
										<li>
											<h3>
												Will you contact me during the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												Yes, by email, or by SMS or
												telephone if you have chosen to
												provide a mobile phone number.
												We might contact you to remind
												you to complete online surveys
												or to let you know about new
												recovery stories. You will only
												be contacted by the University
												of Nottingham (who lead the
												trial) and by Nottinghamshire
												Healthcare Trust (the study
												Sponsor), and only then in
												relation to this study.
											</p>
										</li>
										<li>
											<h3>
												Who is organising the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												The {participantData.trial}{" "}
												Trial is led by Professor Mike
												Slade at the University of
												Nottingham. The NEON study is
												sponsored by Nottinghamshire
												Healthcare NHS Foundation Trust,
												and the {participantData.trial}{" "}
												Trial is overseen by the
												Pragmatic Clinical Trials Unit
												at Queen Mary University of
												London.
											</p>
										</li>
										<li>
											<h3>
												Who has approved the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												The Leicester Central Research
												Ethics Committee, the Health
												Research Authority, the study
												sponsor and the Pragmatic
												Clinical Trials Unit.
											</p>
										</li>
										<li>
											<h3>
												Can I withdraw from the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												You can stop being part of the
												study at any time, without
												giving a reason. We will keep
												information about you that we
												already have, other than any
												information that can identify
												you which will be deleted.
											</p>
										</li>
										<li>
											<h3>
												Will my taking part in the{" "}
												{participantData.trial} Trial be
												kept confidential?
											</h3>
											<p>
												Any information that might
												identify you will be held
												confidentially. As safety is our
												priority, confidentiality may be
												breached if any of your
												responses suggests that you or
												others are at risk of harming
												yourself or others, or that you
												or others have engaged in
												criminal activities that require
												notification to authority.
												Decisions to breach
												confidentiality will be made in
												collaboration with the Chief
												Investigator, and ideally with
												yourself.
											</p>
										</li>
										<li>
											<h3>
												How will we use information
												provided by you?
											</h3>
											<p>
												In this research study we will
												use information provided by you.
												We will only use information
												that we need for the research
												study.
											</p>
											<p>
												The study sponsor has overall
												responsibility for your
												information (they are the "data
												controller"). You can contact
												them to find out more about how
												your information is managed:{" "}
												<a href="mailto:research@nottshc.nhs.uk?subject=Neon Study">
													research@nottshc.nhs.uk
												</a>
												. Everyone involved in this
												study will keep your
												identifiable information safe
												and secure. We will follow all
												privacy rules. People will use
												this information to either do
												the research or to check your
												records to make sure that the
												research is being done properly.
											</p>
											<p>
												Information will initially be
												collected on a web-server
												operated by DRT Software Ltd., a
												software contractor. Information
												will be copied to research
												servers run by the University of
												Nottingham. DRT and the
												University of Nottingham will
												delete any information that
												identifies you by the end of the
												NEON study (currently September
												2022). The study sponsor has
												approved the arrangements with
												DRT Software Ltd. and the
												University and Nottingham, and
												contracts are in place to ensure
												that information is used
												appropriately. Information that
												cannot identify you may be
												shared with other researchers.
											</p>
											<p>
												At the end of the study we will
												save some of this information in
												case we need to check it, and
												for future research. We will
												make sure no-one can work out
												who you are from the reports we
												write. Other researchers might
												be granted access to those parts
												of the research data that cannot
												identify you, and some of it may
												be used in research
												publications. After the NEON
												study has closed, only
												information that cannot identify
												you will be retained by the
												study sponsor.
											</p>
										</li>
										<li>
											<h3>
												How can I find out more about
												how my information is used?
											</h3>
											<p>
												You can find out more about how
												we use your information at{" "}
												<a
													href="http://www.hra.nhs.uk/information-about-patients/"
													target="_blank"
												>
													www.hra.nhs.uk/information-about-patients/
												</a>
												, by reading our leaflet at{" "}
												<a
													href="http://www.hra.nhs.uk/patientdataandresearch"
													target="_blank"
												>
													www.hra.nhs.uk/patientdataandresearch
												</a>
												, by contacting the research
												team using{" "}
												<a href="mailto:neon@nottingham.ac.uk">
													neon@nottingham.ac.uk
												</a>{" "}
												or 07973 841271, or by
												contacting the study sponsor
												using{" "}
												<a href="mailto:research@nottshc.nhs.uk">
													research@nottshc.nhs.uk
												</a>
											</p>
										</li>
										<li>
											<h3>
												I have some more questions about
												the {participantData.trial}{" "}
												Trial. Who can I contact?
											</h3>
											<p>
												You can contact the research
												team using{" "}
												<a href="mailto:neon@nottingham.ac.uk?subject=Neon Study">
													neon@nottingham.ac.uk
												</a>
												, or you can leave a voice
												message or send a text to 07973
												841271. We aim to respond within
												three working days.
											</p>
										</li>
										<li>
											<h3>
												How can I learn about the
												results of the{" "}
												{participantData.trial} Trial?
											</h3>
											<p>
												All results will be published in
												a format that is openly
												accessible to the public. You
												will be able to find all of our
												study publications on the study
												website:{" "}
												<a
													href="http://www.researchintorecovery.com/neon"
													target="_blank"
												>
													http://www.researchintorecovery.com/neon
												</a>
												. The results of the
												{participantData.trial} Trial
												will not be published until 2022
												at least. Participants will
												never be named in publications.
											</p>
										</li>
										<li>
											<h3>
												Who is funding the NEON study
												and the {participantData.trial}{" "}
												Trial?
											</h3>
											<p>
												The National Institute for
												Health Research
											</p>
											<Button
												variant="secondary"
												onClick={toggleGrant}
											>
												<i className="fas fa-caret-down" />{" "}
												More information
											</Button>
											<Collapse
												in={showGrant}
												id="funding"
											>
												<div>
													<div className="card card-body">
														<p>
															The grant number for
															the NEON study is
															RP-PG-0615-20016,
															and it is a
															Programme Grant for
															Applied Research.
														</p>
													</div>
												</div>
											</Collapse>
										</li>
										<li>
											<h3>
												What should I do if I feel
												distressed whilst using the NEON
												Intervention?
											</h3>
											<p>
												If you choose to sign up to
												NEON, then you'll have access to
												lots of ideas for what to do.
												Click on the page called “I'm
												feeling upset” as a starting
												point. This page has been
												developed by people with their
												own experiences of mental health
												distress. It includes ideas for
												self-management of distress,
												links to online peers support
												services, and details of support
												that can be accessed through the
												NHS or charities.
											</p>
										</li>
										<li>
											<h3>
												Something has gone wrong - who
												should I contact?
											</h3>
											<p>
												If you are experiencing
												technical problems with our
												online system, please contact{" "}
												<a href="mailto:neon@nottingham.ac.uk">
													neon@nottingham.ac.uk
												</a>
												. If you are worried about how
												the study is being run, please
												contact the study coordinator{" "}
												<a href="mailto:stefan.egglestone@nottingham.ac.uk">
													stefan.egglestone@nottingham.ac.uk
												</a>{" "}
												Alternatively, you can contact
												the study sponsor{" "}
												<a href="mailto:Research@nottshc.nhs.uk">
													Research@nottshc.nhs.uk
												</a>{" "}
												or contact your local Patient
												Advice and Liaison service,
												whose contact details can be
												found on the Internet.
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<input
							type="hidden"
							id="partID"
							value={participantData.partID}
						/>
						<div className="row mb-5 text-center">
							<div className="col-6">
								<Link
									to={"/consent"}
									className="btn btn-success"
								>
									I do wish to take part in the trial
								</Link>
							</div>
							<div className="col-6">
								<Link
									to={"/noconsent"}
									className="btn btn-danger"
								>
									I do not wish to take part in the trial
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Information;
