import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { ERROR, ACTIONS } from "../state/Config.js";

const Demographic = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const onDataChange = event => {
		formDataRef.current = {
			...formDataRef.current,
			[event.target.name]: event.target.value
		};
		// DEBUG
		console.log("Form = ", formDataRef.current);
	};

	const onSubmitDemographics = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"/categories/demographic",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit demographic data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={0} />
					</div>
					<div>
						<h1>Information about you</h1>
					</div>
					<div className="mt-4">
						<p>
							This information will help us understand who is
							taking part in our trials.
						</p>
						{/* PS added hr */}
						<hr className="mt-5" />
					</div>

					{/* PS added container-fluid */}
					<div className="container-fluid mb-3">
						<Form
							onSubmit={onSubmitDemographics}
							id="demographic"
							className="mb-5"
						>
							<ol className="consent-points">
								<li>
									<Form.Group>
										{/* PS added classes */}
										<Form.Label className="not-full-width">
											What is your age in years?
										</Form.Label>
										<Form.Control
											onChange={onDataChange}
											type="number"
											name="age"
											min="18"
											className="align-bottom"
											required
										/>
									</Form.Group>
								</li>
								<li>
									<Form.Group>
										<Form.Label>
											What best describes your gender?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											name="gender"
											required
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">Female</option>
											<option value="2">Male</option>
											<option value="3">Other</option>
										</Form.Select>
									</Form.Group>
								</li>
								<li>
									<Form.Group>
										{/* PS wrapped label tags round form elements */}
										<Form.Label>
											What best describes your ethnicity?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											name="ethnicity"
											required
										>
											<option>
												Select an answer from the list
											</option>
											<optgroup label="White">
												<option value="1">
													British
												</option>
												<option value="2">Irish</option>
												<option value="3">
													Gypsy or Irish Traveller
												</option>
												<option value="4">
													Any other White background
												</option>
											</optgroup>
											<optgroup label="Mixed / Multiple ethnic groups">
												<option value="5">
													White and Black Caribbean
												</option>
												<option value="6">
													White and Black African
												</option>
												<option value="7">
													White and Asian
												</option>
												<option value="8">
													Any other Mixed / Multiple
													ethnic background
												</option>
											</optgroup>
											<optgroup label="Asian / Asian British">
												<option value="9">
													Indian
												</option>
												<option value="10">
													Pakistani
												</option>
												<option value="11">
													Bangladeshi
												</option>
												<option value="12">
													Chinese
												</option>
												<option value="13">
													Any other Asian background
												</option>
											</optgroup>
											<optgroup label="Black / African / Caribbean / Black British">
												<option value="14">
													African
												</option>
												<option value="15">
													Caribbean
												</option>
												<option value="16">
													Any other Black / African /
													Caribbean background
												</option>
											</optgroup>
											<optgroup label="Other ethnic group">
												<option value="17">Arab</option>
												<option value="18">
													Any other ethnic group
												</option>
											</optgroup>
										</Form.Select>
									</Form.Group>
								</li>
								<li>
									<Form.Group>
										<Form.Label>
											What region of England do you
											currently live in?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											id="region"
											name="region"
											required
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">
												East of England
											</option>
											<option value="2">London</option>
											<option value="3">Midlands</option>
											<option value="4">
												North East and Yorkshire
											</option>
											<option value="5">
												North West
											</option>
											<option value="6">
												South East
											</option>
											<option value="7">
												South West
											</option>
										</Form.Select>
									</Form.Group>
								</li>
								<li>
									<Form.Group>
										<Form.Label>
											What best describes your highest
											qualification?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											id="highestSelected"
											name="education"
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">
												No qualifications
											</option>
											<option value="2">
												O-levels/GCSE or equivalent
											</option>
											<option value="3">
												A-levels/AS-levels/NVQ or
												equivalent
											</option>
											<option value="4">
												Degree-level qualification
											</option>
											<option value="5">
												Higher degree-level
												qualification
											</option>
										</Form.Select>
									</Form.Group>
								</li>
								<li>
									<Form.Group>
										{/* PS changed span to label */}
										<Form.Label>
											Have you <u>ever</u> (including
											currently) used primary care mental
											health services, e.g. had support or
											medication prescribed by your GP for
											mental health issues, seen a GP
											practice counsellor, or used the
											Increasing Access to Psychological
											Therapies (IAPT) programme?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											name="primary"
											required
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">Yes</option>
											<option value="2">No</option>
										</Form.Select>
									</Form.Group>
								</li>
								<li>
									<Form.Group>
										{/* PS changed span to label */}
										<Form.Label>
											Have you <u>ever</u> (including
											currently) used specialist mental
											healthcare services, e.g. a
											community mental health team, mental
											health in-patient ward?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											name="specialist"
											required
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">Yes</option>
											<option value="2">No</option>
										</Form.Select>
									</Form.Group>
								</li>
								{participantData.onNeonTrial && (
									<React.Fragment>
										{" "}
										<li>
											<Form.Group>
												<Form.Label>
													Which of the following best
													describes the <u>current</u>{" "}
													contact you have with the
													NHS about your experiences
													of psychosis?
												</Form.Label>
												<Form.Select
													onChange={onDataChange}
													name="contact"
												>
													<option>
														Select an answer from
														the list
													</option>
													<option value="1">
														No contact with any NHS
														service
													</option>
													<option value="2">
														Contact with my GP
													</option>
													<option value="3">
														Contact with primary
														care counsellor
													</option>
													<option value="4">
														Contact with Improving
														Access to Psychological
														Therapies (IAPT)
													</option>
													<option value="5">
														Contact with a
														specialist community
														mental health team
													</option>
													<option value="6">
														Currently a mental
														health in-patient in
														hospital
													</option>
												</Form.Select>
											</Form.Group>
										</li>
									</React.Fragment>
								)}{" "}
								<li>
									<Form.Group>
										<Form.Label>
											Which of the following best
											describes the main mental health
											problem you have experienced in the
											last month?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											id="problems"
											name="problems"
											required
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">
												I don't want to say
											</option>
											<option value="2">
												I did not experience mental
												health problems
											</option>
											<option value="3">
												Developmental disorder such as
												learning disability
											</option>
											<option value="4">
												Eating disorder such as anorexia
												or bulimia
											</option>
											<option value="5">
												Mood disorder such as
												depression, anxiety or bipolar
											</option>
											<option value="7">
												Personality disorder such as
												borderline personality disorder
											</option>
											<option value="8">
												Schizophrenia or other psychosis
												such as schizo-affective
												disorder or delusional disorder
											</option>
											<option value="9">
												Stress-related disorders such as
												PTSD or OCD
											</option>
											<option value="10">
												Substance-related disorder such
												as alcohol or drug mis-use
											</option>
										</Form.Select>
									</Form.Group>
								</li>
								<li id="recovery" className="d-none">
									<Form.Group>
										<Form.Label>
											Which of the following best describe
											how you are thinking about your
											recovery from mental health
											problems? These terms have been
											taken from a research model.
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											id="recoverySelected"
											name="recovery"
										>
											<option>
												Select an answer from the list
											</option>
											<option value="0">
												I don't want to say
											</option>
											<option value="1">
												Not yet thinking about recovery
											</option>
											<option value="2">
												Working on recovery
											</option>
											<option value="3">
												Living beyond disability
											</option>
										</Form.Select>
									</Form.Group>
								</li>
							</ol>
							<div className="row">
								<input
									type="hidden"
									name="formRequestID"
									value={props.formRequestID}
								/>
								<div className="col-6">
									{/* PS added ml-1 */}
									<button
										type="submit"
										className="btn btn-primary ms-1"
									>
										<i className="fas fa-check" /> Submit
									</button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Demographic;
