import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ERROR, ACTIONS } from "../state/Config.js";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { Link } from "react-router-dom";
import useStore from "../state/store.js";

const Login = props => {
	const [errorMsg, setErrorMsg] = useState("");
	const [formData, setFormData] = useState({ username: "", password: "" });
	const { username, password } = formData;
	const setLoginStatus = useStore(state => state.setLoginStatus);

	// Participant data
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	const navigate = useNavigate();

	const onChange = event =>
		setFormData({ ...formData, [event.target.name]: event.target.value });

	const OnSubmit = async event => {
		event.preventDefault();
		try {
			const response = await postData("/users/login", formData);
			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setLoginStatus(true);
							setParticipantData(data);
							data.title
								? navigate(`/questions/${data.page}`)
								: navigate(`/stories/${data.page}`);
							break;

						default:
							break;
					}

				default:
					setErrorMsg("Invalid login - please try again");
					break;
			}
		} catch (error) {
			console.log("Login failed =", error);
		}
	};

	return (
		<React.Fragment>
			<div className="container mt-3">
				<a
					className="sr-only sr-only-focusable text-primary"
					href="#main"
				>
					Skip to main content
				</a>
				<div className="row">
					<div className="col-12 text-center mb-3">
						<div id="nhs" className="logoPanel">
							<img
								src="/images/NHS_Logo.png"
								alt="NHS logo: Nottinghamshire Healthcare NHS Foundation Trust"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 neon-logo-desktop mt-5 pt-5">
						<img
							src="/images/NEON_logo_strapline_portrait.png"
							alt="NEON logo: Narrative Experiences ONline"
							width="540"
							height="46"
						/>
					</div>
					<div className="col-12 neon-logo-mobile mt-3">
						<img
							src="/images/NEON_logo_strapline_mobile.png"
							alt="NEON logo: Narrative Experiences ONline"
							width="200"
							height="54"
						/>
					</div>
					<div className="col-12 mt-3 mb-5">
						<div className="trialprocedures-stripe-top" />
					</div>
				</div>
			</div>

			<div className="container">
				<div>
					<h1>User Login</h1>
				</div>
				{/* PS moved text */}
				Please enter your username and password to log in.
				{/* PS changed form formatting, including new cols, added for and id, new classes */}
				<form className="mb-5 mt-5" onSubmit={OnSubmit}>
					<div className="form-group row">
						<label
							className="col-4 col-form-label text-right"
							htmlFor="username"
						>
							Email
						</label>
						<div className="col-7 col-md-6 mb-3">
							<input
								type="email"
								className="form-control"
								id="username"
								name="username"
								placeholder="Enter email"
								required
								value={username}
								onChange={onChange}
							/>
						</div>
						<div className="col-1 col-md-2" />
					</div>
					<div className="form-group row">
						<label
							className="col-4 col-form-label text-right"
							htmlFor="password"
						>
							Password
						</label>
						<div className="col-7 col-md-6 mb-3">
							<input
								type="password"
								className="form-control"
								id="password"
								name="password"
								placeholder="password"
								required
								value={password}
								onChange={onChange}
							/>
						</div>
						<div className="col-1 col-md-2" />
					</div>
					<div className="form-group row">
						<div className="col-4" />
						<div className="col-8 mb-2">
							<button type="submit" className="btn btn-primary">
								<i className="fas fa-sign-in-alt" /> Login
							</button>
							{/* PS moved text <span class="text-success">Please enter your username and password to log in.</span>*/}
						</div>
						<div className="col-12">
							<p className="text-danger font-weight-bold">
								{props.header}
							</p>
							<p className="text-danger">{errorMsg}</p>
						</div>
					</div>
					{/* May have narrative to look at straight after login */}
					<input type="hidden" name="story" value={props.story} />
				</form>
				{/* PS added hr */}
				<hr className="mb-5" />
				{/* PS reformatted */}
				<div className="row mb-5">
					<div className="col-12 col-md-4 text-center text-md-right">
						<strong>Forgotten your password?</strong>
					</div>
					<div className="col-0 col-md-8" />
					<div className="col-4 hidden-md" />
					<div className="col-8 change-position">
						<Link to="/reset" className="btn btn-secondary">
							<i className="fas fa-sync-alt" /> Reset
						</Link>
					</div>
				</div>
				<p className="blueLinks">
					If you would like to talk to someone about how to use the
					system, then please contact{" "}
					<a href="mailto:neon@nottingham.ac.uk?subject=Neon Study">
						neon@nottingham.ac.uk
					</a>
					.
				</p>
			</div>
		</React.Fragment>
	);
};

export default Login;
