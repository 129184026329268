import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactBootstrapSlider from "react-bootstrap-slider";
import postData from "../data/PostData.js";
import useFetch from "../hooks/useFetch.js";
import { pdfjs, Document, Page } from "react-pdf";
import { ERROR, ACTIONS, LABELS } from "../state/Config.js";
import AlertModal from "../modals/AlertModal.js";
import { Link } from "react-router-dom";

const pdfOptions = {
	cMapUrl: "/cmaps/",
	cMapPacked: true
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDF_PADDING = 20;
const PDF_SCALE_INCREMENT = 0.175;

const SingleStory = ({ state, triggers }) => {
	const story = state.storyName;
	const route = state.route;
	const categories = state.categories;
	const first = state.first;

	let queryString = route ? `&route=${route}` : "";
	queryString += categories ? `&categories=${categories}` : "";
	queryString += first ? `&first=${first}` : "";

	// Get user state
	let storyState = useFetch(
		`/categories/showStory?story=${story}${queryString}`
	);

	if (!triggers) {
		storyState = state;
	}

	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [showExpanded, setShowExpanded] = useState(false);
	const [docScale, setDocScale] = useState(1);
	const [showBookmarkReason, setShowBookmarkReason] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const docWidth = useRef();

	const navigate = useNavigate();

	const fileLoaded = ({ numPages }) => {
		setNumPages(numPages);
	};

	const showSliders = () => {
		setShowExpanded(true);
	};

	const zoomChanged = event => {
		const zoom = event.target.value;
		setDocScale(1 + zoom * PDF_SCALE_INCREMENT);
	};

	const formatZoomLabels = value => {
		const tooltip = document
			.getElementById("zoom")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.ZOOM_MAX) * 100}%`;

		return LABELS.Zoom[value];
	};

	// Ratings
	const ratingsDataRef = useRef({});

	const formatHopefulLabels = value => {
		const tooltip = document
			.getElementById("hopefull")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value + 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		ratingsDataRef.current = {
			...ratingsDataRef.current,
			["hopefulRatings"]: value
		};

		return LABELS.Hopeful[value + 1];
	};

	const formatSimilarLabels = value => {
		const tooltip = document
			.getElementById("similar")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.SIMILAR_MAX) * 100}%`;

		ratingsDataRef.current = {
			...ratingsDataRef.current,
			["storyTellerRatings"]: value
		};

		return LABELS.Similar[value];
	};

	const formatLifeLabels = value => {
		const tooltip = document
			.getElementById("life")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.LIFE_MAX) * 100}%`;

		ratingsDataRef.current = {
			...ratingsDataRef.current,
			["storyLifeRatings"]: value
		};

		return LABELS.Life[value];
	};

	const formatLearningLabels = value => {
		const tooltip = document
			.getElementById("learning")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.LEARNING_MAX) * 100}%`;

		ratingsDataRef.current = {
			...ratingsDataRef.current,
			["learnRatings"]: value
		};

		return LABELS.Learning[value];
	};

	const formatEmotionalLabels = value => {
		const tooltip = document
			.getElementById("emotional")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.EMOTION_MAX) * 100}%`;

		ratingsDataRef.current = {
			...ratingsDataRef.current,
			["connectedRatings"]: value
		};

		return LABELS.Emotional[value];
	};

	// Block story
	const onBlockStory = async event => {
		event.preventDefault();

		try {
			const formData = {
				storyID: storyState.storyName
			};
			const response = await postData("/categories/blockStory", formData);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							navigate(`/stories/${data.page}`);
							break;

						default:
							break;
					}

				default:
					break;
			}
		} catch (error) {
			console.log("Could not block story");
		}
	};

	const bookMarkText = useRef("");
	const [reasonText, setReasonText] = useState(
		(storyState && storyState.bookMarkText) || ""
	);

	const openOrRemoveBookmark = () => {
		setShowBookmarkReason(true);
		// If already bookmarked then remove bookmark
		if (storyState && storyState.bookMarked) {
			const formData = {
				storyID: storyState.storyName,
				bookMarked: true
			};
			const removeBookmark = async () => {
				try {
					const response = await postData(
						"/categories/likeStory",
						formData
					);
					const { status, data } = response;

					switch (status) {
						case ERROR.NO_ERROR:
							bookMarkText.current = data.msg;
							setShowAlert(true);
							break;

						default:
							break;
					}
				} catch (error) {
					console.log("Could not remove bookmark");
				}
			};

			removeBookmark();
		}
	};

	const closeAlert = () => {
		setShowAlert(false);
	};

	const updateReasonText = event => {
		setReasonText(event.target.value);
	};

	const onBookmarkStory = async event => {
		event.preventDefault();

		try {
			const formData = {
				storyID: storyState.storyName,
				bookMarked: storyState.bookMarked,
				reasonText: reasonText
			};
			const response = await postData(
				"/categories/bookMarkReason",
				formData
			);
			const { status, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					bookMarkText.current = data.msg;
					setShowAlert(true);
					break;

				default:
					break;
			}
		} catch (error) {
			console.log("Could not bookmark story");
		}
	};

	const onSubmitRatings = async event => {
		event.preventDefault();

		try {
			ratingsDataRef.current["story"] =
				storyState && storyState.storyName;
			ratingsDataRef.current["narrativeRequestID"] =
				storyState && storyState.narrativeRequestID;
			// DEBUG
			console.log("Ratings = ", ratingsDataRef.current);
			const response = await postData(
				"/categories/submitRatings",
				ratingsDataRef.current
			);
			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							navigate(`/stories/${data.page}`);
							break;

						default:
							break;
					}

				default:
					break;
			}
		} catch (error) {
			console.log("Could not submit ratings");
		}
	};

	return (
		<div className="container mt-5">
			<div id="display" />
			<div>
				{storyState && storyState.doc && (
					<React.Fragment>
						{" "}
						<div className="row d-none d-lg-flex">
							<div className="col-1" />
							<div className="col-10">
								<div className="row">
									<div className="col-6 text-start">
										<label className="form-check-label">
											<i className="fas fa-search-minus lead" />{" "}
											<strong>Zoom out</strong>
										</label>
									</div>
									<div className="col-6 text-end">
										<label className="form-check-label">
											<strong>Zoom in</strong>{" "}
											<i className="fas fa-search-plus lead" />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<ReactBootstrapSlider
											id="zoom"
											value={0}
											min={0}
											max={3}
											ticks={[0, 1, 2, 3]}
											ticks_tooltip={true}
											change={zoomChanged}
											formatter={formatZoomLabels}
										/>
									</div>
								</div>
							</div>
							<div className="col-1" />
						</div>
						<div className="row d-flex d-lg-none">
							<div className="col-6 text-end">
								<button
									id="zoomInDoc"
									className="btn btn-secondary btn-lg"
								>
									<i className="fas fa-search-plus lead" />{" "}
									Zoom in
								</button>
							</div>
							<div className="col-6 text-start">
								<button
									id="zoomOutDoc"
									className="btn btn-secondary btn-lg"
								>
									<i className="fas fa-search-minus lead" />{" "}
									Zoom out
								</button>
							</div>
						</div>
						<div id="pdfFilename" className="d-none">
							{storyState && storyState.doc}
						</div>
					</React.Fragment>
				)}
				<ul className="nav nav-tabs mt-4">
					<li className="nav-item">
						<div className="nav-link active">
							<strong>
								Story no. {storyState && storyState.storyName}
							</strong>
						</div>
					</li>
				</ul>
				<div ref={docWidth} id="docContent" className="mb-3">
					{storyState && storyState.doc && (
						<div className="pdfViewer">
							{" "}
							<Document
								file={`/categories/uploads/${storyState.doc}`}
								onLoadSuccess={fileLoaded}
								options={pdfOptions}
							>
								{Array.from(
									new Array(numPages),
									(el, index) => (
										<Page
											className="page"
											width={
												docWidth.current.clientWidth *
													docScale -
												PDF_PADDING
											}
											key={`page_${index + 1}`}
											pageNumber={index + 1}
										/>
									)
								)}
							</Document>
						</div>
					)}
					{storyState && storyState.image && (
						<div className="text-center">
							{" "}
							<img
								src={`./categories/uploads/${storyState.image}`}
							/>
						</div>
					)}
					{storyState && storyState.video && (
						<div>
							{" "}
							<video id="storyMedia" controls>
								<source
									src={`./categories/uploads/${storyState.video}`}
								/>
							</video>
						</div>
					)}
					{storyState && storyState.audio && (
						<div className="text-center">
							{" "}
							<audio
								id="storyMedia"
								controls
								controlsList="nodownload"
							>
								<source
									src={`./categories/uploads/${storyState.audio}`}
								/>
							</audio>
						</div>
					)}
					{storyState && storyState.text && (
						<div className="text-center">
							{" "}
							<p>{storyState.text}</p>
						</div>
					)}
					{storyState && storyState.url && (
						<div className="text-center">
							{" "}
							<iframe
								src={storyState.url}
								frameBorder="0"
								webkitallowfullscreen=""
								mozallowfullscreen=""
								allowFullScreen=""
							/>
						</div>
					)}
					{storyState && storyState.urlImage && (
						<div className="text-center">
							{" "}
							<img src={storyState.urlImage} />
						</div>
					)}
					{storyState && storyState.html && (
						<div className="text-center"> {storyState.html}</div>
					)}{" "}
				</div>
				{storyState && storyState.updateHTML && (
					<div className="mt-5 text-center">
						<p>
							<strong>An update from the narrator</strong>
						</p>
						{storyState.updateSegment}
					</div>
				)}
				{storyState && storyState.updateDoc && (
					<div className="pdfViewer">
						{" "}
						<Document
							file={`/categories/uploads/${storyState.updateDoc}`}
							onLoadSuccess={fileLoaded}
							options={pdfOptions}
						>
							{Array.from(new Array(numPages), (el, index) => (
								<Page
									className="page"
									width={
										docWidth.current.clientWidth *
											docScale -
										PDF_PADDING
									}
									key={`page_${index + 1}`}
									pageNumber={index + 1}
								/>
							))}
						</Document>
					</div>
				)}
				<div className="row">
					<div className="col-4" />
					<div className="col-4 blueLinks text-center">
						{storyState && storyState.codeDescription && (
							<React.Fragment>
								{" "}
								<p>{storyState.codeDescription}</p>
							</React.Fragment>
						)}
						{storyState && storyState.credit && (
							<React.Fragment>
								{" "}
								<p>Credit :</p> {storyState.credit}
							</React.Fragment>
						)}{" "}
					</div>
				</div>
			</div>

			<div className="row mt-5 mb-5 text-center fixedButton">
				<div className="col text-end">
					<Form onSubmit={onBlockStory}>
						<input
							type="hidden"
							id="storyID"
							name="storyID"
							// value={storyState && storyState.storyName}
						/>
						<button
							id="blockStory"
							type="submit"
							className="btn btn-danger btn-lg text-nowrap"
						>
							<i
								className="fas fa-trash-alt"
								aria-hidden="true"
							/>
							Block
						</button>
					</Form>
				</div>
				<div className="col text-start">
					<Button
						onClick={openOrRemoveBookmark}
						id="likeStory"
						className="btn btn-info btn-lg text-nowrap"
					>
						<i className="fas fa-heart" aria-hidden="true" />
						{storyState && storyState.bookMarked
							? "Remove Bookmark"
							: "Bookmark"}
					</Button>
				</div>
			</div>

			{(showBookmarkReason || (storyState && storyState.bookMarked)) && (
				<div id="bookMarkReasonContainer" className="container mb-3">
					<div className="row mb-2">
						<div className="col-12">
							<label
								htmlFor="bookMarkReasonText"
								className="form-label"
							>
								Click in the text box below to add your notes on
								this story, and then click "Update bookmark".
							</label>
							<textarea
								onChange={updateReasonText}
								name="bookMarkReasonText"
								className="form-control"
								id="bookMarkReasonText"
								rows="3"
								value={reasonText}
							/>
						</div>
					</div>
					<Form onSubmit={onBookmarkStory} className="row">
						<div className="col-2">
							<button
								id="bookMarkReason"
								type="submit"
								className="btn btn-info"
							>
								Update bookmark
							</button>
						</div>
					</Form>
				</div>
			)}

			<AlertModal
				showAlert={showAlert}
				handleClose={closeAlert}
				titleText="OK"
				alertText={bookMarkText.current}
			/>

			{/* PS added background colour */}
			<div className="container">
				<Form
					onSubmit={onSubmitRatings}
					id="submitRatings"
					className="bg-white pt-4 pb-3"
				>
					<div className="row mb-5">
						<div className="col-1" />
						<div className="col-10">
							<h2>
								<i
									className="far fa-lightbulb me-2 text-black-50 fa-fw"
									aria-hidden="true"
								/>{" "}
								How hopeful did the story leave you feeling?
							</h2>
							<ReactBootstrapSlider
								id="hopefull"
								value={-1}
								min={-1}
								max={2}
								ticks={[-1, 0, 1, 2]}
								ticks_tooltip={true}
								formatter={formatHopefulLabels}
							/>
							<div className="row">
								<div className="col-3 col-md-6 text-start">
									<label className="form-check-label fullText">
										<em>Less hopeful than before</em>
									</label>
									<label className="form-check-label shortText">
										<em>Less</em>
									</label>
								</div>
								<div className="col-3 text-center shortText">
									<label className="form-check-label">
										<em>Same</em>
									</label>
								</div>
								<div className="col-3 text-center shortText">
									<label className="form-check-label">
										<em>
											Bit
											<br />
											more
										</em>
									</label>
								</div>
								<div className="col-3 col-md-6 text-end">
									<label className="form-check-label fullText">
										<em>Much more hopeful</em>
									</label>
									<label className="form-check-label shortText">
										<em>
											Much
											<br />
											more
										</em>
									</label>
								</div>
							</div>
						</div>
						<div className="col-1" />
					</div>

					{showExpanded && (
						<div id="otherRatings">
							<div className="row mb-5">
								<div className="col-1" />
								<div className="col-10">
									<h2>
										<i
											className="fas fa-user-friends me-2 text-black-50 fa-fw"
											aria-hidden="true"
										/>{" "}
										How similar was the story-teller to you?
									</h2>
									<ReactBootstrapSlider
										id="similar"
										value={0}
										min={0}
										max={3}
										ticks={[0, 1, 2, 3]}
										ticks_tooltip={true}
										formatter={formatSimilarLabels}
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Not at all</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very much</em>
											</label>
										</div>
									</div>
								</div>
								<div className="col-1" />
							</div>
							<div className="row mb-5">
								<div className="col-1" />
								<div className="col-10">
									<h2>
										<i
											className="fas fa-people-carry me-2 text-black-50 fa-fw"
											aria-hidden="true"
										/>{" "}
										How similar was the story-teller's life
										to your life?
									</h2>
									<ReactBootstrapSlider
										id="life"
										value={0}
										min={0}
										max={3}
										ticks={[0, 1, 2, 3]}
										ticks_tooltip={true}
										formatter={formatLifeLabels}
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Not at all</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very much</em>
											</label>
										</div>
									</div>
								</div>
								<div className="col-1" />
							</div>
							<div className="row mb-5">
								<div className="col-1" />
								<div className="col-10">
									<h2>
										<i
											className="far fa-lightbulb me-2 text-black-50 fa-fw"
											aria-hidden="true"
										/>{" "}
										How much did you learn from the story?
									</h2>
									<ReactBootstrapSlider
										id="learning"
										value={0}
										min={0}
										max={3}
										ticks={[0, 1, 2, 3]}
										ticks_tooltip={true}
										formatter={formatLearningLabels}
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Nothing</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>A huge amount</em>
											</label>
										</div>
									</div>
								</div>
								<div className="col-1" />
							</div>

							<div className="row mb-5">
								<div className="col-1" />
								<div className="col-10">
									<h2>
										<i
											className="fas fa-hand-holding-heart me-3 text-black-50 fa-fw"
											aria-hidden="true"
										/>
										How emotionally connected did you feel
										with the story?
									</h2>
									<ReactBootstrapSlider
										id="emotional"
										value={0}
										min={0}
										max={3}
										ticks={[0, 1, 2, 3]}
										ticks_tooltip={true}
										formatter={formatEmotionalLabels}
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Not at all</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>A huge amount</em>
											</label>
										</div>
									</div>
								</div>
								<div className="col-1" />
							</div>
						</div>
					)}

					<input
						type="hidden"
						name="userId"
						// value={storyState && storyState.userId}
					/>
					<input
						type="hidden"
						name="story"
						// value={storyState && storyState.storyName}
					/>
					<input
						type="hidden"
						name="narrativeRequestID"
						// value={storyState && storyState.narrativeRequestID}
					/>

					<div className="row mt-4">
						<div className="col-1" />
						<div className="col-10 text-center text-lg-start">
							{storyState && storyState.first && (
								<React.Fragment>
									{" "}
									<input
										type="hidden"
										name="first"
										value="2"
									/>
								</React.Fragment>
							)}{" "}
							<button
								type="submit"
								className="btn btn-primary mb-3 me-3"
							>
								<i className="fas fa-check" /> Submit
							</button>{" "}
							{!showExpanded && (
								<span>
									<span className="align-middle"> or </span>
									<Button
										onClick={showSliders}
										variant="secondary"
										className="ms-3 me-3 mb-3"
									>
										<i className="fas fa-question" /> Answer
										more questions
									</Button>
									<span className="text-nowrap align-middle">
										to improve your matches
									</span>
								</span>
							)}
						</div>
					</div>
				</Form>
			</div>
			{storyState && storyState.first && (
				<React.Fragment>
					{" "}
					<div className="mt-3">
						<Link
							to={
								"/stories/fullWelcome?first=" +
								storyState.first +
								"&story=" +
								storyState.storyName
							}
							className="btn btn-primary"
						>
							Next
						</Link>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default SingleStory;
