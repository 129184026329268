import React, { useState } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ERROR, ACTIONS } from "../state/Config.js";
import { useNavigate, useLocation } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";

const EligibilityQ6 = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);

	const navigate = useNavigate();
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	const [showPsychosis, setShowPsychosis] = useState(false);

	const handleShow = () => setShowPsychosis(true);
	const handleClose = () => setShowPsychosis(false);

	const [formData, setFormData] = useState({
		experiences: "",
		partID: participantData.partID
	});

	const onChangeEligibility = event => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const onSubmitEligibility = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"users/validateEligibilityQ6",
				formData
			);

			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						setParticipantData(data);
						navigate(`/${data.page}`);
						break;

					default:
						break;
				}
			}
		} catch (error) {
			console.log("Could not submit form data", error);
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container mb-3">
					<div>
						<h3>NEON study eligibility</h3>
					</div>
					<div className="mt-4 blueLinks">
						<p>
							These questions allow us to identify which trial you
							are eligible for. Please think about your responses
							carefully. If you don't want to take part in a
							clinical trial, but would instead like to learn more
							about the NEON study, please visit the study website{" "}
							<a
								href="http://www.researchintorecovery.com/NEON"
								target="_blank"
							>
								http://www.researchintorecovery.com/NEON
							</a>
							. If you have already created an account you can
							login at{" "}
							<a href="https://recoverystories.uk/login">
								https://recoverystories.uk/login
							</a>
						</p>
					</div>
					{/* PS added hr */}
					<hr className="mt-4 mb-4" />

					<Form onSubmit={onSubmitEligibility}>
						<Form.Group className="blueLinks mb-3">
							<Form.Label>
								In the last five years have you had experiences
								diagnosed as{" "}
								<a href="#" onClick={handleShow}>
									psychosis
								</a>
								, or that you or others would call psychosis
								(such as seeing or hearing things that others
								have not, or having unusual beliefs that other
								people disagree with)?
							</Form.Label>
							<Form.Select
								name="experiences"
								required
								onChange={onChangeEligibility}
							>
								<option value="">
									Select an answer from the list
								</option>
								<option>Yes</option>
								<option>No</option>
							</Form.Select>
						</Form.Group>
						<button type="submit" className="btn btn-primary">
							Next
						</button>
					</Form>
				</div>
			</div>
			<StripeFooter />
			<Modal
				id="psychosis"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="psychosisLabel"
				aria-hidden="true"
				centered
				show={showPsychosis}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>Psychosis</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Psychosis is a term used to cover a group of diagnoses,
						including schizophrenia, schizoaffective disorder,
						schizophreniform disorder, delusional disorder, brief
						psychotic disorder, substance-induced psychotic disorder
						and paraphrenia.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default EligibilityQ6;
