import React from "react";

const MainHeader = () => {
	return (
		<div className="container mt-3">
			<a className="sr-only sr-only-focusable text-primary" href="#main">
				Skip to main content
			</a>
			<div className="row">
				<div className="col-12 text-center mb-3">
					<div id="nhs" className="logoPanel">
						<img
							src="/images/NHS_Logo.png"
							alt="NHS logo: Nottinghamshire Healthcare NHS Foundation Trust"
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 neon-logo-desktop mt-5 pt-5">
					<img
						src="/images/NEON_logo_strapline_portrait.png"
						alt="NEON logo: Narrative Experiences ONline"
						width="540"
						height="46"
					/>
				</div>
				<div className="col-12 neon-logo-mobile mt-3">
					<img
						src="/images/NEON_logo_strapline_mobile.png"
						alt="NEON logo: Narrative Experiences ONline"
						width="200"
						height="54"
					/>
				</div>
				<div className="col-12 mt-3 mb-5">
					<div className="trialprocedures-stripe-top" />
				</div>
			</div>
		</div>
	);
};

export default MainHeader;
