import React from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";

const Verification = props => (
	<div className="content mt-3">
		<MainHeader />
		<div className="container">
			<div className="mb-3">
				<h3>Email verification</h3>
			</div>

			<div className="mb-5">
				<p>Thank you for giving consent.</p>
				<p>
					We have sent you an email, and to join the trial you now
					need to click on the link in the email, which will verify
					your address.
				</p>
				<p className="blueLinks">
					You should check your Junk Mail folder if the email does not
					appear. If you haven't received it after an hour, then
					please contact us at{" "}
					<a href="mailto:neon@nottingham.ac.uk?subject=Email verification">
						neon@nottingham.ac.uk
					</a>{" "}
					or by texting us at 07973 841271 and we can help.
				</p>
				<p>You can safely close this window now.</p>
			</div>
		</div>
		<StripeFooter />
	</div>
);

export default Verification;
