import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactBootstrapSlider from "react-bootstrap-slider";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { LABELS, ERROR, ACTIONS } from "../state/Config.js";

const Core = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const formatTense = value => {
		const tooltip = document
			.getElementById("tenseRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["tenseRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatSupport = value => {
		const tooltip = document
			.getElementById("supportRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["supportRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatCope = value => {
		const tooltip = document
			.getElementById("copeRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["copeRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatTalking = value => {
		const tooltip = document
			.getElementById("talkingRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["talkingRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatPanic = value => {
		const tooltip = document
			.getElementById("panicRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["panicRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatPlans = value => {
		const tooltip = document
			.getElementById("plansRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["plansRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatSleep = value => {
		const tooltip = document
			.getElementById("sleepRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["sleepRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatDespair = value => {
		const tooltip = document
			.getElementById("despairingRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["despairingRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatUnhappy = value => {
		const tooltip = document
			.getElementById("unhappyRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["unhappyRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const formatUnwanted = value => {
		const tooltip = document
			.getElementById("unwantedRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		tooltip.style.left = `${(value / LABELS.DISTRESS_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["unwantedRatings"]: value
		};

		return LABELS.Distress[value];
	};

	const onSubmitCore = async event => {
		event.preventDefault();

		try {
			// DEBUG
			console.log("Form = ", formDataRef.current);

			const response = await postData(
				"/categories/core",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit core data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>Your recent experiences of distress</h1>
					</div>
					<div>
						{participantData.week52 ? (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand
									your recent experiences of distressing
									symptoms, so that we can see how these have
									changed during the trial.
								</p>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand
									your recent experiences of distressing
									symptoms, so that we can see how these
									change during the trial.
								</p>
							</React.Fragment>
						)}{" "}
					</div>
					<div className="mb-5">
						<p>
							This form has 10 statements about how you have been
							OVER THE LAST WEEK.
							<br />
							Please read each statement and think how often you
							felt that way last week.
						</p>
						<p>Over the last week:</p>
					</div>

					{/* PS added hr */}
					<hr className="mt-5 mb-5" />

					<Form
						onSubmit={onSubmitCore}
						id="distressRatings"
						className="mb-5 small-gap"
					>
						<ol className="questions">
							<li>
								<label htmlFor="tenseRatings">
									I have felt tense, anxious or nervous.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="tenseRatings"
											name="tenseRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatTense}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="supportRatings">
									I have felt I have someone to turn to for
									support when needed.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="supportRatings"
											name="supportRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatSupport}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="copeRatings">
									I have felt able to cope when things go
									wrong.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="copeRatings"
											name="copeRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatCope}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="talkingRatings">
									Talking to people has felt too much for me.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="talkingRatings"
											name="talkingRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatTalking}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="panicRatings">
									I have felt panic or terror.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="panicRatings"
											name="panicRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatPanic}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="plansRatings">
									I made plans to end my life.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="plansRatings"
											name="plansRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatPlans}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="sleepRatings">
									I have had difficulty getting to sleep or
									staying asleep.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="sleepRatings"
											name="sleepRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatSleep}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="despairingRatings">
									I have felt despairing or hopeless.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="despairingRatings"
											name="despairingRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatDespair}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="unhappyRatings">
									I have felt unhappy.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="unhappyRatings"
											name="unhappyRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatUnhappy}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="unwantedRatings">
									Unwanted images or memories have been
									distressing me.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="unwantedRatings"
											name="unwantedRatings"
											value={0}
											min={0}
											max={4}
											ticks={[0, 1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatUnwanted}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Not at all</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>
														Most or all the time
													</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ol>
						<div>
							<input
								type="hidden"
								name="formRequestID"
								value={props.formRequestID}
							/>
							<button
								type="submit"
								className="btn btn-primary ms-5"
							>
								<i className="fas fa-check" /> Submit
							</button>
						</div>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};
export default Core;
