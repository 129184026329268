import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { LABELS, ERROR, ACTIONS } from "../state/Config.js";

const EQ5D = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const onDataChange = event => {
		formDataRef.current = {
			...formDataRef.current,
			[event.target.name]: event.target.value
		};
		// DEBUG
		console.log("Form = ", formDataRef.current);
	};

	const onSubmitEQ5D = async event => {
		event.preventDefault();

		try {
			// DEBUG
			console.log("Form = ", formDataRef.current);

			const response = await postData(
				"/categories/EQ5D",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit mansa data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={60} />
					</div>
					<div>
						<h1>Your health status</h1>
					</div>
					<div>
						<p>
							The following information will help us to calculate
							how cost-effective NEON is.
						</p>
					</div>
					<div className="mb-5">
						<p>
							Under each heading, please select which best
							describes your health TODAY.
						</p>
					</div>
					{/* PS added hr */}
					<hr className="mt-5" />

					<Form
						onSubmit={onSubmitEQ5D}
						id="economicRatings"
						className="mb-5"
					>
						<ol>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>Mobility</Form.Label>
									<Form.Select
										onChange={onDataChange}
										name="mobility"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">
											I have no problems in walking about
										</option>
										<option value="2">
											I have slight problems in walking
											about
										</option>
										<option value="3">
											I have moderate problems in walking
											about
										</option>
										<option value="4">
											I have severe problems in walking
											about
										</option>
										<option value="5">
											I am unable to walk about
										</option>
									</Form.Select>
								</Form.Group>
							</li>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>Self-care</Form.Label>
									<Form.Select
										onChange={onDataChange}
										name="care"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">
											I have no problems washing or
											dressing myself
										</option>
										<option value="2">
											I have slight problems washing or
											dressing myself
										</option>
										<option value="3">
											I have moderate problems washing or
											dressing myself
										</option>
										<option value="4">
											I have severe problems washing or
											dressing myself
										</option>
										<option value="5">
											I am unable to wash or dress myself
										</option>
									</Form.Select>
								</Form.Group>
							</li>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>
										Usual activities (e.g. work, study,
										housework, family or leisure activities)
									</Form.Label>
									<Form.Select
										onChange={onDataChange}
										name="activities"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">
											I have no problems doing my usual
											activities
										</option>
										<option value="2">
											I have slight problems doing my
											usual activities
										</option>
										<option value="3">
											I have moderate problems doing my
											usual activities
										</option>
										<option value="4">
											I have severe problems doing my
											usual activities
										</option>
										<option value="5">
											I am unable to do my usual
											activities
										</option>
									</Form.Select>
								</Form.Group>
							</li>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>Pain / Discomfort</Form.Label>
									<Form.Select
										onChange={onDataChange}
										name="pain"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">
											I have no pain or discomfort
										</option>
										<option value="2">
											I have slight pain or discomfort
										</option>
										<option value="3">
											I have moderate pain or discomfort
										</option>
										<option value="4">
											I have severe pain or discomfort
										</option>
										<option value="5">
											I have extreme pain or discomfort
										</option>
									</Form.Select>
								</Form.Group>
							</li>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>
										Anxiety / Depression
									</Form.Label>
									<Form.Select
										onChange={onDataChange}
										name="anxiety"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">
											I am not anxious or depressed
										</option>
										<option value="2">
											I am slightly anxious or depressed
										</option>
										<option value="3">
											I am moderately anxious or depressed
										</option>
										<option value="4">
											I am severely anxious or depressed
										</option>
										<option value="5">
											I am extremely anxious or depressed
										</option>
									</Form.Select>
								</Form.Group>
							</li>
						</ol>
						<div>
							<button
								type="submit"
								className="btn btn-primary ms-1"
							>
								<i className="fas fa-check" /> Submit
							</button>
						</div>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default EQ5D;
