import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ReactBootstrapSlider from "react-bootstrap-slider";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { LABELS, ERROR, ACTIONS } from "../state/Config.js";

const Confidence = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const formatHappy = value => {
		const tooltip = document
			.getElementById("happyRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["happyRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatHopeful = value => {
		const tooltip = document
			.getElementById("hopefulRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["hopefulRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatGoal = value => {
		const tooltip = document
			.getElementById("goalRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["goalRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatSupport = value => {
		const tooltip = document
			.getElementById("supportRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["supportRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatEsteem = value => {
		const tooltip = document
			.getElementById("esteemRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["esteemRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatFriend = value => {
		const tooltip = document
			.getElementById("friendRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["friendRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatHospital = value => {
		const tooltip = document
			.getElementById("hospitalRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["hospitalRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatDay = value => {
		const tooltip = document
			.getElementById("dayRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["dayRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatLosing = value => {
		const tooltip = document
			.getElementById("losingRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["losingRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatDepressed = value => {
		const tooltip = document
			.getElementById("depressedRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["depressedRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatLonely = value => {
		const tooltip = document
			.getElementById("lonelyRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["lonelyRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatNervous = value => {
		const tooltip = document
			.getElementById("nervousRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["nervousRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatMental = value => {
		const tooltip = document
			.getElementById("mentalRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["mentalRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatAbuse = value => {
		const tooltip = document
			.getElementById("abuseRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["abuseRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatRight = value => {
		const tooltip = document
			.getElementById("rightRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["rightRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const formatAdvocate = value => {
		const tooltip = document
			.getElementById("advocateRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HAPPY_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["advocateRatings"]: value
		};

		return LABELS.Happy[adjusted];
	};

	const onSubmitConfidence = async event => {
		event.preventDefault();

		try {
			// DEBUG
			console.log("Form = ", formDataRef.current);

			const response = await postData(
				"/categories/confidence",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit mansa data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>Your confidence and empowerment</h1>
					</div>
					<div>
						{participantData.week52 ? (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand how
									confident you are about your ability to deal
									with life challenges, so that we can see how
									this has changed during the trial.
								</p>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand how
									confident you are about your ability to deal
									with life challenges, so that we can see how
									this changes during the trial.
								</p>
							</React.Fragment>
						)}{" "}
					</div>
					<div className="mb-5">
						<p>
							For each item, indicate how confident you are that
							you could do something to help yourself right now.
							<br />
							Rate the degree of your confidence by choosing a
							number from 1 to 6 where 1=very non-confident and
							6=very confident.
						</p>
					</div>
					{/* PS added hr */}
					<hr className="mt-5 mb-5" />

					<Form
						onSubmit={onSubmitConfidence}
						id="confidenceRatings"
						className="mb-5 small-gap"
					>
						<ol className="questions">
							<li>
								<label htmlFor="happyRatings">Be happy.</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="happyRatings"
										name="happyRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatHappy}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="hopefulRatings">
									Feel hopeful about the future.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="hopefulRatings"
										name="hopefulRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatHopeful}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="goalRatings">
									Set goals for yourself.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="goalRatings"
										name="goalRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatGoal}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="supportRatings">
									Get support when you need it.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="supportRatings"
										name="supportRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatSupport}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="esteemRatings">
									Boost your self-esteem.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="esteemRatings"
										name="esteemRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatEsteem}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="friendRatings">
									Make friends.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="friendRatings"
										name="friendRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatFriend}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="hospitalRatings">
									Stay out of hospital.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="hospitalRatings"
										name="hospitalRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatHospital}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="dayRatings">
									Face a bad day.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="dayRatings"
										name="dayRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatDay}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="losingRatings">
									Deal with losing someone to you.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="losingRatings"
										name="losingRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatLosing}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="depressedRatings">
									Deal with feeling depressed.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="depressedRatings"
										name="depressedRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatDepressed}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="lonelyRatings">
									Deal with feeling lonely.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="lonelyRatings"
										name="lonelyRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatLonely}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="nervousRatings">
									Deal with nervous feelings.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="nervousRatings"
										name="nervousRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatNervous}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="mentalRatings">
									Deal with symptoms related to your mental
									health diagnosis.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="mentalRatings"
										name="mentalRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatMental}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="abuseRatings">
									Say no to a person abusing you.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="abuseRatings"
										name="abuseRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatAbuse}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="rightRatings">
									Use your right to accept or reject mental
									health treatment.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="rightRatings"
										name="rightRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatRight}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="advocateRatings">
									Advocate for your needs.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="advocateRatings"
										name="advocateRatings"
										value={1}
										min={1}
										max={6}
										ticks={[1, 2, 3, 4, 5, 6]}
										ticks_tooltip={true}
										formatter={formatAdvocate}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Very non-confident</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Very confident</em>
											</label>
										</div>
									</div>
								</div>
							</div>
						</ol>
						<div>
							<input
								type="hidden"
								name="formRequestID"
								value={props.formRequestID}
							/>
							<button
								type="submit"
								className="btn btn-primary ms-5"
							>
								<i className="fas fa-check" /> Submit
							</button>
						</div>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Confidence;
