import React from "react";
import axios from "axios";

const getData = async (url, data) => {
	try {
		const response = await axios.get(url, data);
		return {
			status: response.data.status,
			action: response.data.action,
			data: response.data.data
		};
	} catch (error) {
		console.log("Could not get data", error);
	}
};

export default getData;
