import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactBootstrapSlider from "react-bootstrap-slider";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { LABELS, ERROR, ACTIONS } from "../state/Config.js";

const Mansa = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const formatLife = value => {
		const tooltip = document
			.getElementById("lifeRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["lifeRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatJob = value => {
		const tooltip = document
			.getElementById("jobRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["jobRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatFinance = value => {
		const tooltip = document
			.getElementById("financeRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["financeRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatFriend = value => {
		const tooltip = document
			.getElementById("friendRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["friendRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatLeisure = value => {
		const tooltip = document
			.getElementById("leisureRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["leisureRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatAccomodation = value => {
		const tooltip = document
			.getElementById("accommodationRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["accommodationRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatSafety = value => {
		const tooltip = document
			.getElementById("safetyRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["safetyRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatLive = value => {
		const tooltip = document
			.getElementById("liveRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["liveRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatSex = value => {
		const tooltip = document
			.getElementById("sexRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["sexRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatFamily = value => {
		const tooltip = document
			.getElementById("familyRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["familyRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatPhysical = value => {
		const tooltip = document
			.getElementById("physicalRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["physicalRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatMental = value => {
		const tooltip = document
			.getElementById("mentalRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["mentalRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const onSubmitMansa = async event => {
		event.preventDefault();

		try {
			// DEBUG
			console.log("Form = ", formDataRef.current);

			const response = await postData(
				"/categories/mansa",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit mansa data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>Your quality of life</h1>
					</div>

					<div>
						{participantData.week52 ? (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand
									your current quality of life, so that we can
									see how this has changed during the trial.
								</p>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand
									your current quality of life, so that we can
									see how this changes during the trial.
								</p>
							</React.Fragment>
						)}{" "}
					</div>
					{/* PS added hr */}
					<hr className="mt-5 mb-5" />

					<Form
						onSubmit={onSubmitMansa}
						id="qolRatings"
						className="mb-5 small-gap"
					>
						<ol className="questions">
							<li>
								<label htmlFor="lifeRatings">
									How satisfied are you with your life as a
									whole today?
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="lifeRatings"
											name="lifeRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatLife}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <li>
								{Boolean(props.occupation.employed) && (
									<React.Fragment>
										{" "}
										<label>
											How satisfied are you with your job
											as your main occupation?
										</label>
									</React.Fragment>
								)}
								{Boolean(props.occupation.sheltered) && (
									<React.Fragment>
										{" "}
										<label>
											How satisfied are you with your
											sheltered employment as your primary
											occupation?
										</label>
									</React.Fragment>
								)}
								{Boolean(props.occupation.education) && (
									<React.Fragment>
										{" "}
										<label>
											How satisfied are you with your
											training/education as your primary
											occupation?
										</label>
									</React.Fragment>
								)}
								{Boolean(props.occupation.unemployed) && (
									<React.Fragment>
										{" "}
										<label>
											How satisfied are you with being
											unemployed?
										</label>
									</React.Fragment>
								)}
								{Boolean(props.occupation.retired) && (
									<React.Fragment>
										{" "}
										<label>
											How satisfied are you with being
											retired?
										</label>
									</React.Fragment>
								)}{" "}
							</li> */}

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="jobRatings"
											name="jobRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatJob}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="financeRatings">
									How satisfied are you with your financial
									situation?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="financeRatings"
											name="financeRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatFinance}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="friendRatings">
									How satisfied are you with the number and
									quality of your friendships?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="friendRatings"
											name="friendRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatFriend}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="leisureRatings">
									How satisfied are you with your leisure
									activities?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="leisureRatings"
											name="leisureRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatLeisure}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="accommodationRatings">
									How satisfied are you with your
									accommodation?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="accommodationRatings"
											name="accommodationRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatAccomodation}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="safetyRatings">
									How satisfied are you with your personal
									safety?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="safetyRatings"
											name="safetyRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatSafety}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								{Boolean(props.alone) ? (
									<React.Fragment>
										{" "}
										<label htmlFor="liveRatings">
											How satisfied are you with living
											alone?
										</label>
									</React.Fragment>
								) : (
									<React.Fragment>
										{" "}
										<label htmlFor="liveRatings">
											How satisfied are you with the
											people that you live with?
										</label>
									</React.Fragment>
								)}{" "}
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="liveRatings"
											name="liveRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatLive}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="sexRatings">
									How satisfied are you with your sex life?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="sexRatings"
											name="sexRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatSex}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="familyRatings">
									How satisfied are you with your relationship
									with your family?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="familyRatings"
											name="familyRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatFamily}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="physicalRatings">
									How satisfied are you with your physical
									health?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="physicalRatings"
											name="physicalRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatPhysical}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<li>
								<label htmlFor="mentalRatings">
									How satisfied are you with your mental
									health?
								</label>
							</li>

							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="mentalRatings"
											name="mentalRatings"
											value={1}
											min={1}
											max={7}
											ticks={[1, 2, 3, 4, 5, 6, 7]}
											ticks_tooltip={true}
											formatter={formatMental}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Couldn't be worse</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Couldn't be better</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ol>
						<div>
							<input
								type="hidden"
								name="formRequestID"
								value={props.formRequestID}
							/>
							<button
								type="submit"
								className="btn btn-primary ms-5"
							>
								<i className="fas fa-check" /> Submit
							</button>
						</div>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Mansa;
