import React from "react";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import useFetch from "../hooks/useFetch.js";
import { Link } from "react-router-dom";

const History = props => {
	const historyState = useFetch("/categories/showHistory");

	return (
		<div className="content intervention">
			<MenuHeader />
			<div className="container mt-5">
				<div>
					<h1>My stories</h1>
				</div>

				{/* PS added tab, deleted extra columns, added bg-white class */}
				<ul className="nav nav-tabs mt-4">
					<li className="nav-item">
						<div className="nav-link active">
							<i
								className="far fa-lightbulb me-2"
								aria-hidden="true"
							/>{" "}
							<strong>Stories that make me feel hopeful</strong>
						</div>
					</li>
				</ul>

				<div className="text-center bg-white pt-4 pb-4 mb-5 min-height-half">
					{historyState && historyState.noRatedMsg}
					{historyState &&
						historyState.rated.map((item, i) => (
							<React.Fragment key={i}>
								{" "}
								<div className="pt-2 pb-2">
									<Link
										to={`/stories/checkStory?story=${item.StoryID}
											&route=${historyState.ratedRoute}`}
										className="btn btn-light story-button"
									>
										{item.Name}
									</Link>
								</div>
							</React.Fragment>
						))}{" "}
				</div>

				<ul className="nav nav-tabs mt-4">
					<li className="nav-item">
						<div className="nav-link active">
							<i
								className="fas fa-star me-2"
								aria-hidden="true"
							/>{" "}
							<strong>My bookmarked stories</strong>
						</div>
					</li>
				</ul>

				<div className="text-center bg-white pt-4 pb-4 mb-5 min-height-half">
					{historyState && historyState.noFavouritesMsg}
					{historyState &&
						historyState.favourites.map((item, i) => (
							<React.Fragment key={i}>
								{" "}
								<div className="pt-2 pb-2">
									<Link
										to={`/stories/checkStory?story=${item.StoryID}
											&route=${historyState.favouriteRoute}
											`}
										className="btn btn-info story-button"
									>
										{item.Name}
									</Link>
								</div>
							</React.Fragment>
						))}{" "}
				</div>
			</div>
			<FooterStories />
		</div>
	);
};

export default History;
