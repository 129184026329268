import React, { useEffect, useState } from "react";
import getData from "../data/getData.js";
import MenuHeader from "../partials/MenuHeader.js";
import LogoHeader from "../partials/LogoHeader.js";
import FooterStories from "../partials/FooterStories.js";
import Accordion from "react-bootstrap/Accordion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStore from "../state/store.js";

const About = props => {
	const [trialMode, setTrialMode] = useState("OPEN");
	// Navigation
	const navigate = useNavigate();

	// Get any url parameters
	const location = useLocation();
	const controlGroup = new URLSearchParams(location.search).get("status");

	const loggedIn = useStore(state => state.loggedIn);

	useEffect(() => {
		if (!loggedIn) {
			return navigate("/login");
		}

		const getTrialMode = async () => {
			const trialMode = await getData("/categories/trialMode", {});
			setTrialMode(trialMode.data);
		};

		getTrialMode();
	}, []);

	return (
		<div className="content intervention">
			{controlGroup ? <LogoHeader /> : <MenuHeader />}
			<div className="container mt-5 min-height-full">
				<div>
					<h1>About NEON</h1>
				</div>

				<Accordion flush>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							<i
								className="fas fa-question fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>What is NEON?</strong>
						</Accordion.Header>
						<Accordion.Body>
							<p>
								NEON is an intervention for people living with
								mental health problems or their carers or
								supporters. You might have any or no diagnosis,
								and you may or may not be a regular user of the
								NHS. Our research suggests that one person's
								recovery story can have a powerful and helpful
								influence on other people, and to make the
								impact of stories more widely available, we have
								assembled the world's largest collection of
								recorded recovery stories.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							<i
								className="fas fa-question fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>
								What recovery stories are included in NEON?
							</strong>
						</Accordion.Header>
						<Accordion.Body>
							<p>Stories in NEON are very diverse.</p>
							<p>
								For example, some people talk about how the
								mental health system has been helpful, and
								others talk about how the mental health system
								has been unhelpful. Everyone's recovery journey
								is different, so the stories should not be seen
								as giving advice.
							</p>
							<p>
								The stories in NEON have come from books and
								websites, or have been donated to NEON by
								individuals. They are presented as printed text,
								audio, video or artwork. Stories are only used
								if either the person telling their story has
								given permission, or they previously gave
								permission to their publishers for it to be used
								by others.
							</p>
							<p>
								The term "recovery" can mean different things to
								different people, so we have used an inclusive
								approach to choosing recovery stories. For the
								purpose of NEON, recovery was defined as the
								process of living as well as possible, as
								defined by the individual, with or without
								continued experience of mental health problems.
								We included recovery stories which contain
								adversity or struggle that relate to mental
								health problems and also strengths, successes or
								survival, as defined by the narrator or
								identifiable by others. It must also have events
								that occur over a period of time.
							</p>
							<p className="blueLinks">
								We did exclude some stories, for example because
								they included hate speech. We never excluded a
								story just because we disagreed with its
								perspective on recovery. The process we used to
								make decisions about including stories, and the
								process for requesting a story be removed, are
								described on a page on our study web-site:
								<a
									href="https://www.researchintorecovery.com/neoncollection"
									target="_blank"
								>
									https://www.researchintorecovery.com/neoncollection
								</a>
								.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>
							<i
								className="fas fa-question fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>How can I best use NEON?</strong>
						</Accordion.Header>
						<Accordion.Body>
							<p>
								The aim of NEON is to be helpful. Some stories
								may have more impact for you than others, so we
								encourage you to access a number of stories.
							</p>
							<p>
								NEON research has shown that connection with the
								story is the most powerful way in which good
								changes can happen. We suggest thinking or
								talking with others about the ways you felt
								connected to the story and the impact it has had
								on you. Some reasons a story can be helpful are:
							</p>
							<ul
								style={{
									listStyleType: "disc"
								}}
							>
								<li>
									it provides hope that a better future is
									possible -{" "}
									<em>"If they can do it, so can I"</em>
								</li>
								<li>
									it challenges negative views you might hold
									about yourself -{" "}
									<em>
										"I didn't think recovery was possible
										for someone like me"
									</em>
								</li>
								<li>
									it provides solidarity -{" "}
									<em>
										“I'm not alone - other people have gone
										through what I've been through”
									</em>
								</li>
								<li>
									it gives new ideas -{" "}
									<em>
										"I'd never thought about responding like
										that"
									</em>
								</li>
								<li>
									it puts things in perspective -{" "}
									<em>
										"It makes me realise not everything in
										my life is bad"
									</em>
								</li>
								<li>
									it gives new strength -{" "}
									<em>
										"Others have got through this, and so
										can I"
									</em>
								</li>
							</ul>
							<p>
								Recovery stories often place a lot of emphasis
								on interdependence - giving and getting support
								from each other. So if you do decide to make
								changes in your life as a result of these
								stories, we would encourage you to talk with
								others first. For example you might talk with
								your mental health worker to get their view
								before making a change to your treatment, or
								talk to a friend to get support if you decide to
								become more socially active. Getting someone
								else's views can help you to notice things you
								wouldn't have otherwise thought about. That
								person might also be a source of support if you
								do decide to make the change.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header>
							<i
								className="fas fa-question fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>
								How can I keep myself safe when using NEON?
							</strong>
						</Accordion.Header>
						<Accordion.Body className="blueLinks">
							<p>
								If you find a story distressing, and you don't
								want to see it again, then you can block it
								using the button at the bottom of the story. You
								can reveal blocked stories through the{" "}
								<Link to="/categories/profile?status=1">
									<strong>About me</strong>
								</Link>{" "}
								page if you decide you want to see them again.
							</p>
							<p>
								If there are particular types of content that
								you find distressing, then you can block all
								stories containing this content through the{" "}
								<Link to="/categories/profile?status=1">
									<strong>About me</strong>
								</Link>{" "}
								page. You can also block stories in a particular
								format.
							</p>
							<p>
								If you choose a story containing challenging
								content, then you will be asked if you want to
								continue before accessing the story.
							</p>
							<p>
								If you just want to leave the site for any
								reason, clicking the Get me out of here button
								takes you straight to Google. If you want to
								return to NEON, just click the back button on
								your browser.
							</p>
							<p>
								If you do feel distressed, you can click the{" "}
								<Link to="/categories/help" target="_blank">
									<strong>I'm Upset</strong>
								</Link>{" "}
								button at any point, to obtain some suggestions
								for self-care and links to other points of
								support.
							</p>
							<p>
								Having said all that, some short-term difficult
								feelings can be a normal response to a recovery
								story, and may be the start of a helpful and
								healing journey. So if a story does upset you,
								then you may want to reflect on why it is
								upsetting, and identify which parts of the story
								you are connecting with.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="4">
						<Accordion.Header>
							<i
								className="fas fa-question fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>Who is funding and running NEON?</strong>
						</Accordion.Header>
						<Accordion.Body className="blueLinks">
							<p>
								NEON stands for Narrative Experiences Online,
								and is a research study, funded by the UK
								National Health Service through a grant from the
								National Institute for Health Research (grant
								number RP-PG-0615-20016). The study is funded
								from March 2017 to October 2022, and sponsored
								by Nottinghamshire Healthcare NHS Foundation
								Trust{" "}
								<a
									href="https://www.nottinghamshirehealthcare.nhs.uk"
									target="_blank"
								>
									www.nottinghamshirehealthcare.nhs.uk
								</a>
								. More information about the NEON research study
								can be found on our study website{" "}
								<a
									href="https://www.researchintorecovery.com/NEON"
									target="_blank"
								>
									www.researchintorecovery.com/NEON
								</a>
								. Until October 2022 the study team can be
								contacted by sending an email to{" "}
								<a href="mailto:neon@nottingham.ac.uk?subject=Neon Study">
									neon@nottingham.ac.uk
								</a>
								. After October 2022 you can contact the study
								sponsor{" "}
								<a href="mailto:Research@nottshc.nhs.uk">
									Research@nottshc.nhs.uk
								</a>
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="5">
						<Accordion.Header bsPrefix="bg-primary text-white">
							<i
								className="fas fa-question fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>What are the NEON badges?</strong>
						</Accordion.Header>
						<Accordion.Body className="blueLinks">
							<p>
								Badges are a reflection of your usage of NEON.
								Simply using different parts of NEON can earn
								you a badge. There are 19 different badges that
								you can collect. Badges you have collected or
								could collect will appear on the Badges page.
								Badges that appear in colour are those that you
								have already collected. The faded badges are the
								ones that are still available to collect.
							</p>
							<p>
								We've added the Badges features to support
								people who like a record of how much they have
								used a system, but we're also very aware that
								some users might not like them, and that they
								might feel inappropriate to some users. If you
								would like to hide everything about badges, you
								can do this easily through the About me page.
								You can change your mind at any point.
							</p>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>

				{controlGroup ? (
					<React.Fragment>
						{" "}
						<div className="text-center">
							<Link
								to="/stories/showConsent?status=2"
								className="btn btn-secondary"
							>
								<i
									className="fas fa-tasks"
									aria-hidden="true"
								/>{" "}
								View consent form or withdraw from study
							</Link>
						</div>
					</React.Fragment>
				) : (
					trialMode !== "OPEN" && (
						<React.Fragment>
							{" "}
							<div className="text-center">
								<Link
									to="/stories/showConsent"
									className="btn btn-secondary"
								>
									<i
										className="fas fa-tasks"
										aria-hidden="true"
									/>{" "}
									View consent form or withdraw from study
								</Link>
							</div>
						</React.Fragment>
					)
				)}
			</div>
			{!controlGroup ? <FooterStories /> : null}
		</div>
	);
};

export default About;
