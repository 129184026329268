import React from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";

const RepeatConsent = props => (
	<div className="content mt-3">
		<MainHeader />
		<div className="container">
			<div className="mb-3">
				<h3>Consent Already Given</h3>
			</div>

			<div className="mb-5">
				<p>Thank you for consenting to take part in our study.</p>
				<p className="blueLinks">
					You've already provided your consent using a different email
					address, so our system will need to use that one. If you
					haven't received a validation email, then please check in
					your Junk folder, and if it doesn't arrive in a few hours,
					please contact{" "}
					<a href="mailto:neon@nottingham.ac.uk?subject=Email verification">
						neon@nottingham.ac.uk
					</a>{" "}
					and we'll work out how to help.
				</p>
				<p>You can safely close this window now.</p>
			</div>
		</div>
		<StripeFooter />
	</div>
);

export default RepeatConsent;
