import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const ContactModal = ({ showContact, handleClose }) => {
	return (
		<Modal
			tabIndex="-1"
			role="dialog"
			aria-labelledby="contactLabel"
			aria-hidden="true"
			centered
			show={showContact}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Contact Us</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>To contact us about any of the trials:</p>
				<div className="row mb-3 mt-3 blueLinks">
					<div className="col-5 text-end">Email:</div>
					<div className="col-7">
						<strong>
							<a
								href="mailto:neon@nottingham.ac.uk"
								target="_blank"
							>
								neon@nottingham.ac.uk
							</a>
						</strong>
					</div>
				</div>
				<div className="row mb-3 blueLinks">
					<div className="col-5 text-end">Send a text to:</div>
					<div className="col-7">
						<strong>07973 841271</strong>
					</div>
				</div>
				<div className="row mb-3 d-none">
					<div className="col-12">
						If you or someone you know has experienced problems
						while taking part in the trial:
					</div>
					<div className="col-5" />
					<div className="col-7">
						<Link className="btn btn-warning mt-3" to="/safetyAnon">
							<i className="fas fa-flag" /> Report an incident
						</Link>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ContactModal;
