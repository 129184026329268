import React, { useEffect, useState } from "react";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";
import useFetch from "../hooks/useFetch.js";
import getData from "../data/getData.js";
import axios from "axios";
import { ACTIONS, ERROR } from "../state/Config.js";
import useStore from "../state/store.js";

const SelectOption = () => {
	// Navigation
	const navigate = useNavigate();

	const loggedIn = useStore(state => state.loggedIn);

	// User state
	const [buttonClass, setButtonClass] = useState(
		"btn btn-dark very-dark story-button btn-lg"
	);
	const userState = useFetch("/categories/showStories");

	useEffect(() => {
		if (!loggedIn) {
			return navigate("/login");
		}
	}, []);

	useEffect(() => {
		if (userState) {
			userState.data.disableMatch &&
				setButtonClass(
					"btn btn-dark very-dark story-button btn-lg disabled"
				);
		}
	}, [userState]);

	// Logout state
	const [logoutState, setLogoutState] = useState(null);
	const OnLogout = async event => {
		event.preventDefault();
		// Would be nice to use hook here but can't
		const response = await axios.post("/users/logout");
		setLogoutState(response.data);
	};

	useEffect(() => {
		if (logoutState) {
			if (logoutState.status === ERROR.NO_ERROR) {
				navigate("/login");
			}
		}
	}, [logoutState]);

	const matchStory = async () => {
		try {
			const response = await getData("/categories/matchStory", {});

			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						navigate(
							`/stories/${data.page}?story=${data.narrativeID}&route=${data.route}`
						);
						break;

					default:
						break;
				}
			}
			if (status === ERROR.GENERAL_ERROR) {
				navigate("/error");
			}
		} catch (error) {
			console.log("Could not match story");
		}
	};

	return (
		<>
			<div className="content intervention">
				<MenuHeader />
				<React.Fragment>
					{userState &&
						userState.data.showBadges &&
						userState.data.alertProps && (
							<React.Fragment>
								<div className="container mt-4 badge-message">
									<div className="badge-message-1">
										Your most recent badge:
									</div>
									<div className="badge-message-2">
										<img
											src={
												"/images/badge" +
												userState.data.alertProps
													.badgeID +
												".png"
											}
											alt={
												userState.data.alertProps
													.altText
											}
											width="152"
											height="152"
										/>
									</div>
									<div className="badge-message-3">
										{userState.data.alertProps.badgeText}
									</div>
									<div className="badge-message-4">
										{userState.data.alertProps.badgeDesc}
									</div>
								</div>
							</React.Fragment>
						)}
					<div className="container mt-2 min-height-full">
						{/* PS added tab, changed divs, deleted extra columns, added bg-white and story-button class */}
						<ul className="nav nav-tabs mt-4">
							<li className="nav-item">
								<div className="nav-link active">
									<i className="fas fa-home me-2" />{" "}
									<strong>Home</strong>
								</div>
							</li>
						</ul>

						<div className="text-center bg-white pt-4 pb-4 mb-3">
							<div className="pt-2 pb-1">
								<Button
									onClick={matchStory}
									className={buttonClass}
								>
									<i
										className="fas fa-arrows-alt-h me-3"
										aria-hidden="true"
									/>
									<strong>Match me to a story</strong>
								</Button>
							</div>
							<div className="pb-2 blueLinks text-secondary ms-2 me-2">
								<Link to="/stories/profile?status=1">
									If we have enough information about you
								</Link>
								, we'll recommend a story
							</div>
							<div className="pt-3 pb-1">
								<Link
									to="/stories/checkStory?story=-1"
									className="btn btn-dark story-button btn-lg"
								>
									<i
										className="fas fa-random me-3"
										aria-hidden="true"
									/>
									<strong>Get me a random story</strong>
								</Link>
							</div>
							<div className="pb-2 text-secondary ms-2 me-2">
								Show me a story I haven't seen before
							</div>
							<div className="pt-3 pb-1">
								<Link
									to="/stories/browse"
									className="btn btn-light story-button btn-lg"
								>
									<i
										className="fas fa-list-ul me-3"
										aria-hidden="true"
									/>{" "}
									<strong>Browse stories</strong>
								</Link>
							</div>
							<div className="pb-2 text-secondary ms-2 me-2">
								Find stories with characteristics that interest
								you
							</div>
							<div className="pt-3 pb-1">
								<Link
									to="/stories/showHistory"
									className="btn btn-info story-button btn-lg"
								>
									<i
										className="fas fa-history me-3"
										aria-hidden="true"
									/>{" "}
									<strong>My stories</strong>
								</Link>
							</div>
							<div className="pb-2 text-secondary ms-2 me-2">
								Access hopeful or bookmarked stories
							</div>
						</div>
						<div className="text-center bg-white pt-4 pb-4 mb-5">
							<div className="pt-3 pb-1">
								<Link
									to="/stories/profile?status=1"
									className="btn btn-primary story-button btn-lg"
								>
									<i
										className="fas fa-user-alt me-3"
										aria-hidden="true"
									/>{" "}
									<strong>About Me</strong>
								</Link>
							</div>
							<div className="pb-2 text-secondary">
								Update your profile with information about you,
								so we can match stories better
							</div>
							{userState && userState.data.showBadges && (
								<React.Fragment>
									{" "}
									<div className="pt-3 pb-1">
										<Link
											to="/stories/badges"
											className="btn btn-light story-button btn-lg"
										>
											<i
												className="fas fa-award me-3"
												aria-hidden="true"
											/>{" "}
											<strong>Badges</strong>
										</Link>
									</div>
									<div className="pb-2 text-secondary">
										Badges are a reflection of your usage of
										NEON
									</div>
								</React.Fragment>
							)}{" "}
						</div>

						<Form onSubmit={OnLogout} className="mt-5 text-center">
							<Button type="submit" variant="primary">
								<i className="fas fa-sign-out-alt" /> Logout
							</Button>
						</Form>
					</div>
				</React.Fragment>
				<FooterStories />
			</div>
		</>
	);
};

export default SelectOption;
