import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

const MenuHeader = () => {
	return (
		<>
			<div className="container-fluid mt-4">
				<div className="row bg-white">
					<div className="container pt-2 pb-2">
						<a
							className="sr-only sr-only-focusable text-primary"
							href="#main"
						>
							Skip to main content
						</a>
						<div className="row">
							<div className="col-9 col-xl-10 d-none d-lg-block pt-1">
								<img
									src="/images/NEON_logo_strapline_portrait.png"
									alt="NEON logo: Narrative Experiences ONline"
									width="540"
									height="46"
								/>
							</div>
							<div className="col-3 col-xl-2 d-none d-lg-block">
								{/* Menu nav bar */}
								<Navbar expand={false} className="navbar-dark">
									<Navbar.Toggle>
										<span className="navbar-toggler-icon" />{" "}
										MENU
									</Navbar.Toggle>
									<Navbar.Collapse>
										<Nav>
											<Link
												to="/stories/showOptions"
												active="true"
											>
												<i className="fas fa-home" />{" "}
												Home
											</Link>
											<Link to="/stories/fullWelcome">
												<i className="fas fa-thumbs-up" />{" "}
												Welcome
											</Link>
											<Link to="/stories/about">
												<img
													src="/images/neon_icon_16.png"
													alt="NEON icon"
													className="neon-icon"
												/>{" "}
												About NEON
											</Link>
											<Link to="/stories/help">
												<i className="fas fa-frown-open" />{" "}
												I'm upset
											</Link>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
							</div>
							<div className="col-7 col-md-9 d-lg-none">
								<img
									src="/images/NEON_logo_strapline_mobile.png"
									alt="NEON logo: Narrative Experiences ONline"
									width="200"
									height="54"
								/>
							</div>
							<div className="col-5 col-md-3 d-lg-none">
								{/* Menu nav bar */}
								<Navbar expand="xxl" className="navbar-dark">
									<Navbar.Toggle>
										<span className="navbar-toggler-icon" />
									</Navbar.Toggle>
									<Navbar.Collapse>
										<Nav>
											<Link
												to="/stories/showOptions"
												active="true"
											>
												<i className="fas fa-home" />{" "}
												Home
											</Link>
											<Link to="/stories/fullWelcome">
												<i className="fas fa-thumbs-up" />{" "}
												Welcome
											</Link>
											<Link to="/stories/about">
												<img
													src="/images/neon_icon_16.png"
													alt="NEON icon"
													className="neon-icon"
												/>{" "}
												About NEON
											</Link>
											<Link to="/stories/help">
												<i className="fas fa-frown-open" />{" "}
												I'm upset
											</Link>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MenuHeader;
