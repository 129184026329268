import React, { useState, useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import postData from "../data/PostData.js";
import AlertModal from "../modals/AlertModal.js";
import { ERROR, ACTIONS } from "../state/Config.js";
import useParticipantStore from "../state/participantStore.js";

const CreateAccount = props => {
	// Toggle show password
	const [passwordType, setPasswordType] = useState("password");
	const [errorMsg, setErrorMsg] = useState(null);
	const [showAlert, setShowAlert] = useState(false);

	const navigate = useNavigate();
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	// Get url parameters
	const location = useLocation();
	const username = new URLSearchParams(location.search).get("username");
	const trial = new URLSearchParams(location.search).get("trial");
	const phone = new URLSearchParams(location.search).get("phone");

	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	const loginRef = useRef();

	const closeAlert = () => {
		setShowAlert(false);
	};

	const onShowPassword = event => {
		setPasswordType(passwordType === "password" ? "text" : "password");
	};

	const onCreateAccount = async event => {
		event.preventDefault();

		// Check passwords
		if (passwordRef.current.value !== confirmPasswordRef.current.value) {
			setShowAlert(true);
			return;
		}

		const formData = {
			username,
			trial,
			phone,
			password: passwordRef.current.value,
			sendLogin: loginRef.current.value
		};

		try {
			const response = await postData("users/createAccount", formData);

			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						setParticipantData(data);
						navigate(`/${data.page}`);
						break;

					default:
						break;
				}
			}
		} catch (error) {
			console.log("Could not submit form data", error);
		}
	};
	return (
		<div className="content mt-3">
			<MainHeader />
			<div className="container">
				<div>
					<h3>Create a password</h3>
				</div>

				<div>
					<p>Please enter your new password here.</p>
				</div>

				<Form
					onSubmit={onCreateAccount}
					id="createPasswordForm"
					action=""
				>
					<Form.Group className="row mb-2">
						<label className="col-3 col-form-label">Email</label>
						<div className="col-9">
							<Form.Control
								type="email"
								name="username"
								value={username}
								readOnly
							/>
						</div>
					</Form.Group>
					<Form.Group className="row mb-2">
						<label className="col-3 col-form-label">Password</label>
						<div className="col-9">
							<Form.Control
								ref={passwordRef}
								type={passwordType}
								id="newPassword"
								name="password"
								placeholder="password"
								required
							/>
						</div>
					</Form.Group>
					<Form.Group className="row mb-2">
						<label className="col-3 col-form-label">Confirm</label>
						<div className="col-9">
							<Form.Control
								ref={confirmPasswordRef}
								type={passwordType}
								id="confirmPassword"
							/>
						</div>
					</Form.Group>
					<input type="hidden" name="trial" value={trial} />
					<input type="hidden" name="phone" value={phone} />
					<Form.Group>
						<Form.Check
							type="checkbox"
							id="showPassword"
							label="Show password"
							onChange={onShowPassword}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Check
							ref={loginRef}
							type="checkbox"
							id="sendPassword"
							name="sendLogin"
							label="Send me my login details"
						/>
					</Form.Group>
					<div className="form-group">
						<div className="row">
							<div className="col-12">
								<button
									type="submit"
									className="btn btn-primary"
								>
									Create
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<p className="text-danger">{errorMsg}</p>
							</div>
						</div>
					</div>
					<div>
						<p>
							If you already have an account you can log in{" "}
							<Link to="/login?status=1" className="blueLink">
								here
							</Link>
						</p>
					</div>
				</Form>
			</div>
			<StripeFooter />
			<AlertModal
				showAlert={showAlert}
				handleClose={closeAlert}
				titleText="Error"
				alertText="Please ensure passwords are the same"
			/>
		</div>
	);
};

export default CreateAccount;
