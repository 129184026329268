import React from "react";
import LogoHeader from "../partials/LogoHeader";
import FooterCategoryControl from "../partials/FooterCategoryControl.js";

const UserWithdrawn = () => {
	return (
		<>
			<div className="content mt-3">
				<LogoHeader />
				<div className="container">
					<h3>You have withdrawn from the trial</h3>

					<p className="blueLinks">
						We're sorry to see you go. We have deleted all data that
						might identify you, including your login details. If you
						are leaving because of something to do with NEON and
						would like to tell us anything about how NEON could be
						improved, please{" "}
						<a href="mailto:neon@nottingham.ac.uk?subject=Neon Study">
							contact us
						</a>
						<br />
						Otherwise please close this window.
					</p>
				</div>
			</div>
			<FooterCategoryControl />
		</>
	);
};

export default UserWithdrawn;
