import React from "react";

const NoBanner = () => {
	return (
		<div className="container">
			<div className="row">
				<div className="col-12 text-center mb-3 titleColour">
					<h1>NEON - Narrative Experiences ONline</h1>
				</div>
			</div>
		</div>
	);
};

export default NoBanner;
