import React from "react";
import { Link } from "react-router-dom";

const FooterCategoryControl = () => {
	return (
		<footer className="footer">
			<div className="container text-center">
				<div className="row mb-3">
					<div className="col-6">
						<Link
							to="/stories/about?status=2"
							className="text-secondary lead"
						>
							About NEON
						</Link>
					</div>
					<div className="col-6">
						<Link
							to="/stories/help?status=2"
							className="text-secondary lead"
						>
							I'm feeling upset
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<p>
							<Link
								to="https://www.google.co.uk"
								className="btn btn-primary"
							>
								Get me out of here
							</Link>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterCategoryControl;
