import React from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";

const InvalidToken = props => (
	<div className="content mt-3">
		<MainHeader />
		<div className="container">
			<div>
				<h3>Invalid email verification link</h3>
			</div>

			<div>
				<p>
					You appear to have clicked on an invalid email verification
					link.
				</p>
				<p className="blueLinks">
					Please start the eligibility process again or contact{" "}
					<a href="mailto:neon@nottingham.ac.uk">
						neon@nottingham.ac.uk
					</a>{" "}
					for further guidance.
				</p>
			</div>
		</div>
		<StripeFooter />
	</div>
);

export default InvalidToken;
