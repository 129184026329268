import React from "react";

const StripeFooter = () => {
	return (
		<div className="container mt-3">
			<div className="row">
				<div className="col-12 mt-3 mb-5">
					<div className="trialprocedures-stripe-bottom" />
				</div>
			</div>
		</div>
	);
};

export default StripeFooter;
