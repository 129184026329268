import React from "react";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";

const VimeoModal = ({ url, showVideo, handleClose }) => {
	return (
		<Modal
			tabIndex="-1"
			role="dialog"
			aria-labelledby="videoLabel"
			aria-hidden="true"
			centered
			dialogClassName="vimeoModal"
			show={showVideo}
			onHide={handleClose}
		>
			<Modal.Body>
				<ReactPlayer
					url={url}
					controls={true}
					width="100%"
					height="600px"
					config={{
						vimeo: {
							playerOptions: {
								autoplay: true
							}
						}
					}}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default VimeoModal;
