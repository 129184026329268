import React from "react";
import useParticipantStore from "../state/participantStore.js";
import MainHeader from "../partials/MainHeader.js";
import FooterStories from "../partials/FooterStories.js";

const UserConsent = () => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);

	return (
		<div className="content mt-5 mb-5">
			<MainHeader />
			<div className="container">
				<div>
					<h3>
						Informed Consent Form - {participantData.trialName}{" "}
						Trial
					</h3>
					<p>Version 4.0 13th October 2020.</p>
				</div>

				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<form
								id="consentForm"
								className="text-justify"
								method="POST"
								action="/validateConsent"
							>
								<ol className="consent-points">
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												I have read and understand the
												online participant information
												sheet (PIS) dated version 4.0.
												13th October 2020.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent1"
												name="consent1"
												checked
												disabled
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												I understand that my
												participation is voluntary, and
												that I can withdraw at any time
												without giving any reason,
												without my care or legal rights
												being affected.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent2"
												name="consent2"
												checked
												disabled
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												I understand that my data will
												be held by DRT Software Ltd.,
												Nottingham University and
												Nottinghamshire Healthcare NHS
												Foundation Trust.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent3"
												name="consent3"
												checked
												disabled
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												I understand that the contact
												details I provide can be used to
												contact me about the study, but
												for no other reasons.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent4"
												name="consent4"
												checked
												disabled
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												I understand that my data will
												be accessed by the study team,
												and may be audited by
												Nottinghamshire Healthcare NHS
												Foundation Trust and the
												Pragmatic Clinical Trials Unit
												at Queen Mary University of
												London.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent5"
												name="consent5"
												checked
												disabled
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												I consent to the use of an
												anonymised version of
												information collected about me
												in this and other research
												studies.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent6"
												name="consent6"
												checked
												disabled
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												I agree to take part in this
												research study.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent7"
												name="consent7"
												checked
												disabled
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-9 col-md-10 col-xl-11">
											<li>
												OPTIONAL: I would like to take
												part in an interview about the
												trial (by telephone or
												video-conference). I understand
												this is optional and does not
												affect my trial participation.
											</li>
										</div>
										<div className="col-3 col-md-2 col-xl-1 text-center">
											<input
												className="form-check-input"
												type="checkbox"
												id="consent8"
												name="consent7"
												checked={
													participantData.interview ===
													"true"
												}
												disabled
											/>
										</div>
									</div>
								</ol>
								<p>
									To create a login for you, we need an email
									address. Please enter this below, and we'll
									send an email with a link to click, to check
									it is working.
								</p>
								<p>
									We don't need to know your name for this
									study. If you don't want us to know your
									name, you could use an email address which
									doesn't include your name.
								</p>
								<div className="form-group row">
									<label className="col-3 col-form-label">
										Email
									</label>
									<div className="col-9 mb-3">
										<input
											type="email"
											className="form-control"
											name="username"
											value={participantData.email}
											disabled=""
										/>
									</div>
								</div>
								<p>
									We can also use text messages to contact you
									about the study. If you would like to
									provide a mobile number, please enter it
									below.
								</p>
								<div className="form-group row">
									<label className="col-3 col-form-label">
										Phone
									</label>
									<div className="col-9">
										<input
											type="tel"
											className="form-control"
											name="phone"
											value={participantData.phone}
											disabled=""
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{!participantData.controlGroup ? <FooterStories /> : null}
		</div>
	);
};

export default UserConsent;
