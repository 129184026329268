import React, { useState } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import useParticipantStore from "../state/participantStore.js";
import postData from "../data/PostData.js";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

const ThanksIntervention = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);

	const [msg, setMessage] = useState("");

	const onSubmitVoucher = async event => {
		event.preventDefault();

		try {
			const response = await postData("/categories/submitVoucher", {});

			// DEBUG
			console.log("Response = ", response);

			const { data } = response;

			setMessage(data.message);
		} catch (error) {
			console.log("Could not submit voucher");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					<div className="row mb-5">
						<div
							className={
								participantData.onNeonTrial ? "col-6" : "col-12"
							}
						>
							<p>
								Thank you for your responses - you have now
								completed the survey.
							</p>
							{participantData.randomised && (
								<React.Fragment>
									{" "}
									<p>
										You have been randomly selected to
										receive immediate access to the NEON
										Intervention. Please use it as little or
										as much as you want.
									</p>
									<p>
										We will ask you to complete our survey
										again in 1 week, 3 months and 1 year.
										Please do not share your login details
										with anyone else.
									</p>
								</React.Fragment>
							)}
							{participantData.transferText && (
								<React.Fragment>
									{" "}
									<p>{participantData.transferText}</p>
								</React.Fragment>
							)}{" "}
						</div>
						{participantData.onNeonTrial && (
							<React.Fragment>
								{" "}
								<div className="col-6">
									<p>
										If you want to claim your £20 voucher
										then click here.
									</p>
								</div>
							</React.Fragment>
						)}{" "}
					</div>

					<div className="row mb-3">
						{participantData.showStories ? (
							<React.Fragment>
								{" "}
								<div className="col-6">
									<Link
										to="/stories/showOptions"
										className="btn btn-primary"
									>
										Next
									</Link>
								</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<div className="col-6">
									<Link
										to="/categories/profile?first=1"
										className="btn btn-primary"
									>
										Next
									</Link>
								</div>
							</React.Fragment>
						)}
						{participantData.onNeonTrial && (
							<React.Fragment>
								{" "}
								<div className="col-6">
									<Form onSubmit={onSubmitVoucher}>
										<button
											type="submit"
											id="voucherRequest"
											className="btn btn-primary"
										>
											Claim voucher
										</button>
									</Form>
								</div>
							</React.Fragment>
						)}{" "}
					</div>
					<span className="text-danger">{msg}</span>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default ThanksIntervention;
