import React from "react";
import axios from "axios";
import { ERROR, ACTIONS } from "../state/Config.js";

const postData = async (url, data) => {
	try {
		const response = await axios.post(url, data);
		return {
			status: response.data.status,
			action: response.data.action,
			data: response.data.data
		};
	} catch (error) {
		console.log("Could not post data", error);
		return {
			status: ERROR.USER_NOT_LOGGED_IN,
			action: ACTIONS.NAVIGATE,
			data: {
				page: "login"
			}
		};
	}
};

export default postData;
