import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { ERROR, ACTIONS } from "../state/Config.js";

const NarrativeUsage = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const onDataChange = event => {
		formDataRef.current = {
			...formDataRef.current,
			[event.target.name]: event.target.value
		};
		// DEBUG
		console.log("Form = ", formDataRef.current);
	};

	const onSubmitNarratives = async event => {
		event.preventDefault();

		try {
			// DEBUG
			console.log("Form = ", formDataRef.current);

			const response = await postData(
				"/categories/narratives",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit narratives data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>
							Your usage of recorded recovery narratives outside
							of NEON
						</h1>
					</div>

					<Form onSubmit={onSubmitNarratives}>
						<Form.Group className="mb-3">
							<Form.Label>
								Not including any narratives provided by NEON,
								how many mental health recovery narratives have
								you read, watched or listened to since
								registering for the {participantData.trial}{" "}
								trial?
							</Form.Label>
							<Form.Select
								onChange={onDataChange}
								name="narratives"
								required
							>
								<option>Select an answer from the list</option>
								<option value="0">0</option>
								<option value="1">1-10</option>
								<option value="2">11 or more</option>
							</Form.Select>
						</Form.Group>
						<input
							type="hidden"
							name="formRequestID"
							value={participantData.formRequestID}
						/>
						<button type="submit" className="btn btn-primary">
							Next
						</button>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default NarrativeUsage;
