import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import useFetch from "../hooks/useFetch.js";
import ContactModal from "../modals/ContactModal.js";
import { Link } from "react-router-dom";

const Landing = props => {
	// Get browser state
	const response = useFetch("/users");
	const [open, setOpen] = useState(false);
	const [trialMode, setTrialMode] = useState("OPEN");

	// Modal state
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	let data;
	if (response) {
		data = response.data;
	}

	return (
		<div className="content mt-3">
			<div className="container mt-3">
				<a
					className="sr-only sr-only-focusable text-primary"
					href="#main"
				>
					Skip to main content
				</a>
				<div className="row d-none d-xl-flex">
					<div className="col-2">
						<a href="/">
							<img
								src="/images/NEON_logo_splash_left.png"
								alt="NEON Trial logo"
							/>
						</a>
					</div>
					<div className="col-5">
						<a href="/">
							<img
								src="/images/NEON_logo_splash_right.png"
								alt="Narrative Experiences ONline"
							/>
						</a>
					</div>
					<div className="col-5 text-end">
						<a
							href="https://www.researchintorecovery.com/research/neon/findings/"
							target="_blank"
							className="btn btn-info ps-2"
						>
							<img
								src="/images/neon_icon.png"
								alt="NEON icon"
								className="me-1"
								width="16"
								height="16"
							/>{" "}
							About NEON
						</a>
						<Button className="btn-dark ms-3" onClick={handleShow}>
							<i className="fas fa-comment fa-fw me-1" /> Contact
						</Button>
						<Link className="btn btn-primary ps-2 ms-3" to="/login">
							<i className="fas fa-sign-in-alt" /> Log in
						</Link>
					</div>
				</div>
				<div className="row d-xl-none">
					<div className="col-7">
						<img
							src="/images/NEON_logo_strapline_mobile.png"
							alt="NEON logo: Narrative Experiences ONline"
							width="200"
							height="54"
						/>
					</div>
					<div className="col-5 text-end">
						<Link className="btn btn-primary ps-3" to="/login">
							<i className="fas fa-sign-in-alt" /> Log in
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-3">
						<div className="trialprocedures-stripe-top" id="main" />
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-0 col-xl-2 d-none d-xl-block">
						<div
							style={{
								height: "100%",
								background:
									"url('/assets/images/kite_desktop_bottom.jpg') repeat-y"
							}}
						>
							<img
								src="/images/kite_desktop_200_faded.jpg"
								width="140"
								alt="Photo of a kite"
							/>
						</div>
					</div>
					<div className="col-12 d-xl-none">
						<img
							src="/images/kite_mobile.jpg"
							width="100%"
							alt="Photo of a kite"
						/>
					</div>
					<div className="col-12 col-xl-5 mt-4">
						<h1>Welcome to the NEON Trials!</h1>
						<p className="blueLinks">
							NEON has collected many different mental health{" "}
							<a
								href="#"
								data-toggle="modal"
								data-target="#stories"
							>
								<strong>recovery stories</strong>
							</a>{" "}
							from around the world.{" "}
						</p>
						<p>
							{" "}
							{trialMode !== "OPEN" &&
								`We want to understand if having access to real-life
							stories like this is helpful for people.{" "}`}
						</p>
						{data &&
							(data.expired ? (
								<React.Fragment>
									{" "}
									<p className="mb-4 blueLinks">
										{trialMode !== "OPEN" &&
											`Our trials are now closed to
										recruitment, and results will be
										reported on our{" "}
										<a
											href="https://www.researchintorecovery.com/research/neon/findings/"
											target="_blank"
										>
											study website
										</a>{" "}
										in 2022.`}
									</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									{" "}
									<p className="mb-4">
										Please get involved in our trials, which
										are currently looking for participants.
									</p>
								</React.Fragment>
							))}
						<hr className="light" />

						{data && !data.expired && (
							<React.Fragment>
								{" "}
								<h2 className="mt-4 mb-2">
									What does taking part involve?
								</h2>
								<p>You will:</p>
								<ul className="blueLinks">
									<li>
										have online access to a large collection
										of real-life{" "}
										<a
											href="#"
											data-toggle="modal"
											data-target="#stories"
										>
											<strong>recovery stories</strong>
										</a>
										, either immediately or after a year
									</li>

									<li>
										provide feedback about the stories.{" "}
									</li>

									<li>
										complete some questionnaires about
										yourself and your wellbeing (receiving
										£20 each time if you're found eligible
										for our largest trial)
									</li>

									<li>
										Everything is done online, and can be
										done from your own home.
									</li>
								</ul>
							</React.Fragment>
						)}
						<div
							className="modal fade"
							id="stories"
							tabIndex="-1"
							role="dialog"
							aria-labelledby="storiesLabel"
							aria-hidden="true"
						>
							<div
								className="modal-dialog modal-dialog-centered"
								role="document"
							>
								<div className="modal-content">
									<div className="modal-header">
										<h5
											className="modal-title"
											id="storiesLabel"
										>
											Recovery stories
										</h5>
										<button
											type="button"
											className="close"
											data-dismiss="modal"
											aria-label="Close"
										>
											<span aria-hidden="true">x</span>
										</button>
									</div>
									<div className="modal-body">
										<p>
											A recovery story is a story told by
											a person about their journey of
											recovery.
										</p>

										<p>
											In NEON we are using recovery
											stories which are first-person lived
											experience accounts, include
											elements of both adversity/struggle
											and of strength/success/survival
											related at least in part to mental
											health problems (i.e. is about
											recovery), and which refer to events
											or actions over a period of time
											(i.e. is a story).
										</p>

										<p>
											We include stories where the
											positive aspects may be a very small
											part to a very large part of the
											story, and which might relate to
											such themes as escape (e.g. from
											damaging services, from a negative
											sense of self), enlightenment (e.g.
											transformation, making new sense of
											experiences), endeavour (e.g. ways
											of coping and managing) or endurance
											(e.g. "I'm still here", "I have
											survived").
										</p>
									</div>
									<div className="modal-footer">
										<button
											type="button"
											className="btn btn-primary"
											data-dismiss="modal"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-4">
							<div className="col-xs-5 col-sm-4 col-xl-6 mt-2">
								<p>Find out more:</p>
							</div>
							<div className="col-xs-7 col-sm-5 col-xl-6">
								<a
									href="https://www.researchintorecovery.com/research/neon/findings/"
									target="_blank"
									className="w-100 btn btn-info btn-block text-start ps-3"
								>
									<img
										src="/images/neon_icon.png"
										alt="NEON icon"
										className="me-2"
										width="16"
										height="16"
									/>{" "}
									About NEON
								</a>
							</div>
							<div className="col-xs-0 col-sm-3 col-xl-0" />
						</div>

						{data && !data.expired && (
							<React.Fragment>
								{" "}
								<hr className="light" />
								<div className="mb-4">
									<h2 className="mb-3 mt-4">
										Are you eligible?
									</h2>
									<p>
										You can take part if you are 18+, live
										in England and have experienced any form
										of mental health problem OR care for
										someone who has experienced mental
										health problems. You should only sign up
										to NEON once, and you must use an email
										address that you own.{" "}
									</p>
								</div>
							</React.Fragment>
						)}
						<hr className="light" />
						{data && !data.expired && (
							<React.Fragment>
								{" "}
								<div className="row mt-4 mb-4">
									<div className="col-xs-5 col-sm-4 col-xl-6 mt-2 mb-2">
										<h2>Interested?</h2>
									</div>
									<div className="col-xs-7 col-sm-5 col-xl-6">
										<Link
											className="w-100 btn btn-light btn-block text-start"
											to="/eligibility"
										>
											<i className="fas fa-check fa-fw ms-1 me-2" />{" "}
											Check eligibility
										</Link>
									</div>
									<div className="col-xs-0 col-sm-3 col-xl-6" />
								</div>
							</React.Fragment>
						)}
						<div className="row mb-4">
							<div className="col-xs-5 col-sm-4 col-xl-6 mt-2 mb-2">
								Already registered?
							</div>
							<div className="col-xs-7 col-sm-5 col-xl-6">
								<Link
									className="w-100 btn btn-primary btn-block text-start"
									to="/login"
								>
									<i className="fas fa-sign-in-alt fa-fw ms-1 me-2" />{" "}
									Log in to NEON
								</Link>
							</div>
							<div className="col-xs-0 col-sm-3 col-xl-0" />
						</div>

						<div className="row mb-4">
							<div className="col-xs-5 col-sm-4 col-xl-6 mt-2 mb-2">
								Questions?
							</div>
							<div className="col-xs-7 col-sm-5 col-xl-6">
								{/* Add button to control modal */}
								<Button
									className="w-100 btn-dark btn-block text-start"
									onClick={handleShow}
								>
									<i className="fas fa-comment fa-fw ms-1 me-2" />{" "}
									Contact Us
								</Button>
							</div>
							<div className="col-xs-0 col-sm-3 col-xl-0" />
						</div>

						<hr className="light" />
					</div>{" "}
					{/* End of main section */}
					{/* Sidebar */}
					<div className="col-12 col-xl-5 mt-4 sidebar-p">
						{/* Quotation */}
						<div className="speech-bubble">
							<div className="speech-bubble-top bg-info text-white ps-4 pe-4 pb-3 pt-3 mb-0">
								<h2>
									<Link to="/testimonials">
										It makes you feel like part of a
										community
									</Link>
								</h2>
							</div>
							<div className="speech-bubble-bottom mt-0 pt-2 blueLinks mb-5">
								-{" "}
								<Link to="/testimonials">
									{trialMode === "OPEN"
										? "quote from a NEON user"
										: "quote from a participant"}
								</Link>
							</div>
						</div>

						{/* Links to video pages */}
						<div className="card video-card-grey mb-4 p-2">
							<div className="card-body">
								<Link
									to="/howto"
									className="btn btn-primary ps-3 mb-3 stretched-link video-link"
								>
									<i className="fas fa-question pe-3" />
									How to use NEON
								</Link>
								<p className="card-text">
									Watch our tutorial videos
								</p>
							</div>
						</div>

						<div className="card video-card-grey mb-4 p-2">
							<div className="card-body">
								<Link
									to="/testimonials"
									className="btn btn-light ps-3 mb-3 stretched-link video-link"
								>
									<i className="fas fa-smile pe-3" />
									What our users think
								</Link>
								<p className="card-text">
									How the study has helped others
								</p>
							</div>
						</div>

						<div className="card video-card-grey mb-4 p-2">
							<div className="card-body">
								<Link
									to="/team"
									className="btn btn-info ps-3 mb-3 stretched-link video-link"
								>
									<i className="fas fa-user-friends pe-3" />
									Meet the NEON team
								</Link>
								<p className="card-text">
									Find out about who is behind NEON
								</p>
							</div>
						</div>
					</div>{" "}
					{/* End of sidebar */}
				</div>
				<div className="card mt-5 mb-5">
					<div
						className="card-header bg-secondary text-white collapsed"
						onClick={() => setOpen(!open)}
					>
						<div className="row">
							<div className="col-11 text-center text-md-start">
								<h2>
									<i
										className="fas fa-tasks ms-1 me-3 text-start"
										aria-hidden="true"
									/>
									<strong>Trial approvals</strong>
								</h2>
							</div>
							<div className="col-1 text-end d-none d-md-block">
								<i
									className="fas fa-chevron-down"
									aria-hidden="true"
								/>
							</div>
						</div>
					</div>

					<Collapse in={open}>
						<div>
							<div className="card-body">
								<p className="blueLinks">
									The NEON trials are led by Professor Mike
									Slade at the{" "}
									<a
										href="https://www.nottingham.ac.uk"
										target="_blank"
									>
										University of Nottingham
									</a>
									, and have been approved by the{" "}
									<a
										href="https://www.hra.nhs.uk/"
										target="_blank"
									>
										Health Research Authority
									</a>{" "}
									and by the{" "}
									<a
										href="mailto:nrescommittee.eastmidlands-leicestercentral@nhs.net"
										target="_blank"
									>
										Leicester Central Research Ethics
										Committee
									</a>
									. The reference for the study is 249015.
								</p>
								<p className="blueLinks">
									The NEON study is funded by the{" "}
									<a
										href="https://www.nihr.ac.uk/"
										target="_blank"
									>
										National Institute for Health Research
									</a>{" "}
									and oversight is from{" "}
									<a
										href="https://www.nottinghamshirehealthcare.nhs.uk/"
										target="_blank"
									>
										Nottinghamshire Healthcare NHS
										Foundation Trust
									</a>
									.
								</p>
							</div>
						</div>
					</Collapse>
				</div>

				{/* Add contact modal */}
				<ContactModal showContact={show} handleClose={handleClose} />
				<input
					type="hidden"
					id="unsupportedBrowser"
					value={props.unsupportedBrowser}
				/>
			</div>{" "}
			{/* main col */}
			<div className="container">
				<div className="row">
					<div className="col-12 mb-5">
						<div className="trialprocedures-stripe-bottom" />
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-xs-0 col-xl-9 mb-5 d-none d-xl-flex">
						<a href="https://www.nottingham.ac.uk/" target="_blank">
							<img
								src="/images/logo_uon.png"
								className="me-4"
								height="65"
								alt="University of Nottingham logo"
							/>
						</a>
						<a
							href="https://www.institutemh.org.uk/"
							target="_blank"
						>
							<img
								src="/images/logo_imh2.png"
								className="ms-2 me-4 pe-1"
								height="65"
								alt="Institute of Mental Health Nottingham logo"
							/>
						</a>
						<a href="https://www.nihr.ac.uk/" target="_blank">
							<img
								src="/images/logo_nihr.png"
								height="65"
								className="ms-2"
								alt="Funded by NIHR (The National Institute for Health Research)"
							/>
						</a>
					</div>
					<div className="col-xs-12 col-xl-3 mb-5 text-end">
						<a
							href="https://www.nottinghamshirehealthcare.nhs.uk/"
							target="_blank"
						>
							<img
								src="/images/NHS_Logo.png"
								height="65"
								alt="NHS logo: Nottinghamshire Healthcare NHS Foundation Trust"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Landing;
