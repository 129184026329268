import React, { useState } from "react";
import LogoHeader from "../partials/LogoHeader";
import Accordion from "react-bootstrap/Accordion";
import useParticipantStore from "../state/participantStore";
import Form from "react-bootstrap/Form";
import postData from "../data/PostData.js";
import { ERROR } from "../state/Config.js";
import { Link } from "react-router-dom";

const ControlHome = props => {
	// Select profile elements
	const [currentKey, setCurrentKey] = useState("");

	const onProfileSelect = event => {
		setCurrentKey(event);
	};

	// Participant data
	const participantData = useParticipantStore(state => state.participantData);

	// Form data
	const [formData, setFormData] = useState({
		phone: participantData.profileData.Phone || ""
	});

	const onChangePhone = event => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const onSubmitControlProfile = async event => {
		event.preventDefault();

		try {
			const response = await postData("/users/personalControl", formData);

			const { status } = response;

			if (status === ERROR.NO_ERROR) {
				setCurrentKey("");
			}
		} catch (error) {
			console.log("Could not submit profile data");
		}
	};

	return (
		<div className="content intervention">
			<LogoHeader />
			<div className="container mt-5 min-height-full">
				<div>
					<h1>NEON for control group (delayed start)</h1>
				</div>

				<div>
					<p>
						You'll receive access to NEON one year after you signed
						up. Until then, you're a valuable member of our trial,
						and we'll contact you one week, 12 weeks and one year
						after you signed up to ask you to fill out some
						questionnaires.
					</p>
					<p>
						You can always log in to access the following
						information.
					</p>
				</div>

				<Accordion
					activeKey={currentKey}
					onSelect={onProfileSelect}
					flush
					className="mb-5"
					id="upsetsection"
				>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							<h2>
								<i
									className="fas fa-user-alt fa-fw ms-1 me-3 text-start"
									aria-hidden="true"
								/>
								<strong>I'm feeling upset</strong>
							</h2>
						</Accordion.Header>
						<Accordion.Body>
							<div className="card-body blueLinks">
								<p>
									<strong>
										Who can I talk to immediately?
									</strong>
								</p>
								<p>
									<span>Samaritans</span>
									<a
										href="https://www.samaritans.org"
										target="_blank"
									>
										{" "}
										www.samaritans.org
									</a>
									provide 24 hour telephone support on 116
									123, which is free to call. You can also
									email, write a letter or talk to someone in
									person.
								</p>
								<p>
									<span>The Mind Infoline</span>
									<a
										href="https://www.mind.org.uk/information-support/helplines/"
										target="_blank"
									>
										{" "}
										www.mind.org.uk/information-support/helplines/
									</a>
									might help you find support in your local
									area. You can call 0300 123 3393 (9am-6pm
									Monday to Friday) or text 86463
								</p>
								<p>
									<span>The Mix</span>
									<a
										href="http://www.themix.org.uk/get-support"
										target="_blank"
									>
										{" "}
										www.themix.org.uk/get-support
									</a>
									provides information and support if you are
									25 or under. You can telephone 0808 808 4994
									for free from 11am-11pm or text 'THEMIX' to
									85258 if you are experiencing a crisis.
								</p>
								<p>
									<span>Elefriends</span>
									<a
										href="http://elefriends.org.uk/"
										target="_blank"
									>
										{" "}
										http://elefriends.org.uk
									</a>
									is an online community managed by Mind,
									which might help you find others with
									similar experiences.
								</p>
								<p>
									<span>Big White Wall</span>
									<a
										href="https://www.bigwhitewall.com/"
										target="_blank"
									>
										{" "}
										https://www.bigwhitewall.com
									</a>
									is an online community providing support to
									people who are 16 or older and who have a UK
									postcode.
								</p>
								<p>
									<span>Saneline</span>
									<a
										href="http://www.rethink.org/about-us/our-mental-health-advice"
										target="_blank"
									>
										{" "}
										www.rethink.org/about-us/our-mental-health-advice
									</a>
									is a national helpline providing emotional
									support and information “out of hours”. You
									can ring 0300 304 7000 for free from
									4:30pm-10:30pm.
								</p>
								<p>
									<span>
										The Rethink Mental Illness Advice Line
									</span>
									<a
										href="http://www.rethink.org/about-us/our-mental-health-advice"
										target="_blank"
									>
										{" "}
										www.rethink.org/about-us/our-mental-health-advice
									</a>
									provides advice and information. You can
									call 0300 5000 927 from 9.30am - 4pm Monday
									to Friday.
								</p>
								<p>
									<strong>
										How can I talk to a healthcare
										professional?
									</strong>
								</p>
								<p>
									Could you get an appointment with your GP?
									Some offer emergency appointments.
								</p>
								<p>
									Does your local healthcare trust run a
									crisis line or allow self-referrals?
								</p>
								<p>
									This is the NHS urgent and emergency care
									hotline (dial 111).
								</p>
							</div>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							<h2>
								<i
									className="fas fa-address-card fa-fw ms-1 me-3 text-end"
									aria-hidden="true"
								/>
								<strong>
									I'd like to update my contact details
								</strong>
							</h2>
						</Accordion.Header>
						<Accordion.Body>
							<Form onSubmit={onSubmitControlProfile}>
								<div className="form-group row">
									<label className="col-3 col-form-label">
										<h2>Email</h2>
									</label>
									<div className="col-9">
										<input
											type="email"
											className="form-control"
											name="username"
											value={
												participantData.profileData
													.Email
											}
											readOnly
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-3 col-form-label">
										<h2>Mobile</h2>
									</label>
									<div className="col-9">
										<input
											type="tel"
											className="form-control"
											name="phone"
											onChange={onChangePhone}
											value={formData.phone}
										/>
									</div>
								</div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									<i
										className="fas fa-sign-in-alt ms-1 me-3"
										aria-hidden="true"
									/>{" "}
									Update
								</button>
							</Form>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="2">
						<Accordion.Header>
							<h2>
								<i
									className="fas fa-flag fa-fw ms-1 me-3 text-start"
									aria-hidden="true"
								/>
								<strong>I'd like to report an incident</strong>
							</h2>
						</Accordion.Header>
						<Accordion.Body>
							<Link
								to="/safe/safety?control=2"
								className="btn btn-warning"
							>
								<i
									className="fas fa-flag fa-fw ms-1 me-3"
									aria-hidden="true"
								/>
								Report an incident
							</Link>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header>
							<h2>
								<i
									className="fas fa-tasks fa-fw ms-1 me-3 text-start"
									aria-hidden="true"
								/>
								<strong>
									I'd like to view my consent form or withdraw
								</strong>
							</h2>
						</Accordion.Header>
						<Accordion.Body>
							<Link
								to="/stories/showConsent?status=2"
								className="btn btn-secondary"
							>
								<i
									className="fas fa-tasks"
									aria-hidden="true"
								/>{" "}
								View consent form or withdraw from study
							</Link>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</div>
	);
};

export default ControlHome;
