import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LogoHeader from "../partials/LogoHeader";
import MenuHeader from "../partials/MenuHeader";
import FooterStories from "../partials/FooterStories";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import postData from "../data/PostData.js";
import { ERROR, ACTIONS } from "../state/Config.js";
import Collapse from "react-bootstrap/Collapse";

const Withdraw = props => {
	// Asked to discuss
	const [discussed, setDiscussed] = useState(false);

	// Get url parameters
	const location = useLocation();
	const controlGroup = new URLSearchParams(location.search).get("status");

	const navigate = useNavigate();

	const onSubmitDiscuss = async event => {
		event.preventDefault();

		try {
			const response = await postData("/categories/discuss", {});

			const { status } = response;

			if (status === ERROR.NO_ERROR) {
				setDiscussed(true);
			}
		} catch (error) {
			console.log("Could not discuss withdrawal");
		}
	};

	const onSubmitWithdraw = async event => {
		event.preventDefault();

		try {
			const response = await postData("/categories/withdraw", {});

			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						navigate(`/stories/${data.page}`);
						break;

					default:
						break;
				}
			}
		} catch (error) {
			console.log("Could not process withdraw request");
		}
	};

	return (
		<div className="content intervention">
			{controlGroup ? <LogoHeader /> : <MenuHeader />}
			<div className="container">
				<h3 className="mb-3">Withdraw from trial</h3>

				<div className="mb-5">
					<p>
						You have asked to withdraw from this study. If you
						confirm this decision then all data that might identify
						you will be deleted and only anonymised data will be
						kept. You will no longer have access to the NEON
						intervention.
					</p>
				</div>

				<div className="mb-5">
					<p>
						If you are thinking about withdrawing and would like to
						discuss this with a NEON researcher please click here to
						send an email to request a discussion.
					</p>
					<Form onSubmit={onSubmitDiscuss}>
						<Button type={!discussed ? "submit" : "button"}>
							Discuss
						</Button>
						<Collapse in={discussed}>
							<div className="card card-body">
								Thank you. We will be in touch with you as soon
								as possible using the contact information listed
								in the About Me page. If needed, please update
								your preferences now so we have the correct
								contact information for you.
							</div>
						</Collapse>
					</Form>
				</div>

				<div className="mb-5 blueLinks">
					<p>
						If you are sure you want to withdraw please confirm your
						decision by clicking here. This will immediately delete
						your NEON account. It will not be possible to recreate
						it and you will no longer be a part of our trial.
					</p>
					<Form onSubmit={onSubmitWithdraw}>
						<button
							id="withdrawUser"
							type="submit"
							className="btn btn-primary"
						>
							Withdraw
						</button>
					</Form>
				</div>

				<div id="back" className="mb-5">
					<p>If you do not want to withdraw, please click here</p>
					<div>
						{controlGroup ? (
							<React.Fragment>
								{" "}
								<Link
									to="/stories/controlHome"
									className="btn btn-primary"
								>
									Back
								</Link>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<Link
									to="/stories/showStories"
									className="btn btn-primary"
								>
									Back
								</Link>
							</React.Fragment>
						)}{" "}
					</div>
				</div>

				<span className="text-danger">{props.msg}</span>
			</div>
			{!controlGroup ? <FooterStories /> : null}
		</div>
	);
};

export default Withdraw;
