import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { ERROR, ACTIONS } from "../state/Config.js";

const Abridged = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const onDataChange = event => {
		formDataRef.current = {
			...formDataRef.current,
			[event.target.name]: event.target.value
		};
		// DEBUG
		console.log("Form = ", formDataRef.current);
	};

	const onSubmitAbridged = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"/categories/abridged",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit demographic data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>
					<div className="ml-4 pl-3">
						<h1>Your use of health services</h1>
					</div>
					<div className="ml-4 pl-3">
						<p>
							This information will help us to calculate how
							cost-effective NEON is.
						</p>
					</div>

					<Form
						onSubmit={onSubmitAbridged}
						id="abridgedRatings"
						className="mb-5"
					>
						<ol className="economics">
							<hr className="mt-5 mb-4" />
							<p>
								In the last <strong>{props.months}</strong>{" "}
								months how many nights have you stayed in
								hospital because of…
							</p>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>
										…mental health problems?
									</Form.Label>
									<Form.Control
										onChange={onDataChange}
										id="problems"
										type="number"
										name="problems"
										min="0"
										max={props.maxNights}
										required
									/>
								</Form.Group>
							</li>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>
										…any other reason (e.g. physical health
										problems)?
									</Form.Label>
									<Form.Control
										onChange={onDataChange}
										id="other"
										type="number"
										name="other"
										min="0"
										max={props.maxNights}
										required
									/>
								</Form.Group>
							</li>
							{Boolean(props.showSafety) && (
								<React.Fragment>
									{" "}
									<div
										id="contributedHospital"
										className="d-none"
									>
										<li>
											<Form.Group>
												<Form.Label>
													Did taking part in this
													trial contribute to you
													going into hospital or
													staying longer than
													expected?
												</Form.Label>
												<Form.Select
													onChange={onDataChange}
													name="hospital"
													id="hospital"
												>
													<option>
														Select an answer from
														the list
													</option>
													<option value="1">
														Yes
													</option>
													<option value="2">
														Unsure
													</option>
													<option value="3">
														No
													</option>
												</Form.Select>
											</Form.Group>
										</li>
									</div>
									<div
										id="contributedAnswer"
										className="d-none"
									>
										<li>
											<Form.Group>
												<Form.Label>
													How might the trial have
													contributed?
												</Form.Label>
												<Form.Control
													onChange={onDataChange}
													id="contributed"
													type="text"
													name="contributed"
												/>
											</Form.Group>
										</li>
									</div>
									<div
										id="hospitalDetails"
										className="d-none"
									>
										<li>
											<Form.Group>
												<Form.Label>
													When did you go into
													hospital?
												</Form.Label>
												<Form.Control
													onChange={onDataChange}
													id="hospitalDate"
													type="date"
													name="hospitalDate"
												/>
											</Form.Group>
										</li>
										<li>
											<Form.Group>
												<Form.Label>
													Which hospital were you
													admitted to?
												</Form.Label>
												<Form.Control
													onChange={onDataChange}
													id="hospitalName"
													type="text"
													name="hospitalName"
												/>
											</Form.Group>
										</li>
									</div>
								</React.Fragment>
							)}{" "}
							<hr className="mt-4 mb-4" />
							<p>
								In the last{" "}
								<strong>{participantData.months}</strong> months
								how many visits have you made to…
							</p>
							<li>
								<Form.Group>
									<Form.Label>
										Accident and Emergency (A&E) or Minor
										Injuries Unit?
									</Form.Label>
									<Form.Control
										onChange={onDataChange}
										id="accident"
										type="number"
										name="accident"
										min="0"
										max={props.maxNights}
										required
									/>
								</Form.Group>
							</li>
							{participantData.showSafety && (
								<React.Fragment>
									{" "}
									<div
										id="contributedAccident"
										className="d-none"
									>
										<li>
											<Form.Group>
												<Form.Label>
													Did taking part in this
													trial contribute to you
													going into (A&E) or Minor
													Injuries Unit?
												</Form.Label>
												<Form.Select
													onChange={onDataChange}
													name="emergency"
													id="emergency"
												>
													<option>
														Select an answer from
														the list
													</option>
													<option value="1">
														Yes
													</option>
													<option value="2">
														Unsure
													</option>
													<option value="3">
														No
													</option>
												</Form.Select>
											</Form.Group>
										</li>
									</div>
									<div
										id="contributedDetails"
										className="d-none"
									>
										<li>
											<Form.Group>
												<Form.Label>
													How might the trial have
													contributed?
												</Form.Label>
												<Form.Control
													onChange={onDataChange}
													id="trialContributedAccident"
													type="text"
													name="trialContributedAccident"
												/>
											</Form.Group>
										</li>
									</div>
									<div
										id="accidentDetails"
										className="d-none"
									>
										<li>
											<Form.Group>
												<Form.Label>
													When did you go to the (A&E)
													or Minor Injuries Unit?
												</Form.Label>
												<Form.Control
													onChange={onDataChange}
													id="accidentDate"
													type="date"
													name="accidentDate"
												/>
											</Form.Group>
										</li>
										<li>
											<Form.Group>
												<Form.Label>
													Which (A&E) or Minor
													Injuries Unit did you go to?
												</Form.Label>
												<Form.Control
													onChange={onDataChange}
													id="accidentName"
													type="text"
													name="accidentName"
												/>
											</Form.Group>
										</li>
									</div>
								</React.Fragment>
							)}{" "}
							<hr className="mt-4 mb-4" />
							<p>
								In the last{" "}
								<strong>{participantData.months}</strong> months
								how many visits have you made or received
								from...
							</p>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>
										General practitioner / family doctor?
									</Form.Label>
									<Form.Control
										onChange={onDataChange}
										type="number"
										name="doctor"
										min="0"
										max={participantData.maxNights}
										required
									/>
								</Form.Group>
							</li>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>
										Community nurse, occupational therapist,
										primary care counsellor, IAPT therapist
										OR family therapist?
									</Form.Label>
									<Form.Control
										onChange={onDataChange}
										type="number"
										name="nurse"
										min="0"
										max={participantData.maxNights}
										required
									/>
								</Form.Group>
							</li>
							<li>
								<Form.Group className="mb-3">
									<Form.Label>Psychologist?</Form.Label>
									<Form.Control
										onChange={onDataChange}
										type="number"
										name="psychologist"
										min="0"
										max={participantData.maxNights}
										required
									/>
								</Form.Group>
							</li>
							<li>
								<Form.Group>
									<Form.Label>Psychiatrist?</Form.Label>
									<Form.Control
										onChange={onDataChange}
										type="number"
										name="psychiatrist"
										min="0"
										max={participantData.maxNights}
										required
									/>
								</Form.Group>
							</li>
							<hr className="mt-4 mb-4" />
							<p>
								In the last{" "}
								<strong>{participantData.months}</strong> months
								how many visits have you made to...
							</p>
							<li>
								<Form.Group>
									<Form.Label>A day care service?</Form.Label>
									<Form.Control
										onChange={onDataChange}
										type="number"
										name="day"
										min="0"
										max={participantData.maxNights}
										required
									/>
								</Form.Group>
							</li>
							<hr className="mt-4 mb-4" />
							<li>
								<Form.Group className="mb-3">
									<Form.Label>
										How many weeks have you worked in the
										last {participantData.months} months?
									</Form.Label>
									<Form.Control
										onChange={onDataChange}
										type="number"
										name="weeks"
										min="0"
										max={participantData.maxWeeks}
										required
									/>
								</Form.Group>
							</li>
							<li>
								<Form.Group>
									<Form.Label>
										Which of the following best describes
										your current employment situation?
									</Form.Label>
									<Form.Select
										id="situation"
										name="employment"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">
											In paid employment
										</option>
										<option value="2">
											Unemployed and looking for work
										</option>
										<option value="3">
											At home and not looking for work
											(e.g. housewife/husband)
										</option>
										<option value="4">
											Unable to work due to illness
										</option>
										<option value="5">
											Unable to work for another reason
											(e.g. caring for someone)
										</option>
										<option value="6">Retired</option>
									</Form.Select>
								</Form.Group>
							</li>
							<div id="paidEmployment" className="d-none">
								<li>
									<Form.Group>
										<Form.Label>
											How many hours per week do you
											typically work in all your paid
											jobs?{" "}
										</Form.Label>
										<Form.Control
											onChange={onDataChange}
											id="weeklyHours"
											type="number"
											name="weeklyHours"
											min="0"
											max="168"
										/>
									</Form.Group>
								</li>
								<li>
									<Form.Group>
										<Form.Label>
											What is your main job?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											id="mainJob"
											name="mainJob"
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">
												Manager/administrator
											</option>
											<option value="2">
												Professional (e.g. health,
												teaching, legal)
											</option>
											<option value="3">
												Associate professional (e.g.
												technical, nursing)
											</option>
											<option value="4">
												Clerical worker/secretary
											</option>
											<option value="5">
												Skilled labourer (e.g. building,
												electrical etc)
											</option>
											<option value="6">
												Services/sales (e.g. retail)
											</option>
											<option value="7">
												Factory worker
											</option>
											<option value="8">Other</option>
										</Form.Select>
									</Form.Group>
								</li>
							</div>
							<div id="lastEmployed" className="d-none">
								<li>
									<Form.Group>
										<Form.Label>
											When were you last employed?
										</Form.Label>
										<Form.Control
											onChange={onDataChange}
											id="employed"
											type="date"
											name="lastEmployed"
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>
											What was your most recent job?
										</Form.Label>
										<Form.Select
											onChange={onDataChange}
											id="recentJob"
											name="recentJob"
										>
											<option>
												Select an answer from the list
											</option>
											<option value="1">
												Manager/administrator
											</option>
											<option value="2">
												Professional (e.g. health,
												teaching, legal)
											</option>
											<option value="3">
												Associate professional (e.g.
												technical, nursing)
											</option>
											<option value="4">
												Clerical worker/secretary
											</option>
											<option value="5">
												Skilled labourer (e.g. building,
												electrical etc)
											</option>
											<option value="6">
												Services/sales (e.g. retail)
											</option>
											<option value="7">
												Factory worker
											</option>
											<option value="8">Other</option>
										</Form.Select>
									</Form.Group>
								</li>
							</div>
							<div className="mt-5">
								<button
									type="submit"
									className="btn btn-primary ml-1"
								>
									<i className="fas fa-check" /> Submit
								</button>
							</div>
						</ol>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Abridged;
