import React from "react";
import { Routes, Route } from "react-router-dom";
import SelectOption from "../pages/SelectOption.js";
import Missing from "../pages/Missing.js";
import CheckStory from "../pages/CheckStory.js";
import DisplaySingleStory from "../pages/DisplaySingleStory.js";
import FullWelcome from "../pages/FullWelcome.js";
import Profile from "../pages/Profile.js";
import Browse from "../pages/Browse.js";
import History from "../pages/History.js";
import Badges from "../pages/Badges.js";
import About from "../pages/About.js";
import Help from "../pages/Help.js";
import ControlHome from "../pages/ControlHome.js";
import ShowConsent from "../pages/ShowConsent.js";
import UserConsent from "../pages/UserConsent.js";
import Withdraw from "../pages/Withdraw.js";
import UserWithdrawn from "../pages/UserWithdrawn.js";
import CategoryStories from "../pages/CategoryStories.js";

const Stories = () => {
	return (
		<Routes>
			<Route path="/showOptions" element={<SelectOption />} />
			<Route path="/checkStory" element={<CheckStory />} />
			<Route path="/showStory" element={<DisplaySingleStory />} />
			<Route path="/fullWelcome" element={<FullWelcome />} />
			<Route path="/profile" element={<Profile />} />
			<Route path="/browse" element={<Browse />} />
			<Route path="/showHistory" element={<History />} />
			<Route path="/badges" element={<Badges />} />
			<Route path="/about" element={<About />} />
			<Route path="/help" element={<Help />} />
			<Route path="/controlHome" element={<ControlHome />} />
			<Route path="/showConsent" element={<ShowConsent />} />
			<Route path="/userConsent" element={<UserConsent />} />
			<Route path="/withdraw" element={<Withdraw />} />
			<Route path="/userWithDrawn" element={<UserWithdrawn />} />
			<Route path="/categoryStories" element={<CategoryStories />} />
			<Route path="/*" element={<Missing />} />
		</Routes>
	);
};

export default Stories;
