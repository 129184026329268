import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LogoHeader from "../partials/LogoHeader";
import MenuHeader from "../partials/MenuHeader";
import FooterStories from "../partials/FooterStories";
import Button from "react-bootstrap/Button";
import { ERROR, ACTIONS } from "../state/Config.js";
import getData from "../data/getData.js";
import useParticipantStore from "../state/participantStore.js";

const ShowConsent = () => {
	const navigate = useNavigate();
	// Get url parameters
	const location = useLocation();
	const controlGroup = new URLSearchParams(location.search).get("status");
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	const getConsentData = async () => {
		try {
			const queryString = controlGroup
				? "/categories/userConsent?status=2"
				: "/categories/userConsent";

			const response = await getData(queryString, {});

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/stories/${data.page}`);
							break;

						default:
							break;
					}
					break;

				default:
					break;
			}
		} catch (error) {
			console.log("Could not get user consent data");
		}
	};

	return (
		<div className="content intervention">
			{controlGroup ? <LogoHeader /> : <MenuHeader />}
			<div className="container">
				<div className="mb-5">
					<h3>Consent and Withdrawal</h3>
				</div>

				<div className="mb-5">
					{controlGroup ? (
						<p>Please select one of the following options - </p>
					) : (
						<p>
							Please select one of the following options (or click
							Home to go back) -{" "}
						</p>
					)}
				</div>

				<div className="row mb-5">
					<div className="col-6">
						<Button onClick={getConsentData}>
							Look at consent form
						</Button>
					</div>
					<div className="col-6">
						{controlGroup ? (
							<Link
								to="/stories/withdraw?status=2&control=2"
								className="btn btn-primary"
							>
								I wish to withdraw from this study
							</Link>
						) : (
							<Link
								to="/stories/withdraw"
								class="btn btn-primary"
							>
								I wish to withdraw from this study
							</Link>
						)}
					</div>
				</div>
			</div>
			{!controlGroup ? <FooterStories /> : null}
		</div>
	);
};

export default ShowConsent;
