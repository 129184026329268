import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch.js";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import { useLocation } from "react-router-dom";
import SingleStory from "./SingleStory.js";
import StoryWarning from "./StoryWarning.js";
import { Link } from "react-router-dom";

const CheckStory = () => {
	// Get url parameters
	const location = useLocation();
	const story = new URLSearchParams(location.search).get("story");
	const route = new URLSearchParams(location.search).get("route");
	const categories = new URLSearchParams(location.search).get("categories");
	const first = new URLSearchParams(location.search).get("first");

	let queryString = route !== null ? `&route=${route}` : "";
	queryString += categories !== null ? `&categories=${categories}` : "";
	queryString += first !== null ? `&first=${first}` : "";

	// Get user state
	let storyState = useFetch(
		`/categories/checkStory?story=${story}${queryString}`
	);

	// DEBUG
	console.log("Story state = ", storyState);
	return (
		<div className="content intervention">
			<MenuHeader />
			{storyState && storyState.warningTriggers ? (
				<StoryWarning state={storyState} />
			) : (
				storyState && (
					<SingleStory state={storyState} triggers={false} />
				)
			)}
			<FooterStories />
		</div>
	);
};

export default CheckStory;
