import React, { useState } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import postData from "../data/PostData.js";
import { ERROR, ACTIONS } from "../state/Config.js";
import useParticipantStore from "../state/participantStore.js";

const EligibilityQ3 = props => {
	// Get participant id
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		currentAge: "",
		readEnglish: "",
		partID: participantData.partID
	});
	const onChangeEligibility = event => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const onSubmitEligibility = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"users/validateEligibilityQ3",
				formData
			);

			const { status, action, data } = response;

			if (status === ERROR.NO_ERROR) {
				switch (action) {
					case ACTIONS.NAVIGATE:
						setParticipantData(data);
						navigate(`/${data.page}`);
						break;

					default:
						break;
				}
			}
		} catch (error) {
			console.log("Could not submit form data", error);
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container mb-3">
					<div>
						<h3>NEON study eligibility</h3>
					</div>
					<div className="mt-4 blueLinks">
						<p>
							These questions allow us to identify which trial you
							are eligible for. Please think about your responses
							carefully. If you don't want to take part in a
							clinical trial, but would instead like to learn more
							about the NEON study, please visit the study website{" "}
							<a
								href="http://www.researchintorecovery.com/NEON"
								target="_blank"
							>
								http://www.researchintorecovery.com/NEON
							</a>
							. If you have already created an account you can
							login at{" "}
							<a href="https://recoverystories.uk/login">
								https://recoverystories.uk/login
							</a>{" "}
							and should not complete these questions again.
						</p>
					</div>
					{/* PS added hr */}
					<hr className="mt-4 mb-4" />

					<Form
						onSubmit={onSubmitEligibility}
						id="Q3"
						action="/validateEligibilityQ3"
					>
						<Form.Group className="mb-3">
							<Form.Label>
								Are you 18 or over today, and normally resident
								in England?
							</Form.Label>
							<Form.Select
								id="currentAge"
								name="currentAge"
								onChange={onChangeEligibility}
								required
							>
								<option value="">
									Select an answer from the list
								</option>
								<option>Yes</option>
								<option>No</option>
							</Form.Select>
						</Form.Group>
						<Form.Group id="understand" className="mb-3">
							<Form.Label>
								Can you understand written and spoken English?
							</Form.Label>
							<Form.Select
								id="readEnglish"
								name="readEnglish"
								onChange={onChangeEligibility}
								required
							>
								<option value="">
									Select an answer from the list
								</option>
								<option>Yes</option>
								<option>No</option>
							</Form.Select>
						</Form.Group>
						<input
							type="hidden"
							name="partID"
							value={props.partID}
						/>
						<button type="submit" className="btn btn-primary">
							Next
						</button>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default EligibilityQ3;
