import React, { useState, useRef, useEffect } from "react";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import postData from "../data/PostData.js";
import { ERROR, ACTIONS } from "../state/Config.js";
import axios from "axios";
import useNeonStore from "../state/store.js";
import useParticipantStore from "../state/participantStore.js";
import { Link } from "react-router-dom";

const Profile = props => {
	// Local state
	const [showAlert, setShowAlert] = useState(false);
	const [currentKey, setCurrentKey] = useState("");

	// Participant data
	const participantData = useParticipantStore(state => state.participantData);

	const dismissAlert = () => {
		setShowAlert(false);
	};

	const onProfileSelect = event => {
		setCurrentKey(event);
	};

	// State updates from store
	const profileData = useNeonStore(state => state.profileData);
	const setProfileData = useNeonStore(state => state.setProfileData);
	const updateProfileData = useNeonStore(state => state.updateProfileData);

	const disabilityData = useNeonStore(state => state.disabilityData);
	const setDisabilityData = useNeonStore(state => state.setDisabilityData);
	const resetDisabilityData = useNeonStore(
		state => state.resetDisabilityData
	);
	const updateDisabilityData = useNeonStore(
		state => state.updateDisabilityData
	);

	const diagnosisData = useNeonStore(state => state.diagnosisData);
	const setDiagnosisData = useNeonStore(state => state.setDiagnosisData);
	const updateDiagnosisData = useNeonStore(
		state => state.updateDiagnosisData
	);
	const resetDiagnosisData = useNeonStore(state => state.resetDiagnosisData);

	const preferenceData = useNeonStore(state => state.preferenceData);
	const setPreferenceData = useNeonStore(state => state.setPreferenceData);
	const updatePreferenceData = useNeonStore(
		state => state.updatePreferenceData
	);

	const triggerData = useNeonStore(state => state.triggerData);
	const setTriggerData = useNeonStore(state => state.setTriggerData);
	const updateTriggerData = useNeonStore(state => state.updateTriggerData);

	const blockedData = useNeonStore(state => state.blockedData);
	const setBlockedData = useNeonStore(state => state.setBlockedData);
	const blockedStories = useNeonStore(state => state.blockedStories);
	const setBlockedStories = useNeonStore(state => state.setBlockedStories);
	const updateBlockedData = useNeonStore(state => state.updateBlockedData);

	const managementData = useNeonStore(state => state.managementData);
	const setManagementData = useNeonStore(state => state.setManagementData);
	const updateManagementData = useNeonStore(
		state => state.updateManagementData
	);

	const contactInfo = useNeonStore(state => state.contactInfo);
	const setContactInfo = useNeonStore(state => state.setContactInfo);
	const updateContactInfo = useNeonStore(state => state.updateContactInfo);

	const badgesData = useNeonStore(state => state.badgesData);
	const setBadgesData = useNeonStore(state => state.setBadgesData);
	const updateBadgesData = useNeonStore(state => state.updateBadgesData);

	// Fetch data on entry
	useEffect(() => {
		const fetchData = async () => {
			const response = await axios("/categories/profile");
			const userProfile = response.data;
			// DEBUG
			console.log("User profile = ", userProfile);

			// About me
			setProfileData(userProfile.profile);

			// Disability
			setDisabilityData(userProfile.selectedDisabilities);

			// Diagnosis
			setDiagnosisData(userProfile.selectedDiagnoses);

			// Preferences
			setPreferenceData(userProfile.selectedTypes);

			// Story issues
			setTriggerData(userProfile.selectedTriggers);

			// Blocked formats
			setBlockedData(userProfile.blockedFormats);

			// Blocked stories
			setBlockedStories(userProfile.numBlockedStories);

			// Management strategies
			setManagementData(userProfile.strategies);

			// Contact info
			setContactInfo(userProfile.userProfile);

			// Badges
			setBadgesData(userProfile.badgesEnabled);
		};

		fetchData();
	}, []);

	// Check blocked data
	useEffect(() => {
		// Cannot block everything
		const allBlocked = Object.values(blockedData);

		if (allBlocked.every(element => element === true)) {
			setShowAlert(true);
		}
	}, [blockedData]);

	const onSubmitProfileData = async event => {
		event.preventDefault();
		// Combine all form data
		const formData = {
			...profileData,
			...disabilityData,
			...diagnosisData,
			...preferenceData
		};
		try {
			// Make sure that accordion closes instantly
			setCurrentKey("");
			const response = await axios.post(
				"/categories/updateProfile",
				formData
			);
			if (response.data.status === ERROR.NO_ERROR) {
				// DEBUG
				console.log("Profile data updated OK...");
			}
		} catch (error) {
			console.log("Could not submit profile data");
		}
	};

	// Update data from each form field
	const onChangeProfileData = update => {
		updateProfileData(update);
	};

	const onChangeDisabilityData = update => {
		// Cannot change state if None already selected
		if (
			update.target.name !== "disabilityNone" &&
			disabilityData.disabilityNone
		)
			return;
		// Ensure no other selections if None selected
		if (update.target.name === "disabilityNone" && update.target.checked) {
			resetDisabilityData();
			return;
		}
		updateDisabilityData(update);
	};

	const onChangeDiagnosisData = update => {
		// Cannot change state if None already selected
		if (
			update.target.name !== "diagnosisNone" &&
			diagnosisData.diagnosisNone
		)
			return;
		// Ensure no other selections if None selected
		if (update.target.name === "diagnosisNone" && update.target.checked) {
			resetDiagnosisData();
			return;
		}
		updateDiagnosisData(update);
	};

	const onChangePreferenceData = update => {
		updatePreferenceData(update);
	};

	const onSubmitTriggerData = async event => {
		event.preventDefault();

		// Combine form data
		const formData = { ...triggerData, ...blockedData };
		try {
			// Make sure that accordion closes instantly
			setCurrentKey("");
			const response = await axios.post(
				"/categories/updateStoriesBlock",
				formData
			);
			if (response.data.status === ERROR.NO_ERROR) {
				// DEBUG
				console.log("Trigger data updated OK...");
			}
		} catch (error) {
			console.log("Could not submit trigger data");
		}
	};

	const onChangeTriggerData = update => {
		updateTriggerData(update);
	};

	const onChangeBlockedData = update => {
		updateBlockedData(update);
	};

	const managementDataRef = useRef();
	const onChangeManagementData = update => {
		updateManagementData(update);
	};

	const onSubmitManagementData = async event => {
		event.preventDefault();

		// DEBUG
		console.log("Management = ", managementDataRef.current.value);
		const formData = {
			strategyText: managementDataRef.current.value
		};
		try {
			// Store management data
			setManagementData(formData);
			// Make sure that accordion closes instantly
			setCurrentKey("");
			const response = await axios.post(
				"/categories/updateStrategies",
				formData
			);
			if (response.data.status === ERROR.NO_ERROR) {
				// DEBUG
				console.log("Management data updated OK...");
			}
		} catch (error) {
			console.log("Could not submit management data");
		}
	};

	const onSubmitContactInfo = async event => {
		event.preventDefault();

		try {
			// Make sure that accordion closes instantly
			setCurrentKey("");
			const response = await axios.post(
				"/categories/personal",
				contactInfo
			);
			if (response.data.status === ERROR.NO_ERROR) {
				// DEBUG
				console.log("Contact data updated OK...");
			}
		} catch (error) {
			console.log("Could not submit contact data");
		}
	};

	const onChangeContactInfo = update => {
		updateContactInfo(update);
	};

	const onSubmitBadgesData = async event => {
		event.preventDefault();

		try {
			// Make sure that accordion closes instantly
			setCurrentKey("");
			const response = await axios.post("/categories/badges", badgesData);
			if (response.data.status === ERROR.NO_ERROR) {
				// DEBUG
				console.log("Badges updated OK...");
			}
		} catch (error) {
			console.log("Could not update badges status");
		}
	};

	const onChangeBadgesStatus = update => {
		updateBadgesData(update);
	};

	const onUnblockAll = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"/categories/unblockAllStories",
				{}
			);
			const { status } = response;

			if (status === ERROR.NO_ERROR) {
				// DEBUG
				console.log("Stories unblocked...");
				setCurrentKey("");
			}
		} catch (error) {
			console.log("Cannot unblock all stories");
		}
	};

	return (
		<div className="content intervention">
			<MenuHeader />
			<div className="container mt-5 min-height-full">
				<div>
					<h1>About me</h1>
				</div>

				{participantData.first && (
					<React.Fragment>
						{" "}
						<div>
							<p>
								Before we show you the first story, here is a
								chance for you to add some information about
								yourself.
							</p>
							<p>
								These buttons are visible whenever you're using
								the NEON Intervention, so you can add or change
								information about you whenever you want.
							</p>
						</div>
					</React.Fragment>
				)}
				<Accordion
					activeKey={currentKey}
					onSelect={onProfileSelect}
					flush
				>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							<i
								className="fas fa-user-alt fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>Some information about me</strong>
						</Accordion.Header>
						<Accordion.Body>
							<p>
								The will help us choose a story for you. You can
								tell us as little or as much as you wish.
							</p>
							<Form onSubmit={onSubmitProfileData}>
								<Form.Group className="mb-3">
									<Form.Label>
										<h2>Gender</h2>
									</Form.Label>
									{/* <Form.Control
										id="genderVal"
										type="hidden"
										value={
											userProfile
												? userProfile.profile.gender
												: "0"
										}
									/> */}
									<Form.Select
										id="gender"
										name="gender"
										value={profileData.gender}
										onChange={onChangeProfileData}
									>
										<option value="0">
											Prefer not to say
										</option>
										<option value="1">Female</option>
										<option value="2">Male</option>
										<option value="3">Other</option>
									</Form.Select>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>
										<h2>Age</h2>
									</Form.Label>
									<Form.Control
										id="ageVal"
										type="hidden"
										// value={props.profile.age}
									/>
									<Form.Select
										id="age"
										name="age"
										value={profileData.age}
										onChange={onChangeProfileData}
									>
										<option value="0">
											Prefer not to say
										</option>
										<option value="1">18-25</option>
										<option value="2">26-40</option>
										<option value="3">41-65</option>
										<option value="4">66+</option>
									</Form.Select>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>
										<h2>Ethnicity</h2>
									</Form.Label>
									<Form.Control
										id="ethnicityVal"
										type="hidden"
										// value={props.profile.ethnicity}
									/>
									<Form.Select
										id="ethnicity"
										name="ethnicity"
										value={profileData.ethnicity}
										onChange={onChangeProfileData}
									>
										<option value="0">
											Prefer not to say
										</option>
										<option value="1">Asian</option>
										<option value="2">
											Black/African/Caribbean
										</option>
										<option value="3">
											Dual/multiple ethnic group
										</option>
										<option value="4">
											Other ethnic group
										</option>
										<option value="5">White</option>
									</Form.Select>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>
										<h2>Sexuality</h2>
									</Form.Label>
									<Form.Control
										id="sexualityVal"
										type="hidden"
										// value={props.profile.sexuality}
									/>
									<Form.Select
										id="sexuality"
										className="custom-select"
										name="sexuality"
										value={profileData.sexuality}
										onChange={onChangeProfileData}
									>
										<option value="0">
											Prefer not to say
										</option>
										<option value="1">Bisexual</option>
										<option value="2">Gay man</option>
										<option value="3">Heterosexual</option>
										<option value="4">
											Lesbian/gay woman
										</option>
										<option value="5">Other</option>
									</Form.Select>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>
										<h2>Stage of recovery</h2>
									</Form.Label>
									<Form.Control
										id="recoveryVal"
										type="hidden"
										// value={props.profile.recovery}
									/>
									<Form.Select
										id="recovery"
										name="recovery"
										value={profileData.recovery}
										onChange={onChangeProfileData}
									>
										<option value="0">
											Prefer not to say
										</option>
										<option value="1">
											Working on recovery
										</option>
										<option value="2">
											Thinking about recovery
										</option>
										<option value="3">
											Not yet thinking about recovery
										</option>
										<option value="4">
											Rejects recovery
										</option>
									</Form.Select>
								</Form.Group>

								<Form.Group>
									<Form.Label>
										<h2>Disability</h2>
										(Leave empty if you prefer not to say)
									</Form.Label>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="disabilityNone"
												name="disabilityNone"
												label="None"
												checked={
													disabilityData.disabilityNone
												}
												onChange={
													onChangeDisabilityData
												}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="disabilityVisual"
												name="disabilityVisual"
												label="Visual difficulties"
												checked={
													disabilityData.disabilityVisual
												}
												onChange={
													onChangeDisabilityData
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="disabilityHearing"
												name="disabilityHearing"
												label="Hearing difficulties"
												checked={
													disabilityData.disabilityHearing
												}
												onChange={
													onChangeDisabilityData
												}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="disabilityMobility"
												name="disabilityMobility"
												label="Mobility/stamina
													difficulties"
												checked={
													disabilityData.disabilityMobility
												}
												onChange={
													onChangeDisabilityData
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="disabilityMemory"
												name="disabilityMemory"
												label="Cognitive difficulties"
												checked={
													disabilityData.disabilityMemory
												}
												onChange={
													onChangeDisabilityData
												}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="disabilitySelf"
												name="disabilitySelf"
												label="Self-care difficulties"
												checked={
													disabilityData.disabilitySelf
												}
												onChange={
													onChangeDisabilityData
												}
											/>
										</div>
									</div>
								</Form.Group>

								<Form.Group>
									<Form.Label>
										<h2>Diagnosis</h2>
										(Leave empty if you prefer not to say)
									</Form.Label>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisNone"
												name="diagnosisNone"
												label="None"
												checked={
													diagnosisData.diagnosisNone
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisNeuro"
												name="diagnosisNeuro"
												label="Neurodevelopmental disorder"
												checked={
													diagnosisData.diagnosisNeuro
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisEating"
												name="diagnosisEating"
												label="Eating or food-related
													disorder"
												checked={
													diagnosisData.diagnosisEating
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisMood"
												name="diagnosisMood"
												label="Mood disorder"
												checked={
													diagnosisData.diagnosisMood
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisPersonality"
												name="diagnosisPersonality"
												label="Personality disorder"
												checked={
													diagnosisData.diagnosisPersonality
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisOCD"
												name="diagnosisOCD"
												label="Obsessive-compulsive
													disorder"
												checked={
													diagnosisData.diagnosisOCD
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisSchiz"
												name="diagnosisSchiz"
												label="Schizophrenia or other
													psychosis"
												checked={
													diagnosisData.diagnosisSchiz
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisTrauma"
												name="diagnosisTrauma"
												label="Trauma/stress disorder"
												checked={
													diagnosisData.diagnosisTrauma
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisSubstance"
												name="diagnosisSubstance"
												label="Substance-related disorders"
												checked={
													diagnosisData.diagnosisSubstance
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="diagnosisRejects"
												name="diagnosisRejects"
												label="Rejects diagnostic framework"
												checked={
													diagnosisData.diagnosisRejects
												}
												onChange={onChangeDiagnosisData}
											/>
										</div>
									</div>
								</Form.Group>

								<Form.Group>
									<Form.Label>
										<h2>Narrative format preference</h2>
									</Form.Label>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="storyPreferencesText"
												name="storyPreferencesText"
												label="Narratives containing text"
												checked={
													preferenceData.storyPreferencesText
												}
												onChange={
													onChangePreferenceData
												}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="storyPreferencesAudio"
												name="storyPreferencesAudio"
												label="Narratives containing audio"
												checked={
													preferenceData.storyPreferencesAudio
												}
												onChange={
													onChangePreferenceData
												}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="storyPreferencesMoving"
												name="storyPreferencesMoving"
												label="Narratives containing moving
													images"
												checked={
													preferenceData.storyPreferencesMoving
												}
												onChange={
													onChangePreferenceData
												}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="storyPreferencesStatic"
												name="storyPreferencesStatic"
												label="Narratives containing static
													images"
												checked={
													preferenceData.storyPreferencesStatic
												}
												onChange={
													onChangePreferenceData
												}
											/>
										</div>
									</div>
								</Form.Group>

								<div className="mt-3">
									<button
										type="submit"
										className="btn btn-primary"
									>
										<i
											className="fas fa-sign-in-alt ms-1 me-3"
											aria-hidden="true"
										/>
										Update
									</button>
								</div>
							</Form>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							<i
								className="fas fa-trash-alt fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>Stories I don't want to see</strong>
						</Accordion.Header>
						<Accordion.Body>
							<Form onSubmit={onSubmitTriggerData}>
								<Form.Group className="mb-3">
									<Form.Label>
										<h2 className="mb-3">
											I <strong>do not</strong> want
											stories dealing with these issues
										</h2>
									</Form.Label>

									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="abuse"
												name="abuse"
												label="Abuse or sexual viiolence"
												checked={triggerData.abuse}
												onChange={onChangeTriggerData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="loss"
												name="loss"
												label="Loss of life or endangerment to
												life"
												checked={triggerData.loss}
												onChange={onChangeTriggerData}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="harm"
												name="harm"
												label="Self-harm including eating
												disorders"
												checked={triggerData.harm}
												onChange={onChangeTriggerData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="violence"
												name="violence"
												label="Violence or aggression"
												checked={triggerData.violence}
												onChange={onChangeTriggerData}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="injustice"
												name="injustice"
												label="Injustice, prejudice and
												discrimination"
												checked={triggerData.injustice}
												onChange={onChangeTriggerData}
											/>
										</div>
									</div>
									<h2 className="mt-4 mb-3">
										I <strong>do not</strong> want stories
										including
									</h2>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="text"
												name="text"
												label="Text"
												checked={blockedData.text}
												onChange={onChangeBlockedData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="audio"
												name="audio"
												label="Audio"
												checked={blockedData.audio}
												onChange={onChangeBlockedData}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="moving"
												name="moving"
												label="Moving images"
												checked={blockedData.moving}
												onChange={onChangeBlockedData}
											/>
										</div>
										<div className="col-xs-12 col-md-6 mb-2">
											<Form.Check
												type="checkbox"
												id="static"
												name="static"
												label="Static images"
												checked={blockedData.static}
												onChange={onChangeBlockedData}
											/>
										</div>
									</div>
									<div className="mt-3">
										<h5 className="text-danger">
											{props.msg}
										</h5>
									</div>
									<div className="mt-3">
										<button
											type="submit"
											className="btn btn-primary"
										>
											<i
												className="fas fa-sign-in-alt ms-1 me-3"
												aria-hidden="true"
											/>
											Update
										</button>
									</div>
								</Form.Group>
							</Form>
							<Form onSubmit={onUnblockAll}>
								<Button variant="warning" type="submit">
									<i
										className="fas fa-trash-restore-alt ms-1"
										aria-hidden="true"
									/>
									Unblock All Stories
									<Badge
										className="text-black ms-1"
										bg="light"
									>
										{blockedStories}
									</Badge>
								</Button>
							</Form>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>
							<i
								className="far fa-life-ring fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>My self-management strategies</strong>
						</Accordion.Header>
						<Accordion.Body>
							<Form onSubmit={onSubmitManagementData}>
								<Form.Group>
									<Form.Label>
										If you are feeling upset or distressed,
										what would you like to be reminded
										about?
									</Form.Label>
									<Form.Control
										ref={managementDataRef}
										as="textarea"
										rows={5}
										name="strategyText"
										maxLength={300}
										placeholder={`1. To take some time out for myself.\n2. To talk to others.`}
										onChange={onChangeManagementData}
										value={managementData.strategyText}
									/>
									{/* {props.strategies.text} */}
									<button
										type="submit"
										className="btn btn-primary"
									>
										<i
											className="fas fa-sign-in-alt ms-1 me-3"
											aria-hidden="true"
										/>{" "}
										Update
									</button>
								</Form.Group>
							</Form>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header>
							<i
								className="fas fa-address-card fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>Personal contact information</strong>
						</Accordion.Header>
						<Accordion.Body>
							<Form onSubmit={onSubmitContactInfo}>
								<Form.Group className="row mb-3">
									<Form.Label className="col-3">
										<h2>Email</h2>
									</Form.Label>
									<div className="col-9">
										<Form.Control
											type="email"
											name="username"
											value={contactInfo.username}
											onChange={onChangeContactInfo}
										/>
									</div>
								</Form.Group>
								<Form.Group className="row mb-3">
									<Form.Label className="col-3">
										<h2>Mobile</h2>
									</Form.Label>
									<div className="col-9">
										<Form.Control
											type="tel"
											name="phone"
											pattern="[0-9]{11}"
											value={contactInfo.phone}
											onChange={onChangeContactInfo}
										/>
									</div>
								</Form.Group>
								<input
									type="hidden"
									name="groupStatus"
									value={props.groupStatus}
								/>
								<button
									type="submit"
									className="btn btn-primary"
								>
									<i
										className="fas fa-sign-in-alt ms-1 me-3"
										aria-hidden="true"
									/>{" "}
									Update
								</button>
							</Form>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="4">
						<Accordion.Header>
							<i
								className="fas fa-award ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>NEON badges</strong>
						</Accordion.Header>
						<Accordion.Body>
							<Form onSubmit={onSubmitBadgesData}>
								<div className="ms-4 mb-2">
									<Form.Check
										type="checkbox"
										name="badgesEnabled"
										id="badgesEnabled"
										label="Show badges"
										checked={badgesData.badgesEnabled}
										onChange={onChangeBadgesStatus}
									/>
								</div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									<i
										className="fas fa-sign-in-alt ms-1 me-3"
										aria-hidden="true"
									/>{" "}
									Update
								</button>
							</Form>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>

				<Modal show={showAlert} onHide={dismissAlert}>
					<Modal.Header closeButton>
						<Modal.Title>Cannot block all formats</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						You will not be able to view any stories if all formats
						are blocked.
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={dismissAlert}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>

				{participantData.first && (
					<React.Fragment>
						{" "}
						<div className="mt-3">
							<Link
								to={
									"/stories/checkStory?story=" +
									participantData.storyID +
									"&route=" +
									participantData.firstRoute +
									"&first=1"
								}
								className="btn btn-primary"
							>
								Next
							</Link>
						</div>
					</React.Fragment>
				)}
			</div>
			<FooterStories />
		</div>
	);
};

export default Profile;
