import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ReactBootstrapSlider from "react-bootstrap-slider";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { LABELS, ERROR, ACTIONS } from "../state/Config.js";

const Meaning = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const formatLife = value => {
		const tooltip = document
			.getElementById("lifeRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["lifeRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatMeaningful = value => {
		const tooltip = document
			.getElementById("meaningFulRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["meaningFulRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatPurpose = value => {
		const tooltip = document
			.getElementById("purposeRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["purposeRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatClear = value => {
		const tooltip = document
			.getElementById("clearRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["clearRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatSenseful = value => {
		const tooltip = document
			.getElementById("sensefulRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["sensefulRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatSatisfy = value => {
		const tooltip = document
			.getElementById("satisfyRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["satisfyRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatSignificant = value => {
		const tooltip = document
			.getElementById("significantRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["significantRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatMission = value => {
		const tooltip = document
			.getElementById("missionRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["missionRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatNoClear = value => {
		const tooltip = document
			.getElementById("noClearRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["noClearRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const formatSearch = value => {
		const tooltip = document
			.getElementById("searchRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.SEVEN_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["searchRatings"]: value
		};

		return LABELS.Seven[adjusted];
	};

	const onSubmitMeaning = async event => {
		event.preventDefault();

		try {
			// DEBUG
			console.log("Form = ", formDataRef.current);

			const response = await postData(
				"/categories/meaning",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit meaning data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>The meaning you find in your life</h1>
					</div>
					<div>
						{participantData.week52 ? (
							<React.Fragment>
								{" "}
								<p>
									This information will help us to understand
									how much you are searching for meaning in
									your life, and how much you have found it,
									and to see how these have changed during the
									trial.
								</p>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<p>
									This information will help us to understand
									how much you are searching for meaning in
									your life, and how much you have found it,
									and to see how these change during the
									trial.
								</p>
							</React.Fragment>
						)}{" "}
					</div>
					<div className="mb-5">
						<p>
							Please take a moment to think about what makes your
							life feel important to you. Please respond to the
							following statements as truthfully and accurately as
							you can, and also please remember that these are
							very subjective questions and that there are no
							right or wrong answers.
						</p>
					</div>
					{/* PS added hr */}
					<hr className="mt-5 mb-5" />

					<Form
						onSubmit={onSubmitMeaning}
						id="meaningRatings"
						className="mb-5 small-gap"
					>
						<ol className="questions">
							<li>
								<label htmlFor="lifeRatings">
									I understand my life's meaning.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="lifeRatings"
										name="lifeRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatLife}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="meaningFulRatings">
									I am looking for something that makes my
									life feel meaningful.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="meaningFulRatings"
										name="meaningFulRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatMeaningful}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="purposeRatings">
									I am always looking to find my life’s
									purpose.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="purposeRatings"
										name="purposeRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatPurpose}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="clearRatings">
									My life has a clear sense of purpose.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="clearRatings"
										name="clearRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatClear}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="sensefulRatings">
									I have a good sense of what makes my life
									meaningful.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="sensefulRatings"
										name="sensefulRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatSenseful}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="satisfyRatings">
									I have discovered a satisfying life purpose.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="satisfyRatings"
										name="satisfyRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatSatisfy}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="significantRatings">
									I am always searching for something that
									makes my life feel significant.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="significantRatings"
										name="significantRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatSignificant}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="missionRatings">
									I am seeking a purpose or mission for my
									life.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="missionRatings"
										name="missionRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatMission}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="noClearRatings">
									My life has no clear purpose.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="noClearRatings"
										name="noClearRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatNoClear}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="searchRatings">
									I am searching for meaning in my life.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<ReactBootstrapSlider
										id="searchRatings"
										name="searchRatings"
										value={1}
										min={1}
										max={7}
										ticks={[1, 2, 3, 4, 5, 6, 7]}
										ticks_tooltip={true}
										formatter={formatSearch}
										required
									/>
									<div className="row">
										<div className="col-6 text-start">
											<label className="form-check-label">
												<em>Absolutely Untrue</em>
											</label>
										</div>
										<div className="col-6 text-end">
											<label className="form-check-label">
												<em>Absolutely True </em>
											</label>
										</div>
									</div>
								</div>
							</div>
						</ol>
						<div>
							<input
								type="hidden"
								name="formRequestID"
								value={props.formRequestID}
							/>
							<button
								type="submit"
								className="btn btn-primary ms-5"
							>
								<i className="fas fa-check" /> Submit
							</button>
						</div>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Meaning;
