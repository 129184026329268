import React from "react";
import { Link } from "react-router-dom";

const FooterStories = () => {
	return (
		<footer
			className="container-fluid mt-3 footer-row intervention"
			id="footer"
		>
			<div className="row">
				<div className="container pt-4 pb-4 text-center">
					<div className="row">
						<div className="col-12">
							<Link
								to="/stories/showOptions"
								className="btn btn-dark btn-lg"
							>
								<i className="fas fa-home" /> Home
							</Link>
							<Link
								to="/stories/fullWelcome"
								className="btn btn-light btn-lg"
							>
								<i className="fas fa-thumbs-up" /> Welcome
							</Link>
							<Link
								to="/stories/about"
								className="btn btn-info btn-lg"
							>
								<img
									src="/images/neon_icon.png"
									alt="NEON icon"
									className="me-2"
									width="20"
									height="20"
								/>{" "}
								<span className="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
									About
								</span>{" "}
								NEON
							</Link>
							<Link
								to="/stories/help"
								className="btn btn-primary btn-lg"
							>
								<i className="fas fa-frown-open" /> I'm upset
							</Link>
							<a
								href="https://www.google.co.uk/"
								className="btn btn-warning last-button btn-lg"
							>
								<i className="fas fa-exclamation-triangle" />{" "}
								Get me out of here
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterStories;
