import React, { useState, useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";
import postData from "../data/PostData.js";
import { ERROR, ACTIONS } from "../state/Config.js";
import useParticipantStore from "../state/participantStore.js";

const SELECT_OPTIONS = 7;

const Consent = props => {
	const navigate = useNavigate();
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);

	// Alert
	const [consentAlert, setConsentAlert] = useState(false);
	const handleClose = () => {
		setConsentAlert(false);
	};

	// Errors
	const [errorMsg, setErrorMsg] = useState("");

	const userNameRef = useRef("");
	const phoneRef = useRef("");
	const consentRef = useRef("");
	const selectRefs = useRef({});

	const onSelectionChange = event => {
		selectRefs.current[event.target.name] = event.target.value;
	};

	const areYes = (current, index, values) => values[index] === "Yes";

	const onSubmitConsent = async event => {
		event.preventDefault();

		// See if we've agreed consent to all options
		const values = Object.values(selectRefs.current);
		if (values.length < SELECT_OPTIONS) {
			setConsentAlert(true);
			return;
		}

		if (!values.every(areYes)) {
			setConsentAlert(true);
			return;
		}

		try {
			const formData = {
				partID: participantData.partID,
				trial: participantData.trial,
				username: userNameRef.current.value,
				phone: phoneRef.current.value,
				consent8: consentRef.current.value
			};
			const response = await postData("users/validateConsent", formData);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_ALREADY_EXISTS:
					setErrorMsg(
						"That email already exists - please enter another"
					);
					break;

				default:
					break;
			}
		} catch (error) {
			console.log("Could not submit form data", error);
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					<div>
						<h3>
							Informed Consent Form - {participantData.trial}{" "}
							Trial
						</h3>
						<p>Version 4.0. 13th October 2020.</p>
					</div>

					<div>
						<p className="blueLinks">
							If you have any questions about this form, you can
							contact the research team through{" "}
							<a href="mailto:neon@nottingham.ac.uk?subject=NEON study consent">
								neon@nottingham.ac.uk
							</a>{" "}
							or you can leave a voice message or send a text to
							07973 841271.
						</p>
						<p>
							To provide your consent to take part in the NEON
							study, <strong>please select "Yes"</strong> against
							questions 1-7.
						</p>
						{/* PS added HR */}
						<hr className="mt-5" />
					</div>

					<div className="container-fluid mb-3">
						<div className="row">
							<div className="col-12">
								{/* PS removed class="text-justify */}
								<Form
									onSubmit={onSubmitConsent}
									id="consentForm"
								>
									<ol className="consent-points">
										{/* PS added margins to rows, changed col-9 and col-3 to col-8 and col-4 to fix mobile, added label tags */}
										<div className="row mt-3">
											<div className="col-8 col-md-10 col-xl-11">
												<li>
													<label htmlFor="consent1">
														I have read and
														understand the online
														participant information
														sheet (PIS) dated
														version 4.0. 13th
														October 2020.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent1"
													name="consent1"
													onChange={onSelectionChange}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-8 col-md-10 col-xl-11">
												<li>
													<label htmlFor="consent2">
														I understand that my
														participation is
														voluntary, and that I
														can withdraw at any time
														without giving any
														reason, without my care
														or legal rights being
														affected.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent2"
													name="consent2"
													onChange={onSelectionChange}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-8 col-md-10 col-xl-11">
												<li>
													<label htmlFor="consent3">
														I understand that my
														data will be held by DRT
														Software Ltd.,
														Nottingham University
														and Nottinghamshire
														Healthcare NHS
														Foundation Trust.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent3"
													name="consent3"
													onChange={onSelectionChange}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-8 col-md-10 col-xl-11">
												<li>
													<label htmlFor="consent4">
														I understand that the
														contact details I
														provide can be used to
														contact me about the
														study, but for no other
														reasons.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent4"
													name="consent4"
													onChange={onSelectionChange}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-8 col-md-10 col-xl-11">
												<li>
													<label htmlFor="consent5">
														I understand that my
														data will be accessed by
														the study team, and may
														be audited by
														Nottinghamshire
														Healthcare NHS
														Foundation Trust and the
														Pragmatic Clinical
														Trials Unit at Queen
														Mary University of
														London.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent5"
													name="consent5"
													onChange={onSelectionChange}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-8 col-md-10 col-xl-11">
												<li>
													<label htmlFor="consent6">
														I consent to the use of
														an anonymised version of
														information collected
														about me in this and
														other research studies.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent6"
													name="consent6"
													onChange={onSelectionChange}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-8 col-md-10 col-xl-11">
												<li className="last-li">
													<label htmlFor="consent7">
														I agree to take part in
														this research study.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent7"
													name="consent7"
													onChange={onSelectionChange}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-8 col-md-10 col-xl-11">
												<li>
													<label htmlFor="consent8">
														OPTIONAL: I would like
														to take part in an
														interview about the
														trial (by telephone or
														video-conference). I
														understand this is
														optional and does not
														affect my trial
														participation.
													</label>
												</li>
											</div>
											<div className="col-4 col-md-2 col-xl-1 text-center">
												<Form.Select
													id="consent8"
													name="consent8"
													ref={consentRef}
												>
													<option>No</option>
													<option>Yes</option>
												</Form.Select>
											</div>
										</div>
									</ol>
									{/* PS finiahed row */}

									{/* PS added new div */}
									<div className="mb-5">
										<hr />
										{/* PS added margin */}
										<p className="mt-5">
											To create a login for you, we need
											an email address. Please enter this
											below, and we'll send an email with
											a link to click, to check it is
											working.
										</p>
										<p>
											We don't need to know your name for
											this study. If you don't want us to
											know your name, you could use an
											email address which doesn't include
											your name.
										</p>
										{/* PS changed form formatting, including new col  */}
										<Form.Group className="row">
											<label className="col-3 col-form-label text-right">
												Email
											</label>
											<div className="col-8 col-md-7 mb-3">
												<Form.Control
													ref={userNameRef}
													type="email"
													className="form-control"
													name="username"
													placeholder="Enter email"
													required
												/>
											</div>
											<div className="col-1 col-md-2" />
										</Form.Group>
										<p className="text-danger">
											{errorMsg}
										</p>
										<p>
											We can also use text messages to
											contact you about the study. If you
											would like to provide a mobile
											number, please enter it below.
										</p>
										<Form.Group className="row">
											<label className="col-3 col-form-label text-right">
												Phone
											</label>
											<div className="col-8 col-md-7 mb-3">
												<Form.Control
													ref={phoneRef}
													type="tel"
													className="form-control"
													name="phone"
													placeholder="12345678900"
													pattern="[0-9]{11}"
												/>
											</div>
											<div className="col-1 col-md-2" />
										</Form.Group>
										<input
											type="hidden"
											name="partID"
											value={props.partID}
										/>
										<input
											type="hidden"
											name="trial"
											value={props.trial}
										/>
										<div className="row text-center">
											<div className="col-0 col-md-3" />
											<div className="col-6 col-md-4 text-left">
												<button
													type="submit"
													className="btn btn-primary"
												>
													<i className="fas fa-check" />{" "}
													I agree to take part in the
													study
												</button>
											</div>
											<div className="col-6 col-md-3 text-right">
												<Link
													to="/noconsent"
													className="btn btn-primary"
												>
													<i className="fas fa-times" />{" "}
													I do not wish to take part
												</Link>
											</div>
											<div className="col-0 col-md-2" />
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<StripeFooter />
			<Modal
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
				show={consentAlert}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>Consent</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Please answer Yes to questions 1 to 7 if you want to
						give consent.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Consent;
