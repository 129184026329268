import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ContactModal from "../modals/ContactModal";
import VimeoModal from "../modals/VimeoModal";
import { Link } from "react-router-dom";

const Testimonials = props => {
	// Contact Modal
	const [showContact, setShowContact] = useState(false);
	const handleCloseContact = () => setShowContact(false);
	const handleShowContact = () => setShowContact(true);

	// Video Modal
	const [showVideo, setShowVideo] = useState(false);
	const [currentUrl, setCurrentUrl] = useState("");
	const handleCloseVideo = () => setShowVideo(false);
	const handleShowVideo = props => {
		setCurrentUrl(props.target.getAttribute("data-src"));
		setShowVideo(true);
	};

	return (
		<React.Fragment>
			<div className="container mt-3">
				<a
					className="sr-only sr-only-focusable text-primary"
					href="#main"
				>
					Skip to main content
				</a>
				<div className="row d-none d-xl-flex">
					<div className="col-2">
						<a href="/">
							<img
								src="/assets/images/NEON logo splash left.png"
								alt="NEON Trial logo"
							/>
						</a>
					</div>
					<div className="col-5">
						<a href="/">
							<img
								src="/assets/images/NEON logo splash right.png"
								alt="Narrative Experiences ONline"
							/>
						</a>
					</div>
					<div className="col-5 text-end">
						<a
							href="https://researchintorecovery.com/neon"
							target="_blank"
							className="btn btn-info ps-2"
						>
							<img
								src="/assets/images/neon icon.png"
								alt="NEON icon"
								className="me-1"
								width="16"
								height="16"
							/>{" "}
							About NEON
						</a>
						<Button
							className="btn-dark ms-3"
							onClick={handleShowContact}
						>
							<i className="fas fa-comment fa-fw me-1" /> Contact
						</Button>
						<Link className="btn btn-primary ps-2 ms-3" to="/login">
							<i className="fas fa-sign-in-alt" /> Log in
						</Link>
					</div>
				</div>
				<div className="row d-xl-none">
					<div className="col-7">
						<img
							src="/assets/images/NEON logo strapline mobile.png"
							alt="NEON logo: Narrative Experiences ONline"
							width="200"
							height="54"
						/>
					</div>
					<div className="col-5 text-end">
						<Link className="btn btn-primary ps-3" to="/login">
							<i className="fas fa-sign-in-alt" /> Log in
						</Link>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-3">
						<div className="trialprocedures-stripe-top" id="main" />
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-0 col-xl-2 d-none d-xl-block">
						<div
							style={{
								height: "100%",
								background:
									"url('/assets/images/kite_desktop_bottom.jpg') repeat-y"
							}}
						>
							<img
								src="/assets/images/kite_desktop_200_faded.jpg"
								width="140"
								alt="Photo of a kite"
							/>
						</div>
					</div>
					<div className="col-12 d-xl-none">
						<img
							src="/assets/images/kite_mobile.jpg"
							width="100%"
							alt="Photo of a kite"
						/>
					</div>

					<div className="col-12 col-xl-10 mt-4 mb-4">
						<p className="text-end">
							<Link to="/" className="btn btn-primary ps-3 mb-3">
								<i className="fas fa-arrow-left pe-3" />
								Back to homepage
							</Link>
						</p>
						<h1>What our users think</h1>
						<p className="mb-5 blueLinks">
							We're collecting accounts about how people use NEON.
							Please contact us through{" "}
							<a href="mailto:neon@nottingham.ac.uk?subject=Neon Study">
								neon@nottingham.ac.uk
							</a>
							if you would like to add your own.
						</p>

						<div className="row">
							<div className="col-12 col-lg-6 pe-3">
								<div className="card video-card-grey mb-4 p-2">
									<div className="card-body">
										{/* New button for modal */}
										<Button
											onClick={handleShowVideo}
											className="ps-3 mb-3 btn-light stretched-link video-btn"
											data-src="https://player.vimeo.com/video/518073389"
										>
											<i className="fas fa-question pe-3" />
											How Lara has benefitted
										</Button>
										<p className="card-text">
											The benefits trial participant Lara
											has experienced from engaging with
											NEON.
										</p>
									</div>
								</div>
							</div>

							<div className="col-12 col-lg-6 ps-3">
								<div className="card video-card-grey mb-4 p-2">
									<div className="card-body">
										<Button
											onClick={handleShowVideo}
											className="ps-3 mb-3 btn-light stretched-link video-btn"
											data-src="https://player.vimeo.com/video/518073763"
										>
											<i className="fas fa-question pe-3" />
											Lara's summary
										</Button>
										<p className="card-text">
											Lara's summary of her experience
											with NEON.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Vimeo Video Modal */}
					<VimeoModal
						showVideo={showVideo}
						url={currentUrl}
						handleClose={handleCloseVideo}
					/>
					{/* Add contact modal */}
					<ContactModal
						showContact={showContact}
						handleClose={handleCloseContact}
					/>
					<input
						type="hidden"
						id="unsupportedBrowser"
						value="false"
					/>
				</div>{" "}
				{/* row */}
			</div>
			<div className="container">
				<div className="row">
					<div className="col-12 mb-5">
						<div className="trialprocedures-stripe-bottom" />
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-xs-0 col-xl-9 mb-5 d-none d-xl-flex">
						<a href="https://www.nottingham.ac.uk/" target="_blank">
							<img
								src="/assets/images/logo_uon.png"
								className="me-4"
								height="65"
								alt="University of Nottingham logo"
							/>
						</a>
						<a
							href="https://www.institutemh.org.uk/"
							target="_blank"
						>
							<img
								src="/assets/images/logo_imh2.png"
								className="ms-2 me-4 pe-1"
								height="65"
								alt="Institute of Mental Health Nottingham logo"
							/>
						</a>
						<a href="https://www.nihr.ac.uk/" target="_blank">
							<img
								src="/assets/images/logo_nihr.png"
								height="65"
								className="m1-2"
								alt="Funded by NIHR (The National Institute for Health Research)"
							/>
						</a>
					</div>
					<div className="col-xs-12 col-xl-3 mb-5 text-end">
						<a
							href="https://www.nottinghamshirehealthcare.nhs.uk/"
							target="_blank"
						>
							<img
								src="/assets/images/NHS_Logo.png"
								height="65"
								alt="NHS logo: Nottinghamshire Healthcare NHS Foundation Trust"
							/>
						</a>
					</div>
				</div>
			</div>{" "}
		</React.Fragment>
	);
};

export default Testimonials;
