import React from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

export const ErrorMessage = () => {
	const navigate = useNavigate();

	const backToLogin = () => {
		navigate(`/login`);
	};

	return (
		<div className="content mt-3">
			<MainHeader />
			<div className="container">
				<div className="mb-3">
					<h3>
						<strong>Sorry!</strong>
					</h3>
				</div>
				<div className="blueLinks">
					<p>
						It would appear that something has gone wrong with NEON.
					</p>
					<p>
						This might just be a blip, but If this keeps happening
						to you, email us through{" "}
						<a href="mailto:neon@nottingham.ac.uk">
							neon@nottingham.ac.uk
						</a>{" "}
						so that we can fix it.
					</p>
					<Button onClick={backToLogin}>Back to Login</Button>
				</div>
			</div>
			<StripeFooter />
		</div>
	);
};
