import React from "react";
import NoBanner from "../partials/NoBanner.js";
import useParticipantStore from "../state/participantStore.js";
import { Link } from "react-router-dom";

const SafetySubmitted = () => {
	const participantData = useParticipantStore(state => state.participantData);

	return (
		<div className="content mt-5">
			<NoBanner />
			<div className="container">
				<div className="mb-3">
					<h3>Submission of safety reporting form</h3>
				</div>
				<p className="text-danger">
					Thank you for taking the time to complete this form. The
					information that you have provided has been immediately
					passed to the NEON Chief Investigator and study coordinator,
					and will be processed rapidly.
				</p>

				{participantData.mainPage ? (
					<Link to="/stories/controlHome" className="btn btn-primary">
						Home
					</Link>
				) : participantData.intervention ? (
					<Link to="/categories/showStories" class="btn btn-primary">
						Home
					</Link>
				) : (
					<Link to="/controlHome" class="btn btn-primary">
						Home
					</Link>
				)}
			</div>
		</div>
	);
};

export default SafetySubmitted;
