import React, { useState } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import useParticipantStore from "../state/participantStore.js";
import postData from "../data/PostData.js";
import Form from "react-bootstrap/Form";

const ThanksControl = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);

	const [msg, setMessage] = useState("");

	const onSubmitVoucher = async event => {
		event.preventDefault();

		try {
			const response = await postData("/categories/submitVoucher", {});

			// DEBUG
			console.log("Response = ", response);

			const { data } = response;

			setMessage(data.message);
		} catch (error) {
			console.log("Could not submit voucher");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					<div className="row mb-5">
						<div
							className={
								participantData.onNeonTrial ? "col-6" : "col-12"
							}
						>
							<p>
								Thank you for your responses - you have now
								completed the survey.
							</p>
							{!participantData.randomised && (
								<React.Fragment>
									{" "}
									<p>
										You have been randomly selected to
										receive access to the NEON Intervention
										after one year. Until then, you are
										still an important part of our trial,
										and we want you to continue taking part.
										We will contact you to ask you to
										complete our survey again in 1 week, 3
										months and 1 year.
									</p>
								</React.Fragment>
							)}{" "}
							<p>
								{participantData.onNeonTrial &&
									"You can still claim £20 after each survey."}{" "}
								You can safely close this window.
							</p>
						</div>
						{participantData.onNeonTrial && (
							<React.Fragment>
								{" "}
								<div className="col-6">
									<p>
										If you want to claim your £20 voucher
										then click here.
									</p>
								</div>
							</React.Fragment>
						)}{" "}
					</div>

					<div className="row mb-3">
						{participantData.onNeonTrial && (
							<React.Fragment>
								{" "}
								<div className="col-6" />
								<div className="col-6">
									<Form onSubmit={onSubmitVoucher}>
										<button
											type="submit"
											id="voucherRequest"
											className="btn btn-primary"
										>
											Claim voucher
										</button>
									</Form>
								</div>
							</React.Fragment>
						)}{" "}
					</div>
					<span className="text-danger">{msg}</span>
				</div>
				<StripeFooter />
			</div>
		</>
	);
};

export default ThanksControl;
