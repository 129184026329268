import React from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import { useLocation } from "react-router-dom";

const TrialClosed = () => {
	// Get trial name
	const location = useLocation();
	const trialName = new URLSearchParams(location.search).get("trialName");

	return (
		<div className="content mt-3">
			<MainHeader />
			<div className="container">
				<div>
					<h3>Trial Now Closed</h3>
				</div>

				<div>
					<p>
						Thank you for trying to validate your email address. In
						the period since you filled out our consent form, the{" "}
						{trialName} trial has now closed to recruitment due to
						reaching its maximum number of participants, so you
						won't be able to take part in this trial.
					</p>
					<p className="blueLinks">
						If you'd like to read about the results of this trial,
						they will be appearing at{" "}
						<a
							href="http://researchintorecovery.com/neon/"
							target="_blank"
						>
							http://researchintorecovery.com/neon/
						</a>{" "}
						late 2022.
					</p>
				</div>
			</div>
			<StripeFooter />
		</div>
	);
};

export default TrialClosed;
