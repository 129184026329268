import React from "react";
import MenuHeader from "../partials/MenuHeader.js";
import LogoHeader from "../partials/LogoHeader.js";
import { Link, useLocation } from "react-router-dom";
import FooterStories from "../partials/FooterStories.js";
import FooterCategoryControl from "../partials/FooterCategoryControl.js";
import Accordion from "react-bootstrap/Accordion";

const Help = props => {
	// Get url parameters
	const location = useLocation();
	const controlGroup = new URLSearchParams(location.search).get("status");

	return (
		<div className="content intervention">
			{controlGroup ? <LogoHeader /> : <MenuHeader />}
			<div className="container mt-5 min-height-full">
				<div>
					<h1>I'm upset</h1>
				</div>

				<div className="blueLinks">
					<p>
						The NEON team have created this page to try and help if
						you are feeling upset or distressed after accessing
						recovery stories. We've provided strategies, information
						or services that have helped us or others.
					</p>
					<p>
						If you know of personal strategies that can help you,
						enter them into the{" "}
						<Link to="/stories/profile?status=1">
							<strong>About me</strong>
						</Link>{" "}
						page and they will appear here as a reminder.
					</p>
					{props.strategies ? (
						<React.Fragment>
							{" "}
							<div className="card mb-4">
								<div className="card-body">
									<div className="row">
										<div className="col-xs col-md-1">
											<p className="text-center">
												<i
													className="far fa-life-ring help-icon"
													aria-hidden="true"
												/>
											</p>
										</div>
										<div className="col-xs col-md-11">
											<div className="row">
												<div className="col-12 text-center text-md-start">
													<h2>
														{" "}
														My self-management
														strategies
													</h2>
												</div>
											</div>
											<div className="row">
												<div className="col-12 mt-2">
													{props.strategies.text}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							{" "}
							<p>You haven't provided a strategy yet.</p>
						</React.Fragment>
					)}{" "}
				</div>

				<Accordion flush>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							<i
								className="fas fa-phone-alt fa-fw ms-1 me-3 text-start"
								aria-hidden="true"
							/>
							<strong>Who can I talk to immediately?</strong>
						</Accordion.Header>
						<Accordion.Body className="blueLinks">
							<p>
								<span>Samaritans </span>
								<a
									href="https://www.samaritans.org"
									target="_blank"
								>
									www.samaritans.org
								</a>{" "}
								provide 24 hour telephone support on 116 123,
								which is free to call. You can also email, write
								a letter or talk to someone in person.
							</p>
							<p>
								<span>The Mind Infoline </span>
								<a
									href="https://www.mind.org.uk/information-support/helplines/"
									target="_blank"
								>
									www.mind.org.uk/information-support/helplines/
								</a>{" "}
								might help you find support in your local area.
								You can call 0300 123 3393 (9am-6pm Monday to
								Friday) or text 86463.
							</p>
							<p>
								<span>The Mix </span>
								<a
									href="http://www.themix.org.uk/get-support"
									target="_blank"
								>
									www.themix.org.uk/get-support
								</a>{" "}
								provides information and support if you are 25
								or under. You can telephone 0808 808 4994 for
								free from 11am-11pm or text 'THEMIX' to 85258 if
								you are experiencing a crisis.
							</p>
							<p>
								<span>Elefriends </span>
								<a
									href="http://elefriends.org.uk/"
									target="_blank"
								>
									http://elefriends.org.uk
								</a>{" "}
								is an online community managed by Mind, which
								might help you find others with similar
								experiences.
							</p>
							<p>
								<span>Big White Wall </span>
								<a
									href="https://www.bigwhitewall.com/"
									target="_blank"
								>
									https://www.bigwhitewall.com
								</a>{" "}
								is an online community providing support to
								people who are 16 or older and who have a UK
								postcode.
							</p>
							<p>
								<span>Saneline </span>
								<a
									href="http://www.sane.org.uk/what_we_do/support/helpline"
									target="_blank"
								>
									http://www.sane.org.uk/what_we_do/support/helpline
								</a>{" "}
								is a national helpline providing emotional
								support and information “out of hours”. You can
								ring 0300 304 7000 for free from 4:30pm-10:30pm.
							</p>
							<p>
								<span>
									The Rethink Mental Illness Advice Line{" "}
								</span>
								<a
									href="http://www.rethink.org/about-us/our-mental-health-advice"
									target="_blank"
								>
									www.rethink.org/about-us/our-mental-health-advice
								</a>{" "}
								provides advice and information. You can call
								0300 5000 927 from 9.30am - 4pm Monday to
								Friday.
							</p>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							<i
								className="fas fa-band-aid fa-fw ms-1 me-3"
								aria-hidden="true"
							/>
							<strong>What can I do to help myself?</strong>
						</Accordion.Header>
						<Accordion.Body>
							<p>
								There are a range of strategies that can help if
								you are upset or distressed. Here are some
								ideas. Everyone is different, so you will need
								to work out which are helpful for you. If you
								find something helps, you might choose to add
								them to your list of personal strategies.
							</p>
							<p className="text-center">
								<a
									className="btn btn-sm btn-secondary mb-2 me-2"
									href="#expressyourself"
								>
									Express yourself creatively
								</a>
								<a
									className="btn btn-sm btn-secondary mb-2 me-2"
									href="#labelling"
								>
									Labelling your feelings
								</a>
								<a
									className="btn btn-sm btn-secondary mb-2 me-2"
									href="#mindfulness"
								>
									Mindfulness
								</a>
								<a
									className="btn btn-sm btn-secondary mb-2 me-2"
									href="#selfsoothing"
								>
									Self-soothing
								</a>
								<a
									className="btn btn-sm btn-secondary mb-2 me-2"
									href="#meditation"
								>
									Meditation
								</a>
								<a
									className="btn btn-sm btn-secondary mb-2 me-2"
									href="#triggers"
								>
									Breaking up triggers
								</a>
								<a
									className="btn btn-sm btn-secondary mb-2"
									href="#distraction"
								>
									Distraction
								</a>
							</p>
							<div className="blueLinks">
								<hr />
								<h3 id="expressyourself">
									<strong>
										Express yourself creatively through an
										art or creative activity
									</strong>
								</h3>
								<p>
									Sometimes expressing ourselves creatively -
									through playing an instrument, painting,
									writing, baking etc may help in
									externalising our feelings or converting the
									negative energy into something more
									positive. Have you ever thought of sharing
									your own recovery story? You could{" "}
									<a
										href="https://www.researchintorecovery.com/research/neon/neoncollection/donateastory/"
										target="_blank"
									>
										donate it
									</a>{" "}
									to the NEON Collection.
								</p>
								<hr />
								<h3 id="labelling">
									<strong>Labelling your feelings</strong>
								</h3>
								<p>
									Sometimes we may not be fully aware of how
									we're feeling. Just the simple act of
									labelling a feeling can help you to feel
									more in control and less overwhelmed.
								</p>
								<p>
									You can use the Feelings Wheel{" "}
									<a
										href="http://feelingswheel.com/"
										target="_blank"
									>
										feelingswheel.com/
									</a>{" "}
									to label emotions. Identify the emotion in
									the centre of the wheel which best fits how
									you are currently feeling. Work your way
									outwards to find the right label for your
									feeling. Take a moment to acknowledge it and
									take a deep breath. You can find out more
									about emotional labelling here{" "}
									<a
										href="https://tammylenski.com/control-your-emotions-better-affect-labeling/"
										target="_blank"
									>
										tammylenski.com/control-your-emotions-better-affect-labeling/
									</a>{" "}
									and here{" "}
									<a
										href="https://www.psychologytoday.com/gb/blog/sweet-emotion/201408/why-labeling-emotions-matters"
										target="_blank"
									>
										www.psychologytoday.com/gb/blog/sweet-emotion/201408/why-labeling-emotions-matters
									</a>
									.
								</p>
								<hr />
								<h3 id="mindfulness">
									<strong>Mindfulness</strong>
								</h3>
								<p>
									Mindfulness is a tool that can help us focus
									our awareness on the present moment, and
									this can help us to manage strong emotions
									and thoughts. One mindfulness activity is
									called Five of five.
								</p>
								<ol>
									<li>
										Stop for a moment and notice the
										environment around you.{" "}
										<em>
											Name five things that you can see…
										</em>
									</li>
									<li>
										Listen to the sounds that are around you
										and{" "}
										<em>
											name four things that you can hear…
										</em>
									</li>
									<li>
										Notice the things that are in contact
										with your body and{" "}
										<em>
											name three things that you can feel…
										</em>
									</li>
									<li>
										Now turn your attention to thing that
										you may be able to smell and{" "}
										<em>
											name two things that you can smell
										</em>
									</li>
									<li>
										Now focus your attention on the things
										that you can taste and{" "}
										<em>
											name one thing that you can taste
										</em>
									</li>
								</ol>
								<p>
									Try to describe what you notice with as much
									detail as possible. It is normal for your
									mind to wander back to your thoughts. When
									you notice this, just gently bring your
									attention back to the activity, in a
									non-judgemental manner.
								</p>
								<hr />
								<h3 id="selfsoothing">
									<strong>Self-soothing</strong>
								</h3>
								<p>
									We can help ourselves feel more grounded by
									"stimulating" our five senses. Here are some
									examples of activities that might be useful
									:
								</p>
								<ol>
									<li>
										<strong>Sight: </strong>Photos of
										friends, pictures of favourite places,
										mindfulness colouring books.
									</li>
									<li>
										<strong>Sound: </strong>Favourite songs,
										the sound of nature.
									</li>
									<li>
										<strong>Smell: </strong>Perfume,
										candles.
									</li>
									<li>
										<strong>Touch: </strong>Soft jumper,
										hugs from others.
									</li>
									<li>
										<strong>Taste: </strong>Tea, coffee,
										sweets, favourite meal.
									</li>
								</ol>
								<p>
									Self-soothing can be difficult, especially
									when emotions are running high, or if you
									are not used to engaging in self-care. If
									you find your attention wandering,
									acknowledge this, and gently try to refocus
									your attention to describing what your
									senses are experiencing.
								</p>
								<hr />
								<h3 id="meditation">
									<strong>Meditation</strong>
								</h3>
								<p>
									Meditation is the practice of turning one's
									attention to a single point of reference. It
									can involve focusing on the breath, on
									bodily sensations, or on a word or phrase,
									known as a mantra. Some meditation
									activities are provided through the
									following links:
								</p>
								<ul>
									<li>
										<a
											href="https://www.verywellmind.com/how-to-reduce-stress-by-deep-breathing-2797585"
											target="_blank"
										>
											www.verywellmind.com/how-to-reduce-stress-by-deep-breathing-2797585
										</a>
									</li>
									<li>
										<a
											href="https://www.youtube.com/watch?v=tAUf7aajBWE"
											target="_blank"
										>
											www.youtube.com/watch?v=tAUf7aajBWE
										</a>
									</li>
									<li>
										<a
											href="https://www.youtube.com/watch?v=YFSc7Ck0Ao0"
											target="_blank"
										>
											www.youtube.com/watch?v=YFSc7Ck0Ao0
										</a>
									</li>
									<li>
										<a
											href="https://www.youtube.com/watch?v=i50ZAs7v9es"
											target="_blank"
										>
											www.youtube.com/watch?v=i50ZAs7v9es
										</a>
									</li>
									<li>
										<a
											href="https://www.psychologytoday.com/gb/basics/meditation"
											target="_blank"
										>
											www.psychologytoday.com/gb/basics/meditation
										</a>
									</li>
								</ul>
								<p />
								<hr />
								<h3 id="triggers">
									<strong>Breaking up triggers</strong>
								</h3>
								<p>
									Sometimes, an event in story can trigger a
									release of emotions. When this happens, the
									trigger might feel overwhelming and
									paralyzing. It can help to break the trigger
									up into smaller parts:
								</p>
								<ol>
									<li>
										Acknowledge that you're not able to look
										beyond the trigger right now.
									</li>
									<li>
										Write down or speak aloud what you're
										not able to get past (I feel very angry,
										and am unable to look beyond this right
										now.)
									</li>
									<li>
										Think about the trigger. Sometimes
										things aren't as bad as they seem and we
										may tell ourselves untrue statements, or
										exaggerate a situation by overplaying it
										in our minds. Think: "Is this entirely
										true?"
									</li>
									<li>
										Now try to gain perspective by looking
										at the situation a little differently.
										Think of different ways around the
										situation -you may find more truth in
										another approach.
									</li>
									<li>
										Be kind to yourself. Remind yourself it
										is okay that you got triggered, even if
										now the trigger doesn't seem to make
										sense, or your reactions seems
										disproportionate to the trigger.
									</li>
								</ol>
								<hr />
								<h3 id="distraction">
									<strong>Distraction</strong>
								</h3>
								<p>
									Distracting ourselves from the present issue
									can help to put some space between us and
									our thoughts and emotions. This can provide
									some short-term relief from feeling upset.
									There are many different ways that
									distraction can occur and some that you may
									enjoy more than others.
								</p>
								<p>
									Here are some examples of activities that
									might be useful:
								</p>
								<ul>
									<li>Go for a walk or a run</li>
									<li>Meet with friends</li>
									<li>Have a cup of tea</li>
									<li>Play with a pet</li>
									<li>Listen to music</li>
									<li>Watch TV or read</li>
									<li>Take a bath or shower</li>
									<li>Write a journal entry</li>
									<li>
										Arts and craft (drawing, knitting,
										origami)
									</li>
									<li>Do laundry</li>
									<li>Dance to loud music</li>
									<li>Hug a pillow or a toy</li>
									<li>Cook or bake your favourite foods</li>
									<li>Do some gardening</li>
									<li>
										Look at pictures of destinations you
										would like to visit
									</li>
									<li>Use social media</li>
									<li>Play Sudoku or other brain teasers</li>
								</ul>
								<p className="mt-4">
									This list is a starting point for activities
									that might help to distract yourself from
									strong emotions and thoughts. Have a think
									about what activities you like to engage in.
								</p>
							</div>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>
							<i
								className="fas fa-comment-medical fa-fw ms-1 me-3"
								aria-hidden="true"
							/>
							<strong>
								How can I talk to a healthcare professional?
							</strong>
						</Accordion.Header>
						<Accordion.Body>
							<p>
								<strong>General Practitioner (GP).</strong>
								<br /> Could you get an appointment with your
								GP? Some offer emergency appointments.
							</p>
							<p>
								<strong>Local mental health services.</strong>
								<br /> Does your local healthcare trust run a
								crisis line or allow self-referrals?
							</p>
							<p>
								<strong>NHS 111.</strong>
								<br /> This is the NHS urgent and emergency care
								hotline (dial 111).
							</p>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
			{controlGroup ? <FooterCategoryControl /> : <FooterStories />}
		</div>
	);
};

export default Help;
