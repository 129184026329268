import React from "react";

import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import SingleStory from "./SingleStory.js";

const DisplaySingleStory = props => {
	return (
		<div className="content intervention">
			<MenuHeader />
			<SingleStory />
			<FooterStories />
		</div>
	);
};

export default DisplaySingleStory;
