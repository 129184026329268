import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { ERROR, ACTIONS } from "../state/Config.js";

const MansaPreQ = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const onDataChange = event => {
		formDataRef.current = {
			...formDataRef.current,
			[event.target.name]: event.target.value
		};

		// DEBUG
		console.log("Form data = ", formDataRef.current);
	};

	const onSubmitMansaPreQ = async event => {
		event.preventDefault();

		try {
			const response = await postData(
				"/categories/mansaPreQuestions",
				formDataRef.current
			);

			const { status, action, data } = response;

			// DEBUG
			console.log("Response = ", response);

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit mansaPreQ data");
		}
	};
	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>Your Quality of Life</h1>
					</div>

					<div>
						{participantData.week52 ? (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand
									your current quality of life, so that we can
									see how this has changed during the trial.
								</p>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand
									your current quality of life, so that we can
									see how this changes during the trial.
								</p>
							</React.Fragment>
						)}{" "}
						{/* PS added hr */}
						<hr className="mt-4 mb-4" />
					</div>
					<Form
						onSubmit={onSubmitMansaPreQ}
						id="preQ"
						className="mb-5"
						action=""
					>
						{/* PS changed ul to div, wrapped labels  */}
						<div className="mb-3">
							<Form.Group>
								<Form.Label>
									What is your occupation?
									<Form.Select
										onChange={onDataChange}
										name="preOccupation"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">Employed</option>
										<option value="2">
											Sheltered employment
										</option>
										<option value="3">
											Training or education
										</option>
										<option value="4">Unemployed</option>
										<option value="5">Retired</option>
									</Form.Select>
								</Form.Label>
							</Form.Group>

							<Form.Group>
								<Form.Label>
									Do you live alone or with others?
									<Form.Select
										onChange={onDataChange}
										name="preAlone"
										required
									>
										<option>
											Select an answer from the list
										</option>
										<option value="1">Alone</option>
										<option value="2">With others</option>
									</Form.Select>
								</Form.Label>
							</Form.Group>
						</div>

						<div id="preNext">
							<button type="submit" className="btn btn-primary">
								Next <i className="fas fa-arrow-right" />
							</button>
						</div>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default MansaPreQ;
