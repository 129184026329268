import React from "react";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import { Link } from "react-router-dom";

const FullWelcome = props => {
	return (
		<div className="content intervention">
			<MenuHeader />
			<div className="container min-height-full" id="main">
				{/* PS added tab, changed divs, deleted extra columns, added bg-white and story-button class */}
				<ul className="nav nav-tabs mt-4">
					<li className="nav-item">
						<div className="nav-link active">
							<i className="fas fa-thumbs-up me-2" />{" "}
							<strong>Welcome to NEON!</strong>
						</div>
					</li>
				</ul>

				<div className="bg-white pt-4 pb-4 ps-5 pe-5 mb-5">
					<div>
						NEON gives you access to hundreds of stories from around
						the world of people describing their recovery from
						mental health problems. Recovery stories can give hope
						and be empowering, and they can expose you to new people
						and new ideas. We hope that you will find some of the
						stories on this site hopeful.
					</div>
					<hr className="light" />
					<div className="blueLinks">
						You can choose a story in four ways. For many people the
						best way is to let NEON{" "}
						<Link to="/categories/matchStory">
							<strong>choose a matched story</strong>
						</Link>{" "}
						for you. The more information you tell us about yourself
						through the{" "}
						<Link to="/categories/profile?status=1">
							<strong>About me</strong>
						</Link>{" "}
						button, the better the match will be. If you prefer, you
						can{" "}
						<Link to="/categories/browse">
							<strong>
								browse through story categories and choose a
								story
							</strong>
						</Link>
						, or NEON can choose a{" "}
						<Link to="/categories/checkStory?story=-1">
							<strong>random story</strong>
						</Link>{" "}
						for you. You can also look back at stories{" "}
						<Link to="/categories/showHistory">
							<strong>
								that have made you feel hopeful or that you have
								bookmarked
							</strong>
						</Link>
						.
					</div>
					<hr className="light" />
					<div className="blueLinks">
						Recovery stories can bring up difficult feelings. You
						can choose particular types of stories you do or do not
						want to see by clicking the{" "}
						<Link to="/categories/profile?status=1">
							<strong>About Me</strong>
						</Link>{" "}
						button, and some stories come with content warning. If
						you do become upset after accessing a story, you can
						click the{" "}
						<Link to="/categories/help">
							<strong>I'm Upset</strong>
						</Link>{" "}
						button for suggestions of what you might do. We would
						also encourage you to speak with someone else especially
						before making big changes, for example to talk with your
						mental health worker before making a change to your
						treatment.
					</div>
					<hr className="light" />
					<div className="blueLinks">
						And if you need to leave the site immediately, you can
						always click the{" "}
						<a href="https://www.google.co.uk/">
							<strong>Get Me Out Of Here</strong>
						</a>{" "}
						button.
					</div>
					<hr className="light" />
					<div className="blueLinks">
						Learn more about NEON by clicking the{" "}
						<Link to="/categories/about">
							<span className="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
								<strong>
									<u>About NEON</u>
								</strong>
							</span>
						</Link>{" "}
						button.
					</div>
				</div>
			</div>
			<FooterStories />
		</div>
	);
};

export default FullWelcome;
