import { create } from "zustand";
import { persist } from "zustand/middleware";

const useParticipantStore = create(
	persist(
		set => ({
			// Participant data
			participantData: {},
			setParticipantData: data => {
				set({
					participantData: data
				});
			}
		}),
		{
			name: "participant-storage"
		}
	)
);

export default useParticipantStore;
