import { create } from "zustand";
import axios from "axios";

const useStore = create(set => ({
	loggedIn: false,
	setLoginStatus: status => set({ loggedIn: status }),
	currentState: {},
	fetchNeonState: async url => {
		const state = await axios.get(url);
		set({ currentState: state.data });
	},

	// Profile data
	// About
	profileData: {
		gender: "0",
		age: "0",
		ethnicity: "0",
		sexuality: "0",
		recovery: "0"
	},

	// Disabilities
	disabilityData: {
		disabilityNone: false,
		disabilityVisual: false,
		disabilityHearing: false,
		disabilityMobility: false,
		disabilityMemory: false,
		disabilitySelf: false
	},

	// Diagnoses
	diagnosisData: {
		diagnosisNone: false,
		diagnosisNeuro: false,
		diagnosisEating: false,
		diagnosisMood: false,
		diagnosisPersonality: false,
		diagnosisOCD: false,
		diagnosisSchiz: false,
		diagnosisTrauma: false,
		diagnosisSubstance: false,
		diagnosisRejects: false
	},

	// Preferences
	preferenceData: {
		storyPreferencesText: false,
		storyPreferencesAudio: false,
		storyPreferencesMoving: false,
		storyPreferencesStatic: false
	},

	// Blocked stories
	triggerData: {
		abuse: false,
		loss: false,
		harm: false,
		violence: false,
		injustice: false
	},

	blockedData: {
		text: false,
		audio: false,
		moving: false,
		static: false
	},

	blockedStories: 0,

	// Self management
	managementData: {
		strategyText: ""
	},

	// Contact info
	contactInfo: {
		username: "",
		phone: ""
	},

	// Badges
	badgesData: {
		badgesEnabled: false
	},

	setProfileData: data => {
		set({
			profileData: data
		});
	},

	updateProfileData: profileUpdate => {
		set(state => ({
			profileData: {
				...state.profileData,
				[profileUpdate.target.name]: profileUpdate.target.value
			}
		}));
	},

	setDisabilityData: data => {
		set({
			disabilityData: {
				disabilityNone: data.none,
				disabilityVisual: data.visual,
				disabilityHearing: data.hearing,
				disabilityMobility: data.mobility,
				disabilityMemory: data.memory,
				disabilitySelf: data.self
			}
		});
	},

	resetDisabilityData: () => {
		set({
			disabilityData: {
				disabilityNone: true,
				disabilityVisual: false,
				disabilityHearing: false,
				disabilityMobility: false,
				disabilityMemory: false,
				disabilitySelf: false
			}
		});
	},

	updateDisabilityData: update => {
		set(state => ({
			disabilityData: {
				...state.disabilityData,
				[update.target.name]: update.target.checked
			}
		}));
	},

	setDiagnosisData: data => {
		set({
			diagnosisData: {
				diagnosisNone: data.none,
				diagnosisNeuro: data.neuro,
				diagnosisEating: data.eating,
				diagnosisMood: data.mood,
				diagnosisPersonality: data.personality,
				diagnosisOCD: data.ocd,
				diagnosisSchiz: data.schiz,
				diagnosisTrauma: data.trauma,
				diagnosisSubstance: data.substance,
				diagnosisRejects: data.rejects
			}
		});
	},

	updateDiagnosisData: update => {
		set(state => ({
			diagnosisData: {
				...state.diagnosisData,
				[update.target.name]: update.target.checked
			}
		}));
	},

	resetDiagnosisData: () => {
		set({
			diagnosisData: {
				diagnosisNone: true,
				diagnosisNeuro: false,
				diagnosisEating: false,
				diagnosisMood: false,
				diagnosisPersonality: false,
				diagnosisOCD: false,
				diagnosisSchiz: false,
				diagnosisTrauma: false,
				diagnosisSubstance: false,
				diagnosisRejects: false
			}
		});
	},

	setPreferenceData: data => {
		set({
			preferenceData: {
				storyPreferencesText: data.text,
				storyPreferencesAudio: data.audio,
				storyPreferencesMoving: data.moving,
				storyPreferencesStatic: data.static
			}
		});
	},

	updatePreferenceData: update => {
		set(state => ({
			preferenceData: {
				...state.preferenceData,
				[update.target.name]: update.target.checked
			}
		}));
	},

	setTriggerData: data => {
		set({
			triggerData: data
		});
	},

	updateTriggerData: update => {
		set(state => ({
			triggerData: {
				...state.triggerData,
				[update.target.name]: update.target.checked
			}
		}));
	},

	setBlockedData: data => {
		set({
			blockedData: data
		});
	},

	setBlockedStories: stories => {
		set({ blockedStories: stories });
	},

	updateBlockedData: update => {
		set(state => ({
			blockedData: {
				...state.blockedData,
				[update.target.name]: update.target.checked
			}
		}));
	},

	setManagementData: data => {
		set({
			managementData: {
				strategyText: data.text
			}
		});
	},

	updateManagementData: update => {
		set(state => ({
			managementData: {
				...state.managementData,
				[update.target.name]: update.target.value
			}
		}));
	},

	setContactInfo: data => {
		set({
			contactInfo: {
				username: data.Email,
				phone: data.Phone
			}
		});
	},

	updateContactInfo: update => {
		set(state => ({
			contactInfo: {
				...state.contactInfo,
				[update.target.name]: update.target.value
			}
		}));
	},

	setBadgesData: data => {
		set({
			badgesData: {
				badgesEnabled: data
			}
		});
	},

	updateBadgesData: update => {
		set(state => ({
			badgesData: {
				...state.badgesData,
				[update.target.name]: update.target.checked
			}
		}));
	}
}));

export default useStore;
