import React, { useEffect } from "react";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import useFetch from "../hooks/useFetch.js";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../state/store.js";

const Badges = () => {
	const badgeState = useFetch("/categories/badges");
	// Navigation
	const navigate = useNavigate();
	const loggedIn = useStore(state => state.loggedIn);

	useEffect(() => {
		if (!loggedIn) {
			return navigate("/login");
		}
	}, []);

	return (
		<div className="content intervention">
			<MenuHeader />
			<div className="container mt-5 min-height-full">
				{/* PS added tab, deleted extra columns, added bg-white class */}
				<ul className="nav nav-tabs mt-4">
					<li className="nav-item">
						<div className="nav-link active">
							<i className="fas fa-award me-2" />{" "}
							<strong>Badges</strong>
						</div>
					</li>
				</ul>

				<div className="bg-white ps-4 pe-4 pt-4 pb-5 mb-3">
					<p className="pt-2 pb-4 ps-3 pe-3 blueLinks">
						Badges are a reflection of your usage of NEON. Simply
						using different parts of NEON can earn you a badge.{" "}
						<br />
						<br />
						Below are the badges you have already received. Badges
						which are faded out are the ones you can still collect.{" "}
						<br />
						<br />
						If you do not wish to see your badges, you can hide them
						through the{" "}
						<Link to="/categories/profile?status=1">
							About me
						</Link>{" "}
						page.
					</p>
					<div className="container text-center ">
						<div className="row">
							{/* row of 6 */}
							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.radio
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge02.png"
												alt="Radio star badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-02 badge-title">
												Radio star
											</div>
											You listened to an audio recording!
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.weekday
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge13.png"
												alt="Weekday warrior badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-13 badge-title">
												Weekday warrior
											</div>
											You requested a story on a weekday
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.browsed
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge18.png"
												alt="Browsed badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-18 badge-title">
												Browsed
											</div>
											Thanks for browsing for stories
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.video
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge01.png"
												alt="Video star badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-01 badge-title">
												Video star
											</div>
											You watched a video story!
										</div>
									</div>
								</div>
							</div>

							{/* Only one of these will be rendered */}
							{badgeState && badgeState.attributes.stories5 > 0 && (
								<React.Fragment>
									{" "}
									<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
										<div className="badge-on pt-3 pb-3 mb-4">
											<div className="row">
												<div className="badge-image col-5">
													<img
														src="/assets/images/badge03.png"
														alt="5 stories badge"
													/>
												</div>
												<div className="badge-desc col-7">
													<div className="btn btn-03 badge-title">
														5 stories
													</div>
													You requested 5 stories!
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
							{badgeState && badgeState.attributes.stories10 > 0 && (
								<React.Fragment>
									{" "}
									<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
										<div className="badge-on pt-3 pb-3 mb-4">
											<div className="row">
												<div className="badge-image col-5">
													<img
														src="/assets/images/badge04.png"
														alt="10 stories badge"
													/>
												</div>
												<div className="badge-desc col-7">
													<div className="btn btn-04 badge-title">
														10 stories
													</div>
													You requested 10 stories!
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
							{badgeState && badgeState.attributes.stories15 > 0 && (
								<React.Fragment>
									{" "}
									<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
										<div className="badge-on pt-3 pb-3 mb-4">
											<div className="row">
												<div className="badge-image col-5">
													<img
														src="/assets/images/badge05.png"
														alt="15 stories badge"
													/>
												</div>
												<div className="badge-desc col-7">
													<div className="btn btn-05 badge-title">
														15 stories
													</div>
													You requested 15 stories!
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
							{badgeState && badgeState.attributes.stories20 > 0 && (
								<React.Fragment>
									{" "}
									<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
										<div className="badge-on pt-3 pb-3 mb-4">
											<div className="row">
												<div className="badge-image col-5">
													<img
														src="/assets/images/badge06.png"
														alt="20 stories badge"
													/>
												</div>
												<div className="badge-desc col-7">
													<div className="btn btn-06 badge-title">
														20 stories
													</div>
													You requested 20 stories!
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
							{badgeState && badgeState.attributes.stories30 > 0 && (
								<React.Fragment>
									{" "}
									<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
										<div className="badge-on pt-3 pb-3 mb-4">
											<div className="row">
												<div className="badge-image col-5">
													<img
														src="/assets/images/badge07.png"
														alt="30 stories badge"
													/>
												</div>
												<div className="badge-desc col-7">
													<div className="btn btn-07 badge-title">
														30 stories
													</div>
													You requested 30 stories!
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
							{badgeState && badgeState.attributes.stories40 > 0 && (
								<React.Fragment>
									{" "}
									<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
										<div className="badge-on pt-3 pb-3 mb-4">
											<div className="row">
												<div className="badge-image col-5">
													<img
														src="/assets/images/badge08.png"
														alt="40 stories badge"
													/>
												</div>
												<div className="badge-desc col-7">
													<div className="btn btn-08 badge-title">
														40 stories
													</div>
													You requested 40 stories!
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
							{badgeState && badgeState.attributes.stories50 > 0 && (
								<React.Fragment>
									{" "}
									<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
										<div className="badge-on pt-3 pb-3 mb-4">
											<div className="row">
												<div className="badge-image col-5">
													<img
														src="/assets/images/badge09.png"
														alt="50 stories badge"
													/>
												</div>
												<div className="badge-desc col-7">
													<div className="btn btn-09 badge-title">
														50 stories
													</div>
													You requested 50 stories!
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							)}
							{badgeState &&
								badgeState.attributes.stories100 > 0 && (
									<React.Fragment>
										{" "}
										<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
											<div className=" badge-on pt-3 pb-3 mb-4">
												<div className="row">
													<div className="badge-image col-5">
														<img
															src="/assets/images/badge10.png"
															alt="100 stories badge"
														/>
													</div>
													<div className="badge-desc col-7">
														<div className="btn btn-10 badge-title">
															100 stories
														</div>
														You requested 100
														stories!
													</div>
												</div>
											</div>
										</div>
									</React.Fragment>
								)}
							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.weekend
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge12.png"
												alt="Weekend warrior badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-12 badge-title">
												Weekend warrior
											</div>
											You requested a story at a weekend
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* row of 6 */}

						<div className="row">
							{/* row of 6 */}
							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.early
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge14.png"
												alt="Early bird badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-14 badge-title">
												Early bird
											</div>
											You requested a story early in the
											day
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState && badgeState.attributes.late
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge15.png"
												alt="Night owl badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-15 badge-title">
												Night owl
											</div>
											You requested a story late at night
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.bookmark
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge16.png"
												alt="Bookmarked badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-16 badge-title">
												Bookmarked
											</div>
											Thanks for bookmarking a story
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.matched
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge17.png"
												alt="Matched badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-17 badge-title">
												Matched
											</div>
											You used 'Match me to a story!'
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div
									className={
										badgeState &&
										badgeState.attributes.random
											? "badge-on pt-3 pb-3 mb-4"
											: "badge-off pt-3 pb-3 mb-4"
									}
								>
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge19.png"
												alt="Randomised badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-19 badge-title">
												Randomised
											</div>
											Thanks for getting a random story
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-6 col-md-12 d-flex align-items-stretch justify-content-center">
								<div className="badge-on pt-3 pb-3 mb-4">
									<div className="row">
										<div className="badge-image col-5">
											<img
												src="/assets/images/badge11.png"
												alt="Logged on badge"
											/>
										</div>
										<div className="badge-desc col-7">
											<div className="btn btn-11 badge-title">
												Logged on
											</div>
											Thanks for logging into NEON.
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* row of 6 */}
					</div>
					{/* container */}
				</div>
			</div>
			<FooterStories />
		</div>
	);
};

export default Badges;
