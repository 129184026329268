import React, { useState, useEffect, useRef } from "react";
import getData from "../data/getData.js";
import postData from "../data/PostData.js";
import MenuHeader from "../partials/MenuHeader.js";
import FooterStories from "../partials/FooterStories.js";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { ERROR, ACTIONS } from "../state/Config.js";
import { ErrorMessage } from "./ErrorMessage.js";
import useParticipantStore from "../state/participantStore.js";
import useStore from "../state/store.js";

const Browse = () => {
	// Error states
	const [errorState, setErrorState] = useState(false);
	// Alert when narrative data has been fetched
	const [dataReady, setDataReady] = useState(false);
	const [numStories, setNumStories] = useState(0);

	// Keep track of button states
	const [activeButtons, setActiveButtons] = useState({});

	const navigate = useNavigate();
	const loggedIn = useStore(state => state.loggedIn);

	// Participant data
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const selectCategory = async event => {
		// DEBUG
		// console.log("Clicked = ", event.target.id);
		let value = true;
		if (activeButtons[event.target.id] !== undefined) {
			value = !activeButtons[event.target.id];
		}

		setActiveButtons({ ...activeButtons, [event.target.id]: value });
	};

	const onGetStories = async event => {
		event.preventDefault();

		try {
			const selected = getSelectedCategories();
			const response = await postData("/categories/getStories", {
				submittedCategories: selected
			});

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							// DEBUG
							// console.log("Data = ", data);
							navigate(`/stories/${data.page}`);
							break;

						default:
							break;
					}
					break;
				default:
					setErrorState(true);
					break;
			}
		} catch (error) {
			console.log("Could not get categories");
		}
	};

	const getSelectedCategories = () => {
		const selected = [];
		for (const cat in activeButtons) {
			if (activeButtons[cat]) {
				selected.push(cat);
			}
		}

		return selected;
	};

	const dataRef = useRef();

	useEffect(() => {
		// Get all narrative details
		if (!loggedIn) {
			return navigate("/login");
		}

		const getNarrativeDetails = async () => {
			const storyState = await getData("/categories/browse", {});
			dataRef.current = storyState ? storyState.data : undefined;
			dataRef.current ? setDataReady(true) : setErrorState(true);
		};

		getNarrativeDetails();
	}, []);

	useEffect(() => {
		const getNumberOfStories = async () => {
			// Update number of stories
			const selected = getSelectedCategories();
			const response = await postData("/categories/getNumStories", {
				categories: selected
			});

			const { data } = response;
			setNumStories(data.numStories);
			// DEBUG
			// console.log("Num stories = ", data.numStories);
		};

		getNumberOfStories();
	}, [activeButtons]);

	return (
		<>
			{errorState ? (
				<ErrorMessage />
			) : (
				<div className="content intervention">
					<MenuHeader />
					<div className="container mt-5">
						<div>
							<h1>Browse stories</h1>
							<h2 className="mb-4">Please select a category</h2>
						</div>

						<div className="mb-5">
							<p>
								Please select all the categories that you nare
								interested in to see the related stories.
							</p>
							<p>Click on the category again to deselect it.</p>
							<Form
								onSubmit={onGetStories}
								id="getStoriesTop"
								className="pt-2 pb-2"
							>
								<div className="row">
									<div className="col-0 col-lg-2">
										<input
											type="hidden"
											id="submittedCategoriesTop"
											name="submittedCategories"
											value=""
										/>
									</div>
									<div className="col-12 col-lg-10">
										{/* PS does this need a tooltip for mobile users, telling them to choose categories first? */}
										<Button
											id="storiesTop"
											type="submit"
											disabled=""
											className="btn-lg"
										>
											<i
												className="fab fa-readme me-3"
												aria-hidden="true"
											/>{" "}
											Get Stories{" "}
											<Badge
												className="float-end text-black mt-1 ms-1"
												bg="light"
											>
												{numStories}
											</Badge>
										</Button>
									</div>
								</div>
							</Form>
						</div>
						{/* PS added tabs, improved column layout for mobile, added hr, badges for story numbers (they need to go first), and h3  */}

						<ul className="nav nav-tabs mt-4">
							<li className="nav-item">
								<div className="nav-link active">
									<i
										className="fas fa-book-reader me-2"
										aria-hidden="true"
									/>{" "}
									<strong>About the storyteller</strong>
								</div>
							</li>
						</ul>
						<div className="bg-white pt-4 pb-4 mb-5 ps-3 pe-3">
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Gender:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_TELLER_CATEGORIES.Gender.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Ethnicity:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_TELLER_CATEGORIES.Ethnicity.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Sexuality:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_TELLER_CATEGORIES.Sexuality.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Recovery:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_TELLER_CATEGORIES.Recovery.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Diagnosis:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_TELLER_CATEGORIES.Diagnosis.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
						</div>

						<ul className="nav nav-tabs mt-4">
							<li className="nav-item">
								<div className="nav-link active">
									<i
										className="fas fa-book me-2"
										aria-hidden="true"
									/>{" "}
									<strong>About the story</strong>
								</div>
							</li>
						</ul>
						<div className="bg-white pt-4 pb-4 mb-5 ps-3 pe-3">
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Story:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_CATEGORIES.Story.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Contains:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_CATEGORIES.Contains.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Tone:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_CATEGORIES.Tone.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Recovery:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_CATEGORIES.Recovery.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
							<hr className="light" />
							<div className="row">
								<div className="col-12 col-lg-2 text-start text-lg-end">
									<h3>Genre:</h3>
								</div>
								<div className="col-12 col-lg-10">
									<div className="row text-center">
										{dataReady &&
											dataRef.current.STORY_CATEGORIES.Genre.map(
												(item, i) => (
													<React.Fragment key={i}>
														{" "}
														<div className="col-lg-3 col-6">
															<div className="mt-2 options">
																<Button
																	id={item.id}
																	onClick={
																		selectCategory
																	}
																	variant={
																		activeButtons[
																			item
																				.id
																		]
																			? "primary"
																			: "buttonLight"
																	}
																>
																	{item.Text}{" "}
																	<Badge
																		className="float-end text-black mt-1"
																		bg="light"
																	>
																		{
																			item.numberStories
																		}
																	</Badge>
																</Button>
															</div>
														</div>
													</React.Fragment>
												)
											)}{" "}
									</div>
								</div>
							</div>
						</div>
						<Form
							onSubmit={onGetStories}
							id="getStoriesBottom"
							className="pt-2 pb-2"
						>
							<div className="row">
								<div className="col-0 col-lg-2">
									<input
										type="hidden"
										id="submittedCategoriesBottom"
										name="submittedCategories"
										value=""
									/>
								</div>
								<div className="col-12 col-lg-10">
									{/* PS does this need a tooltip for mobile users, telling them to choose categories first? */}
									<Button
										id="storiesBottom"
										type="submit"
										className="btn-lg"
										disabled=""
									>
										<i
											className="fab fa-readme me-3"
											aria-hidden="true"
										/>{" "}
										Get Stories
										<Badge
											className="float-end text-black mt-1 ms-1"
											bg="light"
										>
											{numStories}
										</Badge>
									</Button>
								</div>
							</div>
						</Form>
					</div>
					<FooterStories />
				</div>
			)}
		</>
	);
};

export default Browse;
