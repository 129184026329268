import React, { useRef } from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import ReactBootstrapSlider from "react-bootstrap-slider";
import { useNavigate } from "react-router-dom";
import postData from "../data/PostData.js";
import useParticipantStore from "../state/participantStore.js";
import { LABELS, ERROR, ACTIONS } from "../state/Config.js";

const Herth = props => {
	// Get participant data
	const participantData = useParticipantStore(state => state.participantData);
	const setParticipantData = useParticipantStore(
		state => state.setParticipantData
	);
	const navigate = useNavigate();
	let formDataRef = useRef({});

	const formatMental = value => {
		const tooltip = document
			.getElementById("mentalRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["mentalRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatGoal = value => {
		const tooltip = document
			.getElementById("goalRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["goalRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatAlone = value => {
		const tooltip = document
			.getElementById("aloneRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["aloneRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatPossibility = value => {
		const tooltip = document
			.getElementById("possibilityRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["possibilityRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatFaith = value => {
		const tooltip = document
			.getElementById("faithRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["faithRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatFuture = value => {
		const tooltip = document
			.getElementById("futureRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["futureRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatHappy = value => {
		const tooltip = document
			.getElementById("happyRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["happyRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatStrength = value => {
		const tooltip = document
			.getElementById("strengthRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["strengthRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatCaring = value => {
		const tooltip = document
			.getElementById("caringRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["caringRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatDirection = value => {
		const tooltip = document
			.getElementById("directionRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["directionRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatPotential = value => {
		const tooltip = document
			.getElementById("potentialRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["potentialRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const formatValue = value => {
		const tooltip = document
			.getElementById("valueRatings")
			.getElementsByClassName("tooltip-inner")
			.item(0);
		const adjusted = value - 1;
		tooltip.style.left = `${(adjusted / LABELS.HOPE_MAX) * 100}%`;

		formDataRef.current = {
			...formDataRef.current,
			["valueRatings"]: value
		};

		return LABELS.Hope[adjusted];
	};

	const onSubmitHope = async event => {
		event.preventDefault();

		try {
			// DEBUG
			console.log("Form = ", formDataRef.current);

			const response = await postData(
				"/categories/hope",
				formDataRef.current
			);

			// DEBUG
			console.log("Response = ", response);

			const { status, action, data } = response;

			switch (status) {
				case ERROR.NO_ERROR:
					switch (action) {
						case ACTIONS.NAVIGATE:
							setParticipantData(data);
							navigate(`/questions/${data.page}`);
							break;

						default:
							break;
					}
					break;

				case ERROR.USER_NOT_LOGGED_IN:
					navigate(`/${data.page}`);
					break;

				default:
					navigate(`/login`);
					break;
			}
		} catch (error) {
			console.log("Could not submit mansa data");
		}
	};

	return (
		<>
			<div className="content mt-3">
				<MainHeader />
				<div className="container">
					{/* PS Progress bar */}
					<div className="mb-5">
						<ProgressBar now={participantData.progress} />
					</div>

					<div>
						<h1>Your current hopefulness</h1>
					</div>
					<div>
						{participantData.week52 ? (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand how
									hopeful you feel at present, so that we can
									see how this has changed during the trial.
								</p>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<p>
									This information will help us understand how
									hopeful you feel at present, so that we can
									see how this changes during the trial.
								</p>
							</React.Fragment>
						)}{" "}
					</div>
					<div className="mb-5">
						<p>
							Listed below are a number of statements regarding
							hope. Read each statement and decide whether it
							applies to you personally. There are no right or
							wrong answers. Select the appropriate box to
							indicate how often the statement has applied to you
							in the past week or two.
						</p>
					</div>
					{/* PS added hr */}
					<hr className="mt-5 mb-5" />

					<Form
						onSubmit={onSubmitHope}
						id="hopefulRatings"
						className="mb-5 small-gap"
					>
						<ol className="questions">
							<li>
								<label htmlFor="positiveRatings">
									I have a positive outlook toward life.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="mentalRatings"
											name="mentalRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatMental}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="goalRatings">
									I have short and/or long range goals.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="goalRatings"
											name="goalRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatGoal}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="aloneRatings">
									I feel all alone.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="aloneRatings"
											name="aloneRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatAlone}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="possibilityRatings">
									I can see possibilities in the midst of
									difficulties.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="possibilityRatings"
											name="possibilityRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatPossibility}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="faithRatings">
									I have a faith that gives me comfort.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="faithRatings"
											name="faithRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatFaith}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="futureRatings">
									I feel scared about my future.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="futureRatings"
											name="futureRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatFuture}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="happyRatings">
									I can recall happy/joyful times.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="happyRatings"
											name="happyRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatHappy}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="strengthRatings">
									I have deep inner strength.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="strengthRatings"
											name="strengthRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatStrength}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="caringRatings">
									I am able to give and receive caring/love.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="caringRatings"
											name="caringRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatCaring}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="directionRatings">
									I have a sense of direction.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="directionRatings"
											name="directionRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatDirection}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="potentialRatings">
									I believe that each day has potential.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="potentialRatings"
											name="potentialRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatPotential}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<li>
								<label htmlFor="valueRatings">
									I feel my life has value and worth.
								</label>
							</li>
							<div className="row mb-4">
								<div className="ms-3 col-11 mb-3">
									<div>
										<ReactBootstrapSlider
											id="valueRatings"
											name="valueRatings"
											value={1}
											min={1}
											max={4}
											ticks={[1, 2, 3, 4]}
											ticks_tooltip={true}
											formatter={formatValue}
											required
										/>
										<div className="row">
											<div className="col-6 text-start">
												<label className="form-check-label">
													<em>Strongly disagree</em>
												</label>
											</div>
											<div className="col-6 text-end">
												<label className="form-check-label">
													<em>Strongly agree</em>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ol>
						<div>
							<input
								type="hidden"
								name="formRequestID"
								value={props.formRequestID}
							/>
							<button
								type="submit"
								className="btn btn-primary ms-5"
							>
								<i className="fas fa-check" /> Submit
							</button>
						</div>
					</Form>
				</div>
			</div>
			<StripeFooter />
		</>
	);
};

export default Herth;
