import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing.js";
import Login from "./pages/Login.js";
import Missing from "./pages/Missing.js";
import Stories from "./routing/Stories.js";
import Questions from "./routing/Questions.js";
import HowTo from "./pages/HowTo.js";
import Testimonials from "./pages/Testimonials.js";
import Team from "./pages/Team.js";
import EligibilityQ1 from "./eligibility/EligibilityCheckQ1.js";
import EligibilityQ3 from "./eligibility/EligibilityCheckQ3.js";
import EligibilityQ5 from "./eligibility/EligibilityCheckQ5.js";
import EligibilityQ6 from "./eligibility/EligibilityCheckQ6.js";
import EligibilityQ7 from "./eligibility/EligibilityCheckQ7.js";
import Eligible from "./eligibility/Eligible.js";
import NotEligible from "./eligibility/NotEligible.js";
import Information from "./pages/Information.js";
import Consent from "./pages/Consent.js";
import RepeatConsent from "./pages/RepeatConsent.js";
import Verification from "./pages/Verification.js";
import InvalidToken from "./pages/InvalidToken.js";
import TrialClosed from "./pages/TrialClosed.js";
import CreateAccount from "./pages/CreateAccount.js";
import Safe from "./routing/Safe.js";
import { ErrorMessage } from "./pages/ErrorMessage.js";

const App = () => {
	return (
		<Routes>
			<Route path="/" element={<Landing />} />
			<Route path="/login" element={<Login />} />
			<Route path="/howto" element={<HowTo />} />
			<Route path="/testimonials" element={<Testimonials />} />
			<Route path="/team" element={<Team />} />
			<Route path="/eligibility" element={<EligibilityQ1 />} />
			<Route path="/eligibilityCheckQ3" element={<EligibilityQ3 />} />
			<Route path="/eligibilityCheckQ5" element={<EligibilityQ5 />} />
			<Route path="/eligibilityCheckQ6" element={<EligibilityQ6 />} />
			<Route path="/eligibilityCheckQ7" element={<EligibilityQ7 />} />
			<Route path="/eligible" element={<Eligible />} />
			<Route path="/notEligible" element={<NotEligible />} />
			<Route path="/information" element={<Information />} />
			<Route path="/consent" element={<Consent />} />
			<Route path="/repeatConsent" element={<RepeatConsent />} />
			<Route path="/verification" element={<Verification />} />
			<Route path="/invalidToken" element={<InvalidToken />} />
			<Route path="/trialClosed" element={<TrialClosed />} />
			<Route path="/createAccount" element={<CreateAccount />} />
			<Route path="/temp/misc" element={<CreateAccount />} />
			<Route path="/stories/*" element={<Stories />} />
			<Route path="/questions/*" element={<Questions />} />
			<Route path="/safe/*" element={<Safe />} />
			<Route path="/error" element={<ErrorMessage />} />
			<Route path="*" element={<Missing />} />
		</Routes>
	);
};

export default App;
