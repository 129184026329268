import React from "react";
import MainHeader from "../partials/MainHeader.js";
import StripeFooter from "../partials/StripeFooter.js";

const NotEligible = props => (
	<>
		<div className="content mt-3">
			<MainHeader />
			<div className="container">
				<div className="blueLinks">
					<p>
						Thank you for taking the time to answer our questions.
						Unfortunately, you are not eligible to take part in any
						of the NEON trials.
					</p>
					<p>
						If you would like to see some recovery stories, you
						might take a look at a list we have created:{" "}
						<a
							href="https://www.researchintorecovery.com/public-recovery-stories"
							target="_blank"
						>
							https://www.researchintorecovery.com/public-recovery-stories
						</a>
					</p>
					<p>
						Please only complete the eligibility questions again if
						your circumstances have changed.
					</p>
				</div>
			</div>
		</div>
		<StripeFooter />
	</>
);

export default NotEligible;
