import React, { useState } from "react";
import SingleStory from "./SingleStory.js";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const StoryWarning = ({ state }) => {
	const [cont, setContinue] = useState(false);

	const onContinue = () => {
		setContinue(true);
	};

	return (
		<>
			{cont ? (
				<SingleStory state={state} triggers={true} />
			) : (
				<div className="container min-height-full">
					{/* PS added tab, changed divs, deleted extra columns, added bg-white and story-button class */}
					<ul className="nav nav-tabs mt-4">
						<li className="nav-item">
							<div className="nav-link active">
								<i className="fas fa-exclamation-triangle me-2" />{" "}
								<strong>Story warning</strong>
							</div>
						</li>
					</ul>

					<div className="bg-white pt-4 pb-4 ps-5 pe-5 mb-5">
						<div className="mt-4">
							<p>The story contains some of the following :</p>
						</div>
						<div className="text-center bg-white pt-4 pb-4">
							{state.warningTriggers.map((item, i) => (
								<React.Fragment key={i}>
									{" "}
									<div className="pt-2 pb-2">
										<a className="btn btn-warning story-button">
											<strong>{item}</strong>
										</a>
									</div>
								</React.Fragment>
							))}{" "}
						</div>
						<hr className="light mb-5" />
						<div className="mb-5 text-center">
							<p>
								If you are happy to view the story then click
								Continue below.
							</p>
							<Button
								onClick={onContinue}
								variant="primary"
								size="lg"
							>
								<i className="fas fa-arrow-right me-3" />
								<strong>Continue</strong>
							</Button>
						</div>
						<div className="mb-5 text-center">
							<p>
								If you would rather not view the story then
								please click the Back button to go to the main
								menu.
							</p>
							<Link
								className="btn btn-secondary btn-lg"
								to="/stories/showOptions"
							>
								<i className="fas fa-arrow-left me-3" />
								<strong>Back</strong>
							</Link>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default StoryWarning;
